import { locationsUrl } from "../services/utils/endpoints";
import { UploaderType } from "../domain/uploaderType";
import { useLocations } from "../services/locationsAdapter";
import storage from "../services/storageAdapter";
import { LocationService, NotificationService, StorageService } from "./ports";
import useNotifier from "../services/notificationAdapter";
import { AppInitStatus } from "../domain/appInitStatus";
import { AppMessage } from "../domain/appMessages";
import { formatError, getErrorMessage } from "./utils/errorFormater";
import { UploadLocation } from "../domain/uploadLocation";

type Deps = {
	storage: StorageService;
	url: string;
	locationsAdapter: LocationService;
	notifier: NotificationService;
	formatError: typeof formatError;
	getErrorMessage: typeof getErrorMessage;
};

const defaultDeps: Deps = {
	storage,
	url: locationsUrl,
	locationsAdapter: useLocations(),
	notifier: useNotifier(),
	formatError,
	getErrorMessage,
};

/**
 * Get all locations for current user.
 *
 * @param deps
 * @returns Array of locations.
 */
async function getLocations(
	deps: Deps = defaultDeps,
): Promise<UploadLocation[]> {
	const {
		storage,
		url,
		locationsAdapter,
		notifier,
		formatError,
		getErrorMessage,
	} = deps;

	const user = storage.getUser();

	if (!user) {
		notifier.notify("error", AppMessage.NoUser);
		throw new Error(AppMessage.NoUser);
	}

	const token = user.token;

	if (!token) {
		notifier.notify("error", AppMessage.NoToken);
		throw new Error(AppMessage.NoToken);
	}

	try {
		const locations = await locationsAdapter.getLocations(url);

		storage.setLocations(locations);

		if (storage.getUploaderType() != UploaderType.Embedded) {
			storage.setAppInitStatus(AppInitStatus.Done);
		}

		return locations;
	} catch (error) {
		notifier.notify(
			"error",
			formatError(AppMessage.CantGettingLocations, error),
		);
		storage.setAppInitStatus(AppInitStatus.Failed);
		throw new Error(getErrorMessage(error));
	}
}

export default getLocations;
