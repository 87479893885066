import { defaultMimeType, mimeTypes } from "../../../domain/mimes";
import useIconService from "../../../services/iconAdapter";
import { IconService } from "../../ports";

type Deps = {
	iconService: IconService;
};

const defaultDeps = {
	iconService: useIconService(),
};

/**
 * Get url to extension icon (call to icon service).
 *
 * @param ext
 * @param typeKeys
 * @param deps
 * @returns Url to icon
 */
function getIconPath(
	ext: string,
	typeKeys: string[],
	deps: Deps = defaultDeps,
): string {
	const { iconService } = deps;

	let iconPath = iconService.getIconPath(defaultMimeType);

	if (!ext.length) {
		return iconPath;
	}

	typeKeys.forEach(t => {
		if (mimeTypes[t].extensions.includes(ext.toLowerCase())) {
			iconPath = iconService.getIconPath(t);
		}
	});

	return iconPath;
}

export default getIconPath;
