import Features from "../domain/features";
import useFeauturesService from "../services/featuresAdapter";
import useNotifier from "../services/notificationAdapter";
import storage from "../services/storageAdapter";
import { FeaturesService, NotificationService, StorageService } from "./ports";
import { getErrorMessage } from "./utils/errorFormater";

type Deps = {
	storage: StorageService;
	featuresService: FeaturesService;
	notifier: NotificationService;
};

const defaultDeps: Deps = {
	storage,
	featuresService: useFeauturesService(),
	notifier: useNotifier(),
};

async function getfeatures(deps: Deps = defaultDeps): Promise<Features> {
	const { storage, featuresService, notifier } = deps;

	try {
		const features = await featuresService.getFeatures();
		storage.setFeatures(features);
	} catch (error) {
		notifier.notify("error", "sss");
		throw new Error(getErrorMessage(error));
	}

	return storage.getFeatures();
}

export default getfeatures;
