import React, { FormEvent, useEffect, useState } from "react";
import { TextField, Text, PrimaryButton, DefaultButton } from "@fluentui/react";
import Panel from "../Panel/Panel";
import { useStore } from "effector-react";
import storage from "../../../services/storageAdapter";
import changeFileName from "../../../application/changeFileName";
import changeDescription from "../../../application/changeDescription";
import { PanelKind } from "../../../domain/panel";
import "./AddMetadata.scss";
import { AppMessage } from "../../../domain/appMessages";
import controlPanel from "../../../application/controlPanel";
import changeStory from "../../../application/changeStory";
import {
	checkNewFileNameForUnique,
	getFullStory,
} from "../../../domain/fileInfo";
import { noStory } from "../../../domain/defaultValues";
import { useStatemanjs } from "@persevie/statemanjs-react";
import { userState } from "../../../services/storage/userStorageService";

function AddMetadata(): JSX.Element {
	const user = useStatemanjs(userState);
	const dirtyQueue = useStore(storage.dirtyQueue);
	const openedPanel = useStore(storage.openedPanel);
	const filesInfo = useStore(storage.filesInfo);
	const stories = useStore(storage.stories);
	const features = useStore(storage.features);

	if (!dirtyQueue) {
		throw new Error(AppMessage.NoDirtyQueue);
	}

	if (!filesInfo) {
		throw new Error(AppMessage.NoFileInfo);
	}

	if (!user) {
		throw new Error(AppMessage.NoUser);
	}

	const story = getFullStory(dirtyQueue[0]) ?? "";
	const name = dirtyQueue[0].name;
	const description = dirtyQueue[0].description;

	const [newStory, setNewStory] = useState(story);
	const [newName, setNewName] = useState(name);
	const [newDescription, setNewDescription] = useState(description);

	const [isFileNameInvalid, setIsFileNameInvalid] = useState(false);
	const [isStoryInvalid, setIsStoryInvalid] = useState(false);

	const [isMetadataChanged, setIsMetadataChanged] = useState(false);

	const noStories = !stories || !stories.length;

	const differentStories =
		new Set(dirtyQueue.map(i => i.storyTitle)).size > 1;
	const isStoriesDisabled = noStories || differentStories;

	const isFileNameDisabled: boolean = dirtyQueue.length > 1;

	const isDescriptionDisabled =
		new Set(dirtyQueue.map(i => i.description)).size > 1 ? true : false;

	const isSaveChangesButtonDisabled = isMetadataChanged
		? isStoryInvalid || isFileNameInvalid
		: true;

	const storiesList = stories ? stories.map(i => `${i.id}-${i.title}`) : [];

	const validateStory = (story: string): string => {
		if (
			story.length &&
			!storiesList.includes(story) &&
			!isStoriesDisabled
		) {
			return AppMessage.ValidationNoStory;
		}

		setIsStoryInvalid(false);

		return "";
	};

	const onChangeStory = (e: FormEvent): void => {
		const value = (e.target as HTMLInputElement).value;

		if (!storiesList.includes(story)) {
			setIsStoryInvalid(true);
		}

		if (value === story) {
			setIsMetadataChanged(false);
		} else {
			setIsMetadataChanged(true);
		}

		setNewStory(value);
	};

	const onChangeName = (e: FormEvent): void => {
		const value = (e.target as HTMLInputElement).value;
		if (value === name) {
			setIsMetadataChanged(false);
		} else {
			setIsMetadataChanged(true);
		}

		setNewName(value);
	};

	const onChangeDescription = (e: FormEvent): void => {
		const value = (e.target as HTMLInputElement).value;
		if (value === description) {
			setIsMetadataChanged(false);
		} else {
			setIsMetadataChanged(true);
		}

		setNewDescription(value);
	};

	const validateFileName = (fileName: string): string => {
		if (
			name !== newName &&
			!checkNewFileNameForUnique(filesInfo, dirtyQueue[0], fileName)
		) {
			setIsFileNameInvalid(true);
			return AppMessage.NotUniqueFileName;
		}

		if (!fileName.length) {
			setIsFileNameInvalid(true);
			return AppMessage.EmptyFileName;
		}

		setIsFileNameInvalid(false);
		return "";
	};

	const getValue = (): string | undefined => {
		if (differentStories) {
			return AppMessage.DifferentStories;
		}

		if (noStories) {
			return AppMessage.NoStories;
		}

		if (stories === null) {
			return "Loading Wolftech data...";
		}

		if (!newStory.length) {
			return "";
		}

		return newStory;
	};

	const onCancel = (): void => {
		setNewStory(story);
		setNewName(name);
		setNewDescription(description);
		controlPanel(PanelKind.None);
		setIsMetadataChanged(false);
	};

	const onSaveChanges = (): void => {
		if (story != newStory) {
			changeStory(newStory);
		}

		if (name != newName) {
			changeFileName(newName);
		}

		if (description != newDescription) {
			changeDescription(newDescription);
		}

		controlPanel(PanelKind.None);
		setIsMetadataChanged(false);
	};

	useEffect(() => {
		setNewStory(getFullStory(dirtyQueue[0]) ?? "");
		setNewName(dirtyQueue[0].name);
		setNewDescription(dirtyQueue[0].description);
	}, [dirtyQueue]);

	return (
		<Panel
			isOpen={openedPanel == PanelKind.Metadata}
			title={<Text variant="xLarge">Add metadata</Text>}
		>
			<section className="add-metadata">
				<section className="add-metadata__content">
					{features.storiesFeature && (
						<div className="add-metadata__content-datalist">
							<TextField
								list="story-list"
								id="stories"
								name="stories"
								label="Story"
								onChange={onChangeStory}
								onGetErrorMessage={validateStory}
								validateOnLoad={false}
								disabled={isStoriesDisabled}
								placeholder={noStory}
								value={getValue()}
							/>

							{stories && stories.length && (
								<datalist id="story-list">
									{stories.map(i => (
										<option
											key={`${i.id}-${i.title}`}
											value={`${i.id}-${i.title}`}
										/>
									))}
								</datalist>
							)}
						</div>
					)}

					<TextField
						label="File name"
						disabled={isFileNameDisabled}
						onChange={onChangeName}
						value={
							isFileNameDisabled
								? AppMessage.NoNameMultipleFiles
								: newName
						}
						onGetErrorMessage={validateFileName}
						validateOnLoad={false}
					/>

					<TextField
						label="Description"
						disabled={isDescriptionDisabled}
						onChange={onChangeDescription}
						value={
							isDescriptionDisabled
								? AppMessage.DifferentDescriptions
								: newDescription
						}
						multiline
						rows={6}
						resizable={false}
					/>
				</section>

				<div className="add-metadata__controls">
					<PrimaryButton
						iconProps={{ iconName: "Completed" }}
						text="Save changes"
						onClick={onSaveChanges}
						disabled={isSaveChangesButtonDisabled}
						className="add-metadata__controls-btn"
					/>

					<DefaultButton
						text="Cancel"
						onClick={onCancel}
						className="add-metadata__controls-btn"
					/>
				</div>
			</section>
		</Panel>
	);
}

export default AddMetadata;
