import { defaultMimeType, mimeTypes } from "../../../domain/mimes";
import capitalize from "../../utils/capitalize";

type Deps = {
	capitalize: typeof capitalize;
};

const defaultDeps: Deps = {
	capitalize,
};

/**
 * Get category for file by extension from mimes.
 *
 * @param ext
 * @param typeKeys
 * @param name
 * @returns File extension.
 */
function getCategory(
	ext: string,
	typeKeys: string[],
	name: string,
	deps: Deps = defaultDeps,
): string {
	const { capitalize } = deps;

	let category = capitalize(defaultMimeType);
	let isCategory = false;

	if (!ext.length) {
		return category;
	}

	typeKeys.forEach(t => {
		if (mimeTypes[t].extensions.includes(ext.toLowerCase())) {
			category = mimeTypes[t].category;
			isCategory = true;
		}
	});

	return isCategory
		? category
		: name.split(".")[name.split(".").length - 1].toUpperCase();
}

export default getCategory;
