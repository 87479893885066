import path from "path";
import { LocationFolder } from "./locationFolder";
import { UploadLocation } from "./uploadLocation";

export type LocationsFoldersTree = {
	[location: string]: LocationFolder[];
};

export function setLocationFoldersTree(
	locationsFoldersTree: LocationsFoldersTree,
	selectedUploadLocation: UploadLocation,
	folders: LocationFolder[],
): LocationsFoldersTree {
	locationsFoldersTree[`${selectedUploadLocation.title}`] = folders;

	return locationsFoldersTree;
}

export function getLocationFoldersTree(
	locationsFoldersTree: LocationsFoldersTree,
	selectedUploadLocation: UploadLocation,
): LocationFolder[] | undefined {
	if (!locationsFoldersTree[`${selectedUploadLocation.title}`]) {
		locationsFoldersTree[`${selectedUploadLocation.title}`] = [];
	}
	return locationsFoldersTree[`${selectedUploadLocation.title}`];
}

export function insertInLocationFoldersTree(
	locationsFoldersTree: LocationsFoldersTree,
	locationFoldersTree: LocationFolder[],
	locationPaths: string[],
): LocationsFoldersTree {
	locationPaths.forEach(locationPath => {
		const splitedPath = path.normalize(locationPath).split("/");
		const root = locationFoldersTree.find(p => p.name === splitedPath[0]);

		if (!root) {
			if (splitedPath[0].length) {
				locationFoldersTree.push({
					name: splitedPath[0],
					path: splitedPath[0],
					key: splitedPath[0],
				});
			}
		} else {
			/** Recursive add or set children to folder */
			const addChildren = (
				root: LocationFolder,
				splitedPath: string[],
				folderName: string,
				folderIdx: number,
				deep = 0,
			): void => {
				if (deep === folderIdx - 1) {
					if (!root.childrens) {
						root.childrens = [];
					}

					if (!root.childrens.find(c => c.name === folderName)) {
						let path = "";
						for (let idx = 0; idx <= folderIdx; idx++) {
							path += `${splitedPath[idx]}/`;
						}
						const parent: LocationFolder = {
							name: root.name,
							path: root.path,
							key: `${root.name}${root.path}`,
						};

						if (root.parent) {
							parent.parent = root.parent;
						}

						root.childrens.push({
							name: folderName,
							parent,
							path,
							key: `${folderName}${path}`,
						});
					}
				} else {
					const newRoot = root.childrens?.find(
						c => c.name === splitedPath[deep + 1],
					);
					if (newRoot) {
						addChildren(
							newRoot,
							splitedPath,
							splitedPath[deep + 2],
							folderIdx,
							deep + 1,
						);
					}
				}
			};

			for (
				let folderIdx = 1;
				folderIdx < splitedPath.length;
				folderIdx++
			) {
				if (splitedPath[folderIdx].length) {
					addChildren(
						root,
						splitedPath,
						splitedPath[folderIdx],
						folderIdx,
					);
				}
			}
		}
	});

	return locationsFoldersTree;
}
