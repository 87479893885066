import { LocationFolder } from "../domain/locationFolder";
import { getLocationFoldersTree } from "../domain/locationsFoldersTree";
import { LocationTreeBreadCrumb } from "../domain/locationTreeBreadCrumbs";
import storage from "../services/storageAdapter";
import useNotifier from "../services/notificationAdapter";
import { NotificationService, StorageService } from "./ports";
import { AppMessage } from "../domain/appMessages";

type Deps = {
	storage: StorageService;
	notifier: NotificationService;
};

const defaultDeps: Deps = {
	storage,
	notifier: useNotifier(),
};

function navigateByBreadcrumbs(
	item?: LocationTreeBreadCrumb,
	deps: Deps = defaultDeps,
): void {
	if (item) {
		const { storage, notifier } = deps;

		const breadcrumbs = storage.getLocationTreeBreadCrumbs();

		if (!breadcrumbs) {
			notifier.notify("error", AppMessage.NoBreadcrumbs);
			throw new Error(AppMessage.NoBreadcrumbs);
		}

		const selectedLocation = storage.getSelectedUploadLocation();

		if (!selectedLocation) {
			notifier.notify("error", AppMessage.NoSelectedLocation);
			throw new Error(AppMessage.NoSelectedLocation);
		}

		const currBreadcrumb = breadcrumbs.findIndex(i => i.key === item.key);

		/** Update breadcrumbs */
		storage.setLocationTreeBreadCrumbs(
			breadcrumbs.slice(0, currBreadcrumb + 1),
		);

		const selectedFolder = storage.getSelectedLocationFolder();

		if (selectedFolder && selectedFolder.key !== item.key) {
			/** Recursive find parent and set folder via parent path */
			const findAndSetParent = (
				folder: LocationFolder,
				parentKey: string,
			): void => {
				if (folder.key === parentKey) {
					const locationsFoldersTree =
						storage.getLocationsFoldersTree();
					const locationFoldersTree = getLocationFoldersTree(
						locationsFoldersTree,
						selectedLocation,
					);

					if (!locationFoldersTree) {
						notifier.notify("error", AppMessage.NoFoldersTree);
						throw new Error(AppMessage.NoFoldersTree);
					}

					const path = folder.path.split("/");
					let parent = locationFoldersTree.find(
						f => f.name === path[0],
					);

					const curItemIndex = path.length - 1;

					for (let level = 1; level < curItemIndex; level++) {
						parent = parent?.childrens?.find(
							c => c.name === path[level],
						);
					}

					if (!parent) {
						notifier.notify("error", AppMessage.NoParrentFolder);
						throw new Error(AppMessage.NoParrentFolder);
					}

					storage.setSelectedLocationFolder(parent);
				} else {
					if (folder.parent) {
						findAndSetParent(folder.parent, parentKey);
					}
				}
			};

			findAndSetParent(selectedFolder, item.key);
		}
	}
}

export default navigateByBreadcrumbs;
