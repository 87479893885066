import { createEvent, createStore } from "effector";
import { CommonStorageService } from "../../application/ports";
import { AppInitStatus } from "../../domain/appInitStatus";
import Features, { defaultFeatures } from "../../domain/features";
import { PanelKind } from "../../domain/panel";
import { UploaderType } from "../../domain/uploaderType";
import { UploadLocation } from "../../domain/uploadLocation";

const appInitStatus = createStore<AppInitStatus>(AppInitStatus.InProgress);
const setAppInitStatus = createEvent<AppInitStatus>();
appInitStatus.on(setAppInitStatus, (_, status) => status);

const isAppInit = createStore<boolean>(false);
const setIsAppInit = createEvent<boolean>();
isAppInit.on(setIsAppInit, (_, status) => status);

const uploaderType = createStore<UploaderType>(UploaderType.Normal);
const setUploaderType = createEvent<UploaderType>();
function getUploaderType(): UploaderType {
	return uploaderType.getState();
}
const resetUploaderType = createEvent();
uploaderType.on(setUploaderType, (_, type) => type).reset(resetUploaderType);

const isLocationFoldersLoading = createStore<boolean>(false);
const setIsLocationFoldersLoading = createEvent<boolean>();
function getIsLocationFoldersLoading(): boolean {
	return isLocationFoldersLoading.getState();
}
isLocationFoldersLoading.on(setIsLocationFoldersLoading, (_, status) => status);

const openedPanel = createStore<PanelKind>(PanelKind.None);
const setOpenedPanel = createEvent<PanelKind>();
function getOpenedPanel(): PanelKind {
	return openedPanel.getState();
}
openedPanel.on(setOpenedPanel, (_, panelName) => panelName);

const isDarkMode = createStore<boolean>(true);
const setIsDarkMode = createEvent<boolean>();
isDarkMode.on(setIsDarkMode, (_, status) => status);

const locations = createStore<UploadLocation[] | null>(null);
const setLocations = createEvent<UploadLocation[]>();
const getLocations = (): UploadLocation[] | null => locations.getState();
locations.on(setLocations, (_, newLocations) => newLocations);

const features = createStore<Features>(defaultFeatures);
const setFeatures = createEvent<Features>();
const getFeatures = (): Features => features.getState();
features.on(setFeatures, (_, newFeatures) => newFeatures);

const commonStorageService: CommonStorageService = {
	appInitStatus,
	setAppInitStatus,

	uploaderType,
	setUploaderType,
	getUploaderType,
	resetUploaderType,

	isLocationFoldersLoading,
	setIsLocationFoldersLoading,
	getIsLocationFoldersLoading,

	openedPanel,
	setOpenedPanel,
	getOpenedPanel,

	isDarkMode,
	setIsDarkMode,

	locations,
	setLocations,
	getLocations,

	features,
	setFeatures,
	getFeatures,
};

export default commonStorageService;
