import storage from "../services/storageAdapter";
import { StorageService } from "./ports";

type Deps = {
	storage: StorageService;
};

const defaultDeps: Deps = {
	storage,
};

function setSortedColumn(
	columnName: string,
	isSortedDescending: boolean,
	deps: Deps = defaultDeps,
): void {
	const { storage } = deps;

	storage.setSortedColumn({ columnName, isSortedDescending });
}

export default setSortedColumn;
