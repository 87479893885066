import storage from "../services/storageAdapter";
import { StorageService } from "./ports";

type Deps = {
	storage: StorageService;
};

const defaultDeps: Deps = {
	storage,
};

function setNewFolderNameDraft(
	folderName: string,
	deps: Deps = defaultDeps,
): string | null {
	const { storage } = deps;

	storage.setNewFolderNameDraft(folderName);

	return storage.getNewFolderNameDraft();
}

export default setNewFolderNameDraft;
