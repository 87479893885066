import { UploadLocation } from "./uploadLocation";

export type CachedItem = string | null;

export type CachedLocation = UploadLocation | null;

export type CachedRule = boolean | null;

export enum CachedItemName {
	RecentFolder = "recentFolder",
	RecentLocationTitle = "recentLocationTitle",
	RecentLocationId = "recentLocationId",
	RecentUploadPath = "recentUploadPath",
	RecentSubfolderNamingStandardRule = "recentSubfolderNamingStandardRule",
	IsDarkMode = "isDarkMode",
}

export type CachedData = {
	recentFolder: CachedItem;
	recentLocation: CachedLocation;
	recentUploadPath: CachedItem;
	recentSubfolderNamingStandardRule: CachedRule;
	isDarkMode: CachedRule;
};
