import { IObjectWithKey, Selection } from "@fluentui/react";
import { makeAutoObservable } from "mobx";
import setDirtyQueue from "../../application/setDirtyQueue";
import { FileInfo } from "../../domain/fileInfo";

class SelectionStore {
	private selection: Selection<IObjectWithKey>;

	constructor() {
		this.selection = this.initialSelection;
		makeAutoObservable(this);
	}

	private getSelectionDetails(): IObjectWithKey[] {
		return this.selection.getSelection();
	}

	private initialSelection = new Selection({
		onSelectionChanged: (): void => {
			setDirtyQueue(
				this.getSelectionDetails().length
					? (this.getSelectionDetails() as FileInfo[])
					: [],
			);
		},
	});

	getSelection = (): Selection<IObjectWithKey> => this.selection;

	selectAll = (): void => {
		const newSelection = this.selection;
		newSelection.setAllSelected(true);
		this.selection = newSelection;
	};

	deselectAll = (): void => {
		const newSelection = this.selection;
		newSelection.setAllSelected(false);
		this.selection = newSelection;
	};

	resetSelection = (): void => {
		this.selection = this.initialSelection;
		this.deselectAll();
	};
}

const selectionStore = new SelectionStore();

export default selectionStore;
