import { createEvent, createStore } from "effector";
import { DataStorageService } from "../../application/ports";
import { FileInfo, FileWithPath } from "../../domain/fileInfo";
import { SortedColumn } from "../../domain/sortedColumn";
import { Story } from "../../domain/story";

const isDataDragNDropped = createStore<boolean>(false);
const setIsDataDragNDropped = createEvent<boolean>();
function getIsDataDragNDropped(): boolean {
	return isDataDragNDropped.getState();
}
isDataDragNDropped.on(setIsDataDragNDropped, (_, status) => status);

const isNeedToPreservePath = createStore<boolean>(false);
const setIsNeedToPreservePath = createEvent<boolean>();
function getIsNeedToPreservePath(): boolean {
	return isNeedToPreservePath.getState();
}
isNeedToPreservePath.on(setIsNeedToPreservePath, (_, status) => status);

const baseFileMetadata = createStore<FileWithPath | null>(null);
const setBaseFileMetadata = createEvent<FileWithPath | null>();
function getBaseFileMetadata(): FileWithPath | null {
	return baseFileMetadata.getState();
}
baseFileMetadata.on(setBaseFileMetadata, (_, newFileInfo) => newFileInfo);

const isInfoCreating = createStore<boolean>(false);
const setIsInfoCreating = createEvent<boolean>();
function getIsInfoCreating(): boolean {
	return isInfoCreating.getState();
}
isInfoCreating.on(setIsInfoCreating, (_, status) => status);

const filesInfo = createStore<FileInfo[] | null>(null);
const setFilesInfo = createEvent<FileInfo[]>();
function getFilesInfo(): FileInfo[] | null {
	return filesInfo.getState();
}
const resetFilesInfo = createEvent();
filesInfo.on(setFilesInfo, (_, info) => info).reset(resetFilesInfo);

const isFilesSelectedInFileList = createStore<boolean>(false);
const setIsFilesSelectedInFileList = createEvent<boolean>();
function getIsFilesSelectedInFileList(): boolean {
	return isFilesSelectedInFileList.getState();
}
isFilesSelectedInFileList.on(
	setIsFilesSelectedInFileList,
	(_, status) => status,
);

const sortedColumn = createStore<SortedColumn | null>(null);
const setSortedColumn = createEvent<SortedColumn>();
function getSortedColumn(): SortedColumn | null {
	return sortedColumn.getState();
}
sortedColumn.on(setSortedColumn, (_, column) => column);

const isNeedToSort = createStore<boolean>(false);
const setIsNeedToSort = createEvent<boolean>();
function getIsNeedToSort(): boolean {
	return isNeedToSort.getState();
}
isNeedToSort.on(setIsNeedToSort, (_, status) => status);

const isCameraCardImported = createStore<boolean>(false);
const setIsCameraCardImported = createEvent<boolean>();
const getIsCameraCardImported = (): boolean => isCameraCardImported.getState();
isCameraCardImported.on(setIsCameraCardImported, (_, status) => status);

const stories = createStore<Story[] | null>(null);
const setStories = createEvent<Story[]>();
const getStories = (): Story[] | null => stories.getState();
const resetStories = createEvent();
stories.on(setStories, (_, newStories) => newStories).reset(resetStories);

const ignoreList = createStore<string[]>([]);
const setIgnoreList = createEvent<string[]>();
const getIgnoreList = (): string[] => ignoreList.getState();
ignoreList.on(setIgnoreList, (_, newList) => newList);

const ignoredFilesPaths = createStore<string[]>([]);
const setIgnoredFilePaths = createEvent<string[]>();
const getIgnoredFilePaths = (): string[] => ignoredFilesPaths.getState();
ignoredFilesPaths.on(setIgnoredFilePaths, (_, paths) => paths);

const dataStorageService: DataStorageService = {
	isDataDragNDropped,
	setIsDataDragNDropped,
	getIsDataDragNDropped,

	isNeedToPreservePath,
	setIsNeedToPreservePath,
	getIsNeedToPreservePath,

	baseFileMetadata,
	setBaseFileMetadata,
	getBaseFileMetadata,

	isInfoCreating,
	setIsInfoCreating,
	getIsInfoCreating,

	filesInfo,
	setFilesInfo,
	getFilesInfo,
	resetFilesInfo,

	isFilesSelectedInFileList,
	setIsFilesSelectedInFileList,
	getIsFilesSelectedInFileList,

	sortedColumn,
	setSortedColumn,
	getSortedColumn,

	isNeedToSort,
	setIsNeedToSort,
	getIsNeedToSort,

	isCameraCardImported,
	setIsCameraCardImported,
	getIsCameraCardImported,

	stories,
	setStories,
	getStories,
	resetStories,

	ignoreList,
	setIgnoreList,
	getIgnoreList,

	ignoredFilesPaths,
	setIgnoredFilePaths,
	getIgnoredFilePaths,
};

export default dataStorageService;
