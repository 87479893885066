import { CachedData } from "../domain/cache";
import cacheService from "../services/cacheAdapter";
import storage from "../services/storageAdapter";
import { CacheService, StorageService } from "./ports";

type Deps = {
	storage: StorageService;
	cacheService: CacheService;
};

const defaultDeps = {
	storage,
	cacheService: cacheService,
};

function applyCache(deps: Deps = defaultDeps): CachedData {
	const { storage, cacheService } = deps;

	const cache = cacheService.getCache();

	if (cache.recentFolder) {
		storage.setRecentUploadFolder(cache.recentFolder);
	}

	if (cache.recentLocation) {
		storage.setRecentLocation(cache.recentLocation);
	}

	if (cache.recentUploadPath) {
		storage.setRecentUploadPath(cache.recentUploadPath);
	}

	if (cache.recentSubfolderNamingStandardRule) {
		storage.setRecentSubfolderNamingStandardRule(
			!!cache.recentSubfolderNamingStandardRule,
		);
	}

	storage.setIsDarkMode(!!cache.isDarkMode);

	return {
		recentFolder: storage.getRecentUploadFolder(),
		recentLocation: storage.getRecentLocation(),
		recentUploadPath: storage.getRecentUploadPath(),
		recentSubfolderNamingStandardRule:
			storage.getRecentSubfolderNamingStandardRule(),
		isDarkMode: cache.isDarkMode,
	};
}

export default applyCache;
