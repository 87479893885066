import { FileWithPath } from "../../../domain/fileInfo";

/**
 * Searches for a thumbnail file.
 * It takes an xml element (from a metadata file),
 * takes a thumbnail uri from it
 * and return a thumbnail-file as a string.
 *
 * @param element XML element (from a metadata file)
 * @param files Imported files
 * @param isDroped
 * @returns Thmbnl path
 */
async function getThmbnl(
	element: Element,
	files: FileWithPath[],
	isDroped: boolean,
): Promise<string> {
	return new Promise((resolve, reject) => {
		const info = element.lastElementChild?.getAttribute("uri");
		if (info != null) {
			const infoFileName = info.split("/")[info.split("/").length - 1];
			const infoExt =
				infoFileName.split(".")[infoFileName.split(".").length - 1];
			const isThmbnl = infoExt.toLowerCase() !== "xml";

			if (isThmbnl) {
				let file: FileWithPath | undefined = undefined;
				if (isDroped) {
					files.forEach(i => {
						if (i.path.includes(infoFileName)) {
							file = i;
						}
					});
				} else {
					files.forEach(i => {
						if (i.webkitRelativePath.includes(infoFileName)) {
							file = i;
						}
					});
				}

				if (file != null) {
					const reader = new FileReader();

					reader.onload = (): void => {
						resolve(reader.result as string);
					};
					reader.onerror = (): void => reject();
					reader.readAsDataURL(file);
				} else {
					reject();
				}
			} else {
				resolve("");
			}
		} else {
			reject();
		}
	});
}

export default getThmbnl;
