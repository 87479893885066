import { FileInfo, FileWithPath } from "../../../domain/fileInfo";
import { mimeTypeKeys } from "../../../domain/mimes";
import useMimes from "../../../services/mimesAdapter";
import storage from "../../../services/storageAdapter";
import convertTime from "../../utils/convertTime";
import getCategory from "./getCategory";
import getIconPath from "./getIconPath";
import getSize from "../../../utils/getSize";
import getThmbnl from "./getThmbnl";
import { StorageService, MimeService } from "../../ports";
import { notFound, unknown } from "../../../domain/defaultValues";

type Deps = {
	storage: StorageService;
	mime: MimeService;
	getThmbnl: typeof getThmbnl;
	getSize: typeof getSize;
	convertTime: typeof convertTime;
	getCategory: typeof getCategory;
	getIconPath: typeof getIconPath;
};

const defaultDeps: Deps = {
	storage,
	mime: useMimes(),
	getThmbnl,
	getSize,
	convertTime,
	getCategory,
	getIconPath,
};

/**
 * Writes information about files taken from metadata to the store
 * and update status.
 *
 * @param validFiles
 * @param document
 * @returns Files info
 */
async function setCameraCardInfo(
	validFiles: File[],
	document: Document,
	deps: Deps = defaultDeps,
): Promise<FileInfo[] | null> {
	const {
		storage,
		mime,
		getThmbnl,
		getSize,
		convertTime,
		getCategory,
		getIconPath,
	} = deps;

	const isDataDragNDropped = storage.getIsDataDragNDropped();

	const cameraSerial =
		document
			.getElementsByTagName("Properties")[0]
			.getElementsByTagName("System")[0]
			.getAttribute("systemId") ?? notFound;

	const materials = document
		.getElementsByTagName("Contents")[0]
		.getElementsByTagName("Material");

	const filesInfo: FileInfo[] = [];
	const fileList = Object.values(validFiles) as FileWithPath[];

	for (let i = 0; i < materials.length; i++) {
		const material = materials[i];
		const name = material.getAttribute("uri")?.split(/[\\/\\]/gm)[2];
		const thmbnl = await getThmbnl(material, fileList, isDataDragNDropped);

		fileList.find((f, idx) => {
			if (f.name === name) {
				const ct = mime.contentType(
					material.getAttribute("type") ?? notFound,
				);

				const getExt = (type: string, name: string): string => {
					const ext = mime.extension(type);

					if (ext) {
						return ext;
					}

					const splitedName = name.split(".");
					return splitedName[splitedName.length - 1];
				};

				const ext = ct
					? getExt(ct, name ?? notFound)
					: (material.getAttribute("type") ||
							name?.split(".")[name.split(".").length - 1]) ??
					  unknown;

				const type: string | undefined = ct
					? getCategory(ext, mimeTypeKeys, name ?? notFound)
					: name?.split(".")[name.split(".").length - 1];

				filesInfo.push({
					file: f,
					size: getSize(f.size),
					lastModified: convertTime(f.lastModified),
					rawLastModified: f.lastModified,
					id: i,
					key: idx.toString(),
					umid: material.getAttribute("umid") ?? notFound,
					name: name?.split(".")[0] ?? notFound,
					type: type ?? unknown,
					ext,
					thmbnl:
						thmbnl.length > 0
							? thmbnl
							: getIconPath(ext, mimeTypeKeys),
					description: notFound,

					cameraSerialNumber: cameraSerial,
					cameraModelName: "",
					isCameraCardFile: true,
					sourcePath: storage.getIsDataDragNDropped()
						? (f as FileWithPath).path
						: (f as FileWithPath).webkitRelativePath,
				});
			}
		});
	}

	storage.setIsCameraCardImported(true);
	storage.setIsInfoCreating(false);

	storage.setFilesInfo(filesInfo);

	return storage.getFilesInfo();
}

export default setCameraCardInfo;
