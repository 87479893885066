type Features = {
	storiesFeature: boolean;
	cameraCardParsingFeature: boolean;
	retryCount: number;
};

export const defaultFeatures: Features = {
	storiesFeature: false,
	cameraCardParsingFeature: false,
	retryCount: 5,
};

export default Features;
