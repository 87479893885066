import { AppMessage } from "../../domain/appMessages";
import { UploadFileInfo } from "../../domain/fileInfo";
import storage from "../../services/storageAdapter";
import { StorageService } from "../ports";

type Deps = {
	storage: StorageService;
};

const defaultDeps: Deps = {
	storage,
};

function getQueueOrThrowError(deps: Deps = defaultDeps): UploadFileInfo[] {
	const { storage } = deps;

	const queue = storage.queue.getQueue();
	if (!queue) {
		throw new Error(AppMessage.NoQueue);
	}

	return queue;
}

export default getQueueOrThrowError;
