export const dark = {
	palette: {
		themePrimary: "#00bbff",
		themeLighterAlt: "#00070a",
		themeLighter: "#001e29",
		themeLight: "#00384d",
		themeTertiary: "#007099",
		themeSecondary: "#00a5e0",
		themeDarkAlt: "#19c2ff",
		themeDark: "#3dcbff",
		themeDarker: "#70d9ff",
		neutralLighterAlt: "#363e48",
		neutralLighter: "#3c454f",
		neutralLight: "#47505b",
		neutralQuaternaryAlt: "#4e5763",
		neutralQuaternary: "#545d69",
		neutralTertiaryAlt: "#6c7682",
		neutralTertiary: "#eee8e8",
		neutralSecondary: "#f1ebeb",
		neutralPrimaryAlt: "#f4efef",
		neutralPrimary: "#e6dcdc",
		neutralDark: "#f9f7f7",
		black: "#fcfbfb",
		white: "#2f3740",
	},
};

export const light = {
	palette: {
		themePrimary: "#2196f3",
		themeLighterAlt: "#f6fbfe",
		themeLighter: "#daedfd",
		themeLight: "#badefb",
		themeTertiary: "#78bef7",
		themeSecondary: "#3ba1f4",
		themeDarkAlt: "#1f86da",
		themeDark: "#1a71b8",
		themeDarker: "#135388",
		neutralLighterAlt: "#faf9f8",
		neutralLighter: "#f3f2f1",
		neutralLight: "#edebe9",
		neutralQuaternaryAlt: "#e1dfdd",
		neutralQuaternary: "#d0d0d0",
		neutralTertiaryAlt: "#c8c6c4",
		neutralTertiary: "#a19f9d",
		neutralSecondary: "#605e5c",
		neutralPrimaryAlt: "#3b3a39",
		neutralPrimary: "#323130",
		neutralDark: "#201f1e",
		black: "#000000",
		white: "#ffffff",
	},
};
