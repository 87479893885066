import { createStore, createEvent } from "effector";
import { CacheStorageService } from "../../application/ports";
import { CachedItem, CachedLocation, CachedRule } from "../../domain/cache";

const recentUploadFolder = createStore<CachedItem>(null);
const setRecentUploadFolder = createEvent<CachedItem>();
function getRecentUploadFolder(): CachedItem {
	return recentUploadFolder.getState();
}
const resetRecentUploadFolder = createEvent();
recentUploadFolder
	.on(setRecentUploadFolder, (_, folder) => folder)
	.reset(resetRecentUploadFolder);

const recentLocation = createStore<CachedLocation>(null);
const setRecentLocation = createEvent<CachedLocation>();
function getRecentLocation(): CachedLocation {
	return recentLocation.getState();
}
recentLocation.on(setRecentLocation, (_, location) => location);

const recentUploadPath = createStore<CachedItem>(null);
const setRecentUploadPath = createEvent<CachedItem>();
function getRecentUploadPath(): CachedItem {
	return recentUploadPath.getState();
}
recentUploadPath.on(setRecentUploadPath, (_, path) => path);

const recentSubfolderNamingStandardRule = createStore<CachedRule>(null);
const setRecentSubfolderNamingStandardRule = createEvent<CachedRule>();
function getRecentSubfolderNamingStandardRule(): CachedRule {
	return recentSubfolderNamingStandardRule.getState();
}
recentSubfolderNamingStandardRule.on(
	setRecentSubfolderNamingStandardRule,
	(_, rule) => rule,
);

const cacheStorageService: CacheStorageService = {
	recentUploadFolder,
	setRecentUploadFolder,
	getRecentUploadFolder,
	resetRecentUploadFolder,

	recentLocation,
	setRecentLocation,
	getRecentLocation,

	recentUploadPath,
	setRecentUploadPath,
	getRecentUploadPath,

	recentSubfolderNamingStandardRule,
	setRecentSubfolderNamingStandardRule,
	getRecentSubfolderNamingStandardRule,
};

export default cacheStorageService;
