import { User } from "../../domain/user";
import { createState } from "@persevie/statemanjs";
import { UserStorageService } from "../../application/ports";

export const userState = createState<User | null>(null);

function setUser(user: User): void {
	userState.set(user);
}
function getUser(): User | null {
	return userState.get();
}
function resetUser(): void {
	userState.set(null);
}

const userStorageService: UserStorageService = {
	setUser,
	getUser,
	resetUser,
};

export default userStorageService;
