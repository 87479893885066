import mime from "mime-types";

type UseMimeOut = {
	contentType(filenameOrExt: string): string | false;
	extension(typeString: string): string | false;
};

function useMimes(): UseMimeOut {
	function contentType(filenameOrExt: string): string | false {
		return mime.contentType(filenameOrExt);
	}

	function extension(typeString: string): string | false {
		return mime.extension(typeString);
	}

	return {
		contentType,
		extension,
	};
}

export default useMimes;
