import React from "react";
import {
	Callout,
	CommandBarButton,
	Persona,
	PersonaInitialsColor,
	PersonaSize,
	Toggle,
	Text,
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import packageJson from "../../../../../../package.json";
import { useStore } from "effector-react";
import storage from "../../../services/storageAdapter";
import { defaultAuthorizedUserName } from "../../../domain/user";
import "./User.scss";
import { AppMessage } from "../../../domain/appMessages";
import toggleTheme from "../../../application/toggleTheme";
import { useStatemanjs } from "@persevie/statemanjs-react";
import { userState } from "../../../services/storage/userStorageService";
import signOut from "../../../application/auth/signOut";

function onSignOut(): void {
	signOut();
}

function User(): JSX.Element {
	const user = useStatemanjs(userState);
	const isDarkMode = useStore(storage.isDarkMode);

	if (!user) {
		throw new Error(AppMessage.NoUser);
	}

	const userName = user.name ?? defaultAuthorizedUserName;

	const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] =
		useBoolean(false);

	const buildNumber = process.env.BUILD_NUMBER;
	const appVersion = buildNumber
		? `${packageJson.version}.${buildNumber}`
		: packageJson.version;

	const onToggleTheme = (
		ev: React.MouseEvent<HTMLElement>,
		checked?: boolean,
	): void => {
		toggleTheme(!!checked);
	};

	return (
		<div>
			<Persona
				className="user user_authorized"
				text={userName}
				onRenderPrimaryText={(): JSX.Element => <></>}
				initialsColor={PersonaInitialsColor.blue}
				size={PersonaSize.size32}
				onClick={toggleIsCalloutVisible}
				hidePersonaDetails
				initialsTextColor={"white"}
			/>
			{isCalloutVisible ? (
				<Callout
					role="alertdialog"
					gapSpace={0}
					target={".user"}
					onDismiss={toggleIsCalloutVisible}
					setInitialFocus
				>
					<div className="user__callout">
						<Toggle
							label={<Text variant="medium">Dark mode</Text>}
							checked={isDarkMode}
							onChange={onToggleTheme}
							inlineLabel
							role="checkbox"
							aria-describedby="toggleId"
							className="user__callout-items"
							styles={{
								root: { margin: "0", padding: "0 8px" },
								label: { margin: "8px" },
							}}
						/>

						<CommandBarButton
							iconProps={{ iconName: "SignOut" }}
							text="Sign out"
							className="user__callout-items"
							onClick={onSignOut}
						/>

						<CommandBarButton
							className="user__callout-items"
							text={`ver. ${appVersion}`}
							disabled
						/>
					</div>
				</Callout>
			) : null}
		</div>
	);
}

export default User;
