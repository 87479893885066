import { AppMessage } from "../domain/appMessages";

/**
 * Define a measure of information by size of file.
 *
 * @param size Size in bytes
 * @returns A measure of information (mb, gb etc).
 */
function getSize(size: number): string {
	if (size < 1024) {
		return `${Math.round(size)} B`;
	} else if (size >= 1024 && size < 1048576) {
		return `${Math.round(size / 1000)} KB`;
	} else if (size >= 1048576 && size < 1073741824) {
		return `${Math.round(size * 1e-6 * 100) / 100} MB`;
	} else if (size >= 1073741824) {
		return `${Math.round(size * 1e-9 * 100) / 100} GB`;
	} else {
		return AppMessage.CantCalculated;
	}
}

export default getSize;
