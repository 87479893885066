import { LocationFolder } from "../domain/locationFolder";
import { LocationTreeBreadCrumb } from "../domain/locationTreeBreadCrumbs";
import storage from "../services/storageAdapter";
import getLocationFolders from "./getLocationFolders";
import navigateByBreadcrumbs from "./navigateByBreadcrumbs";
import { StorageService } from "./ports";
import resetBreadcrumbs from "./resetBreadcrumbs";

type Deps = {
	storage: StorageService;
	navigateByBreadcrumbs: typeof navigateByBreadcrumbs;
	getLocationFolders: typeof getLocationFolders;
};

const defaultDeps: Deps = {
	storage,
	navigateByBreadcrumbs,
	getLocationFolders,
};

/**
 * Set selected folder to the store and update breadcrumbs
 *
 * @param folder
 * @param deps
 * @returns Selected folder or null
 */
function selectFolder(
	folder: LocationFolder,
	deps: Deps = defaultDeps,
): LocationFolder | null {
	const { storage, navigateByBreadcrumbs, getLocationFolders } = deps;

	storage.setSelectedLocationFolder(folder);

	const locationTreeBreadCrumbs =
		storage.getLocationTreeBreadCrumbs() ?? resetBreadcrumbs();

	const newItems = locationTreeBreadCrumbs.slice();
	newItems.push({
		text: folder.name,
		key: folder.name + folder.path,
		onClick: (
			ev?: HTMLElement | MouseEvent,
			item?: LocationTreeBreadCrumb,
		) => navigateByBreadcrumbs(item),
	});

	storage.setLocationTreeBreadCrumbs(newItems);

	if (!folder.childrens) {
		getLocationFolders();
	}

	return storage.getSelectedLocationFolder();
}

export default selectFolder;
