import {
	AirUploadParams,
	NameOfAirUploadParams,
} from "../domain/airUploadParams";
import { AppInitStatus } from "../domain/appInitStatus";
import { UploaderType } from "../domain/uploaderType";
import storage from "../services/storageAdapter";
import useNotifier from "../services/notificationAdapter";
import { NotificationService, StorageService } from "./ports";
import { AppMessage } from "../domain/appMessages";

type Deps = {
	storage: StorageService;
	notifier: NotificationService;
};

const defaultDeps = {
	storage,
	notifier: useNotifier(),
};

/**
 * Get params from AIR by parsing the query string.
 *
 * @param deps
 * @returns AIR upload params
 */
function getUploadParamsFromAir(deps: Deps = defaultDeps): AirUploadParams {
	const { storage, notifier } = deps;

	const isExternalApp = storage.getUploaderType() == UploaderType.External;

	if (!isExternalApp) {
		notifier.notify("error", AppMessage.NotExternalApp);
		storage.setAppInitStatus(AppInitStatus.Failed);
		throw new Error(AppMessage.NotExternalApp);
	}

	const params = new URLSearchParams(window.location.search);
	const locationId = params.get(NameOfAirUploadParams.LocationId);
	const uploadPath = params.get(NameOfAirUploadParams.UploadPath);

	if (!locationId || (locationId && !locationId.length)) {
		notifier.notify("error", AppMessage.NoSelectedLocationId);
		storage.setAppInitStatus(AppInitStatus.Failed);
		throw new Error(AppMessage.NoSelectedLocationId);
	}

	const path = uploadPath ?? "";
	return { locationId, path };
}

export default getUploadParamsFromAir;
