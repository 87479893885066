import { AppMessage } from "../domain/appMessages";
import { getLocationFolderNameIsNotUniqueMessage } from "../domain/locationFolder";
import { getLocationFoldersTree } from "../domain/locationsFoldersTree";
import storage from "../services/storageAdapter";
import { StorageService } from "./ports";

type Deps = {
	storage: StorageService;
};

const defaultDeps: Deps = {
	storage,
};

function validateNewFolderName(
	folderName: string,
	deps: Deps = defaultDeps,
): string {
	const { storage } = deps;

	if (!folderName.length) {
		storage.setIsFolderNameInvalid(true);
		return AppMessage.ValidationEmptyName;
	}

	if (folderName.includes("/") || folderName.includes("\\")) {
		storage.setIsFolderNameInvalid(true);
		return AppMessage.ValidationSeparatorSymbol;
	}

	const selectedLocationFolder = storage.getSelectedLocationFolder();
	const selectedLocation = storage.getSelectedUploadLocation();

	if (selectedLocationFolder) {
		if (
			selectedLocationFolder.childrens?.find(f => f.name === folderName)
		) {
			storage.setIsFolderNameInvalid(true);
			return getLocationFolderNameIsNotUniqueMessage(folderName);
		}
	} else {
		if (selectedLocation) {
			const locationsFoldersTree = storage.getLocationsFoldersTree();
			const locationFoldersTree = getLocationFoldersTree(
				locationsFoldersTree,
				selectedLocation,
			);

			if (
				locationFoldersTree &&
				locationFoldersTree.find(f => f.name === folderName)
			) {
				storage.setIsFolderNameInvalid(true);
				return getLocationFolderNameIsNotUniqueMessage(folderName);
			}
		}
	}

	storage.setIsFolderNameInvalid(false);
	return "";
}

export default validateNewFolderName;
