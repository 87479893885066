import { NotificationService } from "../application/ports";
import {
	notify as notifyService,
	findNotifyItem as findNotifyItemService,
	deleteNotifyItem as deleteNotifyItemService,
} from "./notifier";

/**
 * Adapter for notifier service
 *
 * @returns NotificationService contract
 */
function useNotifier(): NotificationService {
	/**
	 * Show message bar
	 *
	 * @param notifyType
	 * @param message
	 * @param time Time is part of the ID (needed to delete or search for a message in the DOM)
	 * @param autoHideTimerMs Default - 0ms. Pass 0 for disable auto hide
	 * @param position
	 */
	function notify(
		notifyType: "error" | "info" | "warn" | "success",
		message: string,
		time: number = new Date().getTime(),
		autoHideTimerMs?: number,
		position?: "bottom" | "top",
	): void {
		switch (notifyType) {
			case "error":
				message = `Error: ${message}`;
				break;

			case "info":
				message = `Info: ${message}`;
				break;

			case "warn":
				message = `Warning: ${message}`;
				break;

			case "success":
				message = `Success: ${message}`;
				break;
		}
		notifyService(
			notifyType,
			message,
			time,
			autoHideTimerMs ?? 0,
			position ?? "bottom",
		);
	}

	/**
	 * Find and return message by time.
	 *
	 * @param time
	 * @returns True or false
	 */
	function findNotifyItem(time: number): boolean {
		return findNotifyItemService(time);
	}

	function deleteNotifyItem(time: number): void {
		deleteNotifyItemService(time);
	}

	return { notify, findNotifyItem, deleteNotifyItem };
}

export default useNotifier;
