import { LocationService } from "../application/ports";
import { getErrorMessage } from "../application/utils/errorFormater";
import { LocationFolderNames } from "../domain/locationFolderNames";
import { UploadLocation } from "../domain/uploadLocation";
import api from "./utils/api";

type Deps = {
	api: typeof api;
	getErrorMessage: typeof getErrorMessage;
};

const defaultDeps = { api, getErrorMessage };

export function useLocations(): LocationService {
	async function getLocations(
		url: string,
		deps: Deps = defaultDeps,
	): Promise<UploadLocation[]> {
		const { api, getErrorMessage } = deps;

		try {
			const res = await api().get(url);
			return res.data;
		} catch (error) {
			throw new Error(getErrorMessage(error));
		}
	}

	async function getCurrentLocationFolders(
		url: string,
		locationId: string,
		folderPath: string,
		deps: Deps = defaultDeps,
	): Promise<LocationFolderNames> {
		const { api, getErrorMessage } = deps;

		try {
			const res = await api().get(url, {
				headers: {
					locationId: `${locationId}`,
					folderPath: encodeURIComponent(folderPath),
				},
			});

			return res.data;
		} catch (error) {
			throw new Error(getErrorMessage(error));
		}
	}

	return { getLocations, getCurrentLocationFolders };
}
