export const defaultAuthorizedUserName = "Authorized User";

export type User = {
	userName: string;
	userToken: string;
	userIdGroups: string[];
	id: string;
	name: string;
	token: string;
	permissions: string[];
	sourceLocations: string[];
	rePermissionLocations: string[];
	expirationDate: Date;
};

export type UserCredentials = {
	accessToken: string;
	expiresIn: number;
	user: Omit<User, "token" | "expirationDate">;
};
