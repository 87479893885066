import { checkNewFileNameForUnique, FileInfo } from "../domain/fileInfo";
import storage from "../services/storageAdapter";
import useNotifier from "../services/notificationAdapter";
import { NotificationService, StorageService } from "./ports";
import { AppMessage } from "../domain/appMessages";
import planSorting from "./planSorting";
import setDirtyQueue from "./setDirtyQueue";

type Deps = {
	storage: StorageService;
	notifier: NotificationService;
	planSorting: typeof planSorting;
	setDirtyQueue: typeof setDirtyQueue;
};

const defaultDeps: Deps = {
	storage,
	notifier: useNotifier(),
	planSorting,
	setDirtyQueue,
};

/**
 * Changes the file name
 *
 * @param fileName
 * @param deps
 * @returns File info or null
 */
function changeFileName(
	fileName: string,
	deps: Deps = defaultDeps,
): FileInfo[] | null {
	const { storage, notifier, planSorting, setDirtyQueue } = deps;

	const filesInfo = storage.getFilesInfo();
	const dirtyQueue = storage.getDirtyQueue();

	if (!filesInfo) {
		notifier.notify("error", AppMessage.NoFilesInfo);
		throw new Error(AppMessage.NoFilesInfo);
	}

	if (!dirtyQueue) {
		notifier.notify("error", AppMessage.NoDirtyQueue);
		throw new Error(AppMessage.NoDirtyQueue);
	}

	if (checkNewFileNameForUnique(filesInfo, dirtyQueue[0], fileName)) {
		const filesInfoFileIndex = filesInfo.findIndex(
			i => i.id === dirtyQueue[0].id,
		);
		if (filesInfoFileIndex === -1) {
			notifier.notify("error", AppMessage.NoFileInfo);
			throw new Error(AppMessage.NoFileInfo);
		}

		filesInfo[filesInfoFileIndex].name = fileName;

		const sortedColumn = storage.getSortedColumn();

		if (sortedColumn && sortedColumn.columnName === "name") {
			planSorting(true);
		}

		storage.setFilesInfo([...filesInfo]);
		setDirtyQueue([filesInfo[filesInfoFileIndex]]);
	}

	return storage.getFilesInfo();
}

export default changeFileName;
