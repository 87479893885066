import { FileInfo } from "../domain/fileInfo";
import storage from "../services/storageAdapter";
import useNotifier from "../services/notificationAdapter";
import { NotificationService, StorageService } from "./ports";
import { AppMessage } from "../domain/appMessages";
import setDirtyQueue from "./setDirtyQueue";

type Deps = {
	storage: StorageService;
	notifier: NotificationService;
	setDirtyQueue: typeof setDirtyQueue;
};

const defaultDeps: Deps = {
	storage,
	notifier: useNotifier(),
	setDirtyQueue,
};

/**
 * Changes the file description
 *
 * @param description File description
 * @param deps
 * @returns File info or null
 */
function changeDescription(
	description: string,
	deps: Deps = defaultDeps,
): FileInfo[] | null {
	const { storage, notifier, setDirtyQueue } = deps;

	const filesInfo = storage.getFilesInfo();
	if (!filesInfo) {
		notifier.notify("error", AppMessage.NoFilesInfo);
		throw new Error(AppMessage.NoFilesInfo);
	}

	const dirtyQueue = storage.getDirtyQueue();
	if (!dirtyQueue) {
		notifier.notify("error", AppMessage.NoDirtyQueue);
		throw new Error(AppMessage.NoDirtyQueue);
	}

	const newDirtyQueue: FileInfo[] = [];

	dirtyQueue.forEach(element => {
		const filesInfoFileIndex = filesInfo.findIndex(
			i => i.id === element.id,
		);
		if (filesInfoFileIndex === -1) {
			notifier.notify("error", AppMessage.NoFileInfo);
			throw new Error(AppMessage.NoFileInfo);
		}

		filesInfo[filesInfoFileIndex].description = description;
		newDirtyQueue.push(filesInfo[filesInfoFileIndex]);
	});

	storage.setFilesInfo([...filesInfo]);
	setDirtyQueue(newDirtyQueue);

	return storage.getFilesInfo();
}

export default changeDescription;
