import AuthConfig from "../../domain/authConfig";
import useAuth from "../../services/authAdapter";
import cacheService from "../../services/cacheAdapter";
import storageAdapter from "../../services/storageAdapter";
import { getAppError } from "../appError/appError";
import { AppErrorKind } from "../appError/appErrorKind";
import { AuthService, CacheService, StorageService } from "../ports";

type Deps = {
	authService: AuthService;
	storageService: StorageService;
	cacheService: CacheService;
	getAppError: typeof getAppError;
	appErrorKind: typeof AppErrorKind;
};

const defaultDeps: Deps = {
	authService: useAuth,
	storageService: storageAdapter,
	cacheService: cacheService,
	getAppError,
	appErrorKind: AppErrorKind,
};

async function auth(deps: Deps = defaultDeps): Promise<void> {
	const {
		authService,
		storageService,
		cacheService,
		getAppError,
		appErrorKind,
	} = deps;

	storageService.resetAuthError();

	try {
		const user = cacheService.getAuth();

		if (user !== null) {
			if (new Date(user.expirationDate) > new Date()) {
				storageService.setUser(user);

				return;
			}
		}

		const authConfig: AuthConfig = await authService.getAuthConfig();
		cacheService.setAuthState(authConfig.state);
		// TODO: Get location in service
		cacheService.setReturnUri(window.location.href);

		window.location = authConfig.uri as string & Location;
	} catch (error) {
		storageService.setAuthError(
			getAppError(
				error as Error,
				appErrorKind.Auth,
				"error during authorization",
			),
		);
		throw error;
	}
}

export default auth;
