import { AppMessage } from "../domain/appMessages";
import { FileInfo } from "../domain/fileInfo";
import storage from "../services/storageAdapter";
import clearData from "./clearData";
import { StorageService } from "./ports";

type Deps = {
	storage: StorageService;
	clearData: typeof clearData;
};

const defaultDeps: Deps = {
	storage,
	clearData,
};

function removeFiles(
	filesToRemove: FileInfo[],
	deps: Deps = defaultDeps,
): FileInfo[] {
	const { storage, clearData } = deps;

	const files = storage.getFilesInfo();

	if (!files) {
		throw new Error(AppMessage.NoFiles);
	}

	let newFiles = files;

	for (let index = 0; index < filesToRemove.length; index++) {
		const fileToRemove = filesToRemove[index];

		newFiles = newFiles.filter(i => i.id != fileToRemove.id);
	}

	if (!newFiles.length) {
		clearData();
	} else {
		storage.setFilesInfo(newFiles);
	}

	return newFiles;
}

export default removeFiles;
