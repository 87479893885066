import { ValidatorService } from "../application/ports";
import minimatch from "minimatch";

class ValidatorAdapter implements ValidatorService {
	validate(path: string, patterns: string[]): boolean {
		for (const pattern of patterns) {
			if (minimatch(path, pattern)) {
				return false; // path matches a pattern, so it's not valid
			}
		}
		return true; // path doesn't match any pattern, so it's valid
	}
}

export const validatorAdapter = new ValidatorAdapter();
