import { UploadLocation } from "../domain/uploadLocation";
import storage from "../services/storageAdapter";
import { StorageService } from "./ports";
import resetBreadcrumbs from "./resetBreadcrumbs";

type Deps = {
	storage: StorageService;
	resetBreadcrumbs: typeof resetBreadcrumbs;
};

const defaultDeps: Deps = {
	storage,
	resetBreadcrumbs,
};

/**
 * Select upload location and reset breadcrumbs
 *
 * @param location
 * @param deps
 * @returns Selected upload location or null
 */
function selectUploadLocation(
	location: UploadLocation,
	deps: Deps = defaultDeps,
): UploadLocation | null {
	const { storage, resetBreadcrumbs } = deps;

	storage.setSelectedUploadLocation(location);
	resetBreadcrumbs();

	return storage.getSelectedUploadLocation();
}

export default selectUploadLocation;
