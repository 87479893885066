import storage from "../../../services/storageAdapter";
import { StorageService } from "../../ports";

type Deps = {
	storage: StorageService;
};

const defaultDeps: Deps = {
	storage,
};

export function updateChunkStart(
	id: string,
	chunkStart: number,
	deps: Deps = defaultDeps,
): void {
	const { storage } = deps;
	const queue = storage.queue;
	queue.setChunkStart(id, chunkStart);
}

export function updateChunkEnd(
	id: string,
	chunkEnd: number,
	deps: Deps = defaultDeps,
): void {
	const { storage } = deps;

	const queue = storage.queue;
	queue.setChunkEnd(id, chunkEnd);
}

export function updateFileChunkSize(
	id: string,
	fileChunkSize: number,
	deps: Deps = defaultDeps,
): void {
	const { storage } = deps;

	const queue = storage.queue;
	queue.setFileChunkSize(id, fileChunkSize);
}

export function updateBlobEnd(
	id: string,
	blobEnd: number,
	deps: Deps = defaultDeps,
): void {
	const { storage } = deps;

	const queue = storage.queue;
	queue.setBlobEnd(id, blobEnd);
}

export function updateController(
	id: string,
	controller: AbortController,
	deps: Deps = defaultDeps,
): void {
	const { storage } = deps;

	const queue = storage.queue;
	queue.setController(id, controller);
}

export function updateCancelledReason(
	id: string,
	cancelledReason: string,
	deps: Deps = defaultDeps,
): void {
	const { storage } = deps;

	const queue = storage.queue;
	queue.setCancelledReason(id, cancelledReason);
}

export function updateCommonProgress(
	id: string,
	commonProgress: number,
	deps: Deps = defaultDeps,
): void {
	const { storage } = deps;

	const queue = storage.queue;
	queue.setCommonProgress(id, commonProgress);
}

export function updateEta(
	id: string,
	eta: string,
	deps: Deps = defaultDeps,
): void {
	const { storage } = deps;

	const queue = storage.queue;
	queue.setEta(id, eta);
}

export function updateUploadedChunks(
	id: string,
	uploadedChunks: number,
	deps: Deps = defaultDeps,
): void {
	const { storage } = deps;

	const queue = storage.queue;
	queue.setUploadedChunks(id, uploadedChunks);
}

export function updateNumberOfChunks(
	id: string,
	numberOfChunks: number,
	deps: Deps = defaultDeps,
): void {
	const { storage } = deps;

	const queue = storage.queue;
	queue.setNumberOfChunks(id, numberOfChunks);
}

export function updateIsWaiting(
	id: string,
	isWaiting: boolean,
	deps: Deps = defaultDeps,
): void {
	const { storage } = deps;

	const queue = storage.queue;
	queue.setIsWaiting(id, isWaiting);
}

export function updateStartTime(
	id: string,
	startTime: number,
	deps: Deps = defaultDeps,
): void {
	const { storage } = deps;

	const queue = storage.queue;
	queue.setStartTime(id, startTime);
}

export function updateChunksMap(
	id: string,
	chunksMap: string[],
	deps: Deps = defaultDeps,
): void {
	const { storage } = deps;

	const queue = storage.queue;
	queue.setChunksMap(id, chunksMap);
}

export function updateIsFinalCallOk(
	id: string,
	isFinalCallOk: boolean,
	deps: Deps = defaultDeps,
): void {
	const { storage } = deps;

	const queue = storage.queue;
	queue.setIsFinalCallOk(id, isFinalCallOk);
}

export function updateIsFileCreated(
	id: string,
	isFileCreated: boolean,
	deps: Deps = defaultDeps,
): void {
	const { storage } = deps;

	const queue = storage.queue;
	queue.setIsFileCreated(id, isFileCreated);
}

export function updateError(
	id: string,
	error: string,
	deps: Deps = defaultDeps,
): void {
	const { storage } = deps;

	const queue = storage.queue;
	queue.setError(id, error);
}

export function updateBadChunk(
	id: string,
	badChunk: number | null,
	deps: Deps = defaultDeps,
): void {
	const { storage } = deps;

	const queue = storage.queue;
	queue.setBadChunk(id, badChunk);
}

export function updateRetryTimer(
	id: string,
	timer: number,
	deps: Deps = defaultDeps,
): void {
	const { storage } = deps;

	const queue = storage.queue;
	queue.setRetryTimer(id, timer);
}

export function updateErrorToDefault(
	id: string,
	deps: Deps = defaultDeps,
): void {
	const { storage } = deps;

	const queue = storage.queue;
	queue.resetError(id);
}

export function updateIsUploadComplete(
	id: string,
	status: boolean,
	deps: Deps = defaultDeps,
): void {
	const { storage } = deps;

	const queue = storage.queue;
	queue.setIsUploadComplete(id, status);
}

export function updateRetryAttempt(
	id: string,
	retryAtempt: number,
	deps: Deps = defaultDeps,
): void {
	const { storage } = deps;

	const queue = storage.queue;
	queue.setRetryAttempt(id, retryAtempt);
}
