import getSize from "../utils/getSize";
import { UploadLocation } from "./uploadLocation";

export interface FileWithPath extends File {
	webkitRelativePath: string;
	path: string;
}

export type FileInfo = {
	id: number;
	key: string;
	file: File;
	name: string;
	type: string;
	ext: string;
	thmbnl: string;
	size: string;
	lastModified: string;
	rawLastModified: string | number;
	umid: string;
	description: string;
	sourcePath: string;

	// Feature fields
	cameraModelName?: string;
	cameraSerialNumber?: string;
	storyId?: string;
	storyTitle?: string;
	isCameraCardFile?: boolean;
};

export type UploadFileInfo = FileInfo & {
	numberOfChunks: number;
	uploadedChunks: number;
	commonProgress: number;
	path: string;
	isWaiting: boolean;
	leaseId: string;
	isFileCreated: boolean;
	uploadId: string;
	chunkStart: number;
	chunksMap: string[] | null;
	eta: string | null;
	startTime: number | null;
	uploadAttempts: number | null;
	chunkEnd: number | null;
	fileChunkSize: number;
	blobEnd: number | null;
	location: UploadLocation;
	controller: AbortController | null;
	retryTimer: number | null;
	isUploadComplete: boolean;
	error: string | null;
	isFinalCallOk: boolean;
	badChunk: number | null;
	cancelledReason: string | null;
	retryAttempt: number;
};

export function getUploadedSize(fileInfo: UploadFileInfo): string {
	return `${getSize(
		fileInfo.uploadedChunks * fileInfo.fileChunkSize,
	)} of ${getSize(fileInfo.file.size)}`;
}

export function getFileThmbnl(
	filesInfo: FileInfo[],
	fileName: string,
): string | undefined {
	return filesInfo.find(i => i.name === fileName.split(".")[0])?.thmbnl;
}

export function sortFiles(
	files: FileInfo[],
	columnKey: string,
	isSortedDescending?: boolean,
): FileInfo[] {
	const key = columnKey as keyof FileInfo;

	if (!files) {
		throw new Error("");
	}

	return files.slice(0).sort((a: FileInfo, b: FileInfo) => {
		if (key === "name") {
			if (
				`${a.name}.${a.ext}`.toLowerCase() ===
				`${b.name}.${b.ext}`.toLowerCase()
			) {
				return 0;
			}

			return (
				isSortedDescending
					? `${a.name}.${a.ext}`.toLocaleLowerCase() <
					  `${b.name}.${b.ext}`.toLocaleLowerCase()
					: `${a.name}.${a.ext}`.toLocaleLowerCase() >
					  `${b.name}.${b.ext}`.toLocaleLowerCase()
			)
				? 1
				: -1;
		}

		if (key === "storyTitle") {
			if (
				`${a.storyId}.${a.storyTitle}` ===
				`${b.storyId}.${b.storyTitle}`
			) {
				return 0;
			}

			return (
				isSortedDescending
					? `${a.storyId}.${a.storyTitle}` <
					  `${b.storyId}.${b.storyTitle}`
					: `${a.storyId}.${a.storyTitle}` >
					  `${b.storyId}.${b.storyTitle}`
			)
				? 1
				: -1;
		}

		if (key === "type") {
			if (a.type === b.type) {
				return 0;
			}

			return (isSortedDescending ? a.type < b.type : a.type > b.type)
				? 1
				: -1;
		}

		if (key === "size") {
			if (a.file.size === b.file.size) {
				return 0;
			}

			return (
				isSortedDescending
					? a.file.size < b.file.size
					: a.file.size > b.file.size
			)
				? 1
				: -1;
		}

		if (key === "lastModified") {
			if (a.rawLastModified === b.rawLastModified) {
				return 0;
			}

			return (
				isSortedDescending
					? a.rawLastModified < b.rawLastModified
					: a.rawLastModified > b.rawLastModified
			)
				? 1
				: -1;
		}

		return 0;
	});
}

export function checkNewFileNameForUnique(
	filesInfo: FileInfo[],
	fileForUpdate: FileInfo,
	fileName: string,
): boolean {
	return !filesInfo
		.map(i => `${i.name}.${i.ext}`)
		.includes(`${fileName}.${fileForUpdate.ext}`);
}

export function getFullStory(fileInfo: FileInfo): string | undefined {
	if (fileInfo.storyId && fileInfo.storyTitle) {
		return `${fileInfo.storyId}-${fileInfo.storyTitle}`;
	} else if (fileInfo.storyTitle) {
		return fileInfo.storyTitle;
	}
}

export function getUploadingInfoProgress(
	uploadingInfoItem: UploadFileInfo,
): number {
	if (uploadingInfoItem.isFinalCallOk) {
		return 1;
	} else {
		return (
			uploadingInfoItem.uploadedChunks /
			(uploadingInfoItem.numberOfChunks / 100) /
			100
		);
	}
}

export function getUploadingInfoEta(rawEta: number): string {
	let time: string;

	if (rawEta === Infinity) {
		time = "calculating...";
	} else if (rawEta < 1000) {
		time = `${Math.round(rawEta)}ms`;
	} else if (rawEta > 1000 && rawEta < 60000) {
		time = `${Math.round(rawEta / 1000)}s`;
	} else if (rawEta > 60000 && rawEta < 3.6e6) {
		time = `${Math.round(rawEta / 60000)}m`;
	} else if (rawEta > 3.6e6) {
		time = `${Math.round(rawEta / 3.6e6)}h`;
	} else {
		time = "";
	}

	if (rawEta === Infinity) {
		return `ETA: ${time}`;
	}

	return `ETA: about ${time}`;
}
