import { CachedItemName } from "../domain/cache";
import storage from "../services/storageAdapter";
import applyTheme from "./applyTheme";
import { StorageService } from "./ports";

type Deps = {
	storage: StorageService;
	applyTheme: typeof applyTheme;
};

const defaultDeps: Deps = {
	storage,
	applyTheme,
};

function toggleTheme(isDarkMode: boolean, deps: Deps = defaultDeps): void {
	const { storage, applyTheme } = deps;

	localStorage.setItem(CachedItemName.IsDarkMode, `${isDarkMode}`);
	applyTheme(isDarkMode);
	storage.setIsDarkMode(isDarkMode);
}

export default toggleTheme;
