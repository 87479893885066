export const foldersUrl = "/api/folders";

export const locationsUrl = "/api/locations";

export const authConfigUrl = "/api/auth/config";

export const storiesUrl = "/api/metadata/stories";

export const featuresUrl = "/api/features";

/** URL for upload files. */
export function getUploadUrl(locationId: string): string {
	return `/api/files/upload/${locationId}/`;
}

/** URL for complete upload file. */
export function getCompleteUploadUrl(locationId: string): string {
	return `/api/files/upload/${locationId}/complete`;
}

export function getFailUploadUrl(locationId: string): string {
	return `/api/files/upload/${locationId}/fail`;
}

export function getCreateFileUrl(locationId: string): string {
	return `/api/files/create/${locationId}`;
}
