import path from "path";
import { foldersUrl } from "../services/utils/endpoints";
import storage from "../services/storageAdapter";
import { useLocations } from "../services/locationsAdapter";
import { LocationService, NotificationService, StorageService } from "./ports";
import {
	getLocationFoldersTree,
	insertInLocationFoldersTree,
	setLocationFoldersTree,
} from "../domain/locationsFoldersTree";
import useNotifier from "../services/notificationAdapter";
import { LocationFolderNames } from "../domain/locationFolderNames";
import { AppMessage } from "../domain/appMessages";
import { formatError, getErrorMessage } from "./utils/errorFormater";

type Deps = {
	storage: StorageService;
	locationsAdapter: LocationService;
	url: string;
	notifier: NotificationService;
	formatError: typeof formatError;
	getErrorMessage: typeof getErrorMessage;
};

const defaultDeps: Deps = {
	storage,
	locationsAdapter: useLocations(),
	url: foldersUrl,
	notifier: useNotifier(),
	formatError,
	getErrorMessage,
};

/**
 * Get folders for selected location.
 *
 * @returns Array of folders (paths).
 */
async function getLocationFolders(
	deps: Deps = defaultDeps,
): Promise<LocationFolderNames> {
	const {
		storage,
		locationsAdapter,
		url,
		notifier,
		formatError,
		getErrorMessage,
	} = deps;

	const selectedUploadLocation = storage.getSelectedUploadLocation();

	if (!selectedUploadLocation) {
		notifier.notify("error", AppMessage.NoSelectedLocation);
		throw new Error(AppMessage.NoSelectedLocation);
	}

	const selectedLocationId = selectedUploadLocation.id;
	const selectedFolder = storage.getSelectedLocationFolder();

	let folderPath = "";

	storage.setIsLocationFoldersLoading(true);

	if (selectedFolder && selectedFolder.path) {
		folderPath = path.normalize(selectedFolder.path + "/");
	}

	let paths: LocationFolderNames;

	try {
		paths = await locationsAdapter.getCurrentLocationFolders(
			url,
			selectedLocationId,
			folderPath,
		);
	} catch (error) {
		notifier.notify(
			"error",
			formatError(AppMessage.CantGettingLocationFolders, error),
		);
		storage.setIsLocationFoldersLoading(false);
		throw new Error(getErrorMessage(error));
	}

	const locationsFoldersTree = storage.getLocationsFoldersTree();
	const locationFoldersTree = getLocationFoldersTree(
		locationsFoldersTree,
		selectedUploadLocation,
	);

	if (!locationFoldersTree) {
		notifier.notify("error", AppMessage.NoFoldersTree);
		throw new Error(AppMessage.NoFoldersTree);
	}

	/** Root update */
	if (!selectedFolder) {
		setLocationFoldersTree(
			locationsFoldersTree,
			selectedUploadLocation,
			[],
		);
	}

	/**
	 * If an empty array is received when loading folders,
	 * then there are no children
	 */
	if (paths.length) {
		const newLocationsFoldersTree = insertInLocationFoldersTree(
			locationsFoldersTree,
			locationFoldersTree,
			paths,
		);

		storage.setLocationsFoldersTree(newLocationsFoldersTree);
	}

	setLocationFoldersTree(
		locationsFoldersTree,
		selectedUploadLocation,
		locationFoldersTree,
	);

	storage.setIsLocationFoldersLoading(false);

	return paths;
}

export default getLocationFolders;
