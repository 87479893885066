import useMimes from "../../../services/mimesAdapter";
import { MimeService } from "../../ports";

type Deps = {
	mimeService: MimeService;
};

const defaultDeps: Deps = {
	mimeService: useMimes(),
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function getExt(type: string, name: string, deps: Deps = defaultDeps): string {
	const splitedName = name.split(".");
	return splitedName[splitedName.length - 1];
}

export default getExt;
