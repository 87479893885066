import { AppMessage } from "../../domain/appMessages";
import { chunkSize } from "../../domain/defaultValues";
import { PanelKind } from "../../domain/panel";
import useNotifier from "../../services/notificationAdapter";
import storage from "../../services/storageAdapter";
import controlPanel from "../controlPanel";
import { getUploadsPath } from "./upload/getUploadsPath";
import { StorageService, NotificationService } from "../ports";
import { getUuid } from "../utils/uuid";
import uploadQueue from "./upload/uploadQueue";
import { CachedItemName } from "../../domain/cache";
import { getErrorMessage } from "../utils/errorFormater";
import { getUploadingInfoEta } from "../../domain/fileInfo";

type Deps = {
	storage: StorageService;
	notifier: NotificationService;
	getUploadingInfoEta: typeof getUploadingInfoEta;
	uploadQueue: typeof uploadQueue;
	getErrorMessage: typeof getErrorMessage;
	chunkSize: number;
};

const defaultDeps: Deps = {
	storage,
	notifier: useNotifier(),
	getUploadingInfoEta,
	uploadQueue,
	getErrorMessage,
	chunkSize,
};

/**
 * Upload files to the selected path.
 * There are two types of uploads: recent and not.
 * If it is a recent upload, the upload path is taken from the CachedItemName.
 * After upload - uploading settings are cached (location, generation folders)
 *
 * @param isRecent If user click on "Recent upload" button
 * @param deps
 */

async function upload(
	isRecent?: boolean,
	deps: Deps = defaultDeps,
): Promise<void> {
	const {
		storage,
		notifier,
		getUploadingInfoEta,
		uploadQueue,
		getErrorMessage,
		chunkSize,
	} = deps;

	try {
		let selectedLocation = storage.getSelectedUploadLocation();
		const recentLocation = storage.getRecentLocation();
		const selectedFolder = storage.getSelectedLocationFolder();

		if (!selectedLocation && recentLocation) {
			storage.setSelectedUploadLocation(recentLocation);
			selectedLocation = storage.getSelectedUploadLocation();
		}
		if (!selectedLocation) {
			throw new Error(AppMessage.NoSelectedLocation);
		}
		if (!selectedLocation.id) {
			throw new Error(AppMessage.NoSelectedLocationId);
		}
		if (!selectedLocation.title) {
			throw new Error(AppMessage.NoSelectedLocationTitle);
		}

		if (selectedFolder?.path) {
			/** Upload to location folder */
			const recentPath = `${selectedLocation.title}/${selectedFolder.path}`;

			storage.setUploadFolder(selectedFolder.path);
			storage.setRecentUploadFolder(selectedFolder.path);
			storage.setRecentUploadPath(recentPath);

			localStorage.setItem(
				CachedItemName.RecentFolder,
				selectedFolder.path,
			);
			localStorage.setItem(CachedItemName.RecentUploadPath, recentPath);
		} else if (isRecent) {
			/** Upload to recent */

			const recentFolder = localStorage.getItem(
				CachedItemName.RecentFolder,
			);

			if (recentFolder) {
				storage.setUploadFolder(recentFolder);
			}
		} else {
			/** Upload to root of location */

			storage.resetUploadFolder();
			storage.resetRecentUploadFolder();
			storage.setRecentUploadPath(selectedLocation.title);

			localStorage.removeItem(CachedItemName.RecentFolder);
			localStorage.setItem(
				CachedItemName.RecentUploadPath,
				selectedLocation.title,
			);
		}

		localStorage.setItem(
			CachedItemName.RecentLocationTitle,
			selectedLocation.title,
		);
		localStorage.setItem(
			CachedItemName.RecentLocationId,
			selectedLocation.id,
		);

		const isNeedToUseNamingStandard =
			storage.getIsNeedToUseNamingStandard();

		/** Only CachedItemName the setting if it is not a recent upload */
		if (!isRecent) {
			localStorage.setItem(
				CachedItemName.RecentSubfolderNamingStandardRule,
				`${isNeedToUseNamingStandard}`,
			);
		}

		const id = new Date().getTime();
		const recentSubfolderNamingStandardRule =
			storage.getRecentSubfolderNamingStandardRule();

		storage.setRecentLocation(selectedLocation);

		const isNeedToCreateSubfolderByConvention =
			isRecent && recentSubfolderNamingStandardRule
				? recentSubfolderNamingStandardRule
				: isNeedToUseNamingStandard;

		storage.setRecentSubfolderNamingStandardRule(
			isNeedToCreateSubfolderByConvention,
		);

		if (storage.getOpenedPanel() !== PanelKind.Queue) {
			controlPanel(PanelKind.Queue);
		}

		const user = storage.getUser();
		if (!user) {
			throw new Error(AppMessage.NoUser);
		}

		const filesInfo = storage.getFilesInfo();
		if (!filesInfo) {
			notifier.notify("error", AppMessage.NoFilesInfo);
			throw new Error(AppMessage.NoFilesInfo);
		}

		const dirtyQueue = storage.getDirtyQueue();
		if (!dirtyQueue) {
			notifier.notify("error", AppMessage.NoDirtyQueue);
			throw new Error(AppMessage.NoDirtyQueue);
		}

		const queue = storage.queue.getQueue() ?? [];

		for (let i = 0; i < dirtyQueue.length; i++) {
			const element = dirtyQueue[i];
			const currentUploads = storage.getCurrentUploads() ?? [];

			const numberOfChunks = Math.ceil(element.file.size / chunkSize);

			const path = getUploadsPath(
				element,
				storage.getUploadFolder() ?? "",
				isNeedToCreateSubfolderByConvention,
			);
			const leaseId = getUuid();

			if (currentUploads.includes(path)) {
				notifier.notify("warn", AppMessage.FileUploading);
			} else {
				queue.push({
					id: element.id,
					key: element.key,
					file: element.file,
					name: element.name,
					type: element.type,
					ext: element.ext,
					thmbnl: element.thmbnl,
					size: element.file.size.toString(),
					lastModified: element.lastModified,
					rawLastModified: element.rawLastModified,
					umid: element.umid,
					cameraModelName: element.cameraModelName,
					cameraSerialNumber: element.cameraSerialNumber,
					description: element.description,
					isFileCreated: false,
					numberOfChunks,
					uploadedChunks: 0,
					commonProgress: 0,
					uploadId: id.toString(),
					isWaiting: true,
					path,
					location: selectedLocation,
					leaseId,
					chunksMap: null,
					eta: getUploadingInfoEta(Infinity),
					startTime: null,
					uploadAttempts: null,
					chunkStart: 0,
					chunkEnd: null,
					fileChunkSize: chunkSize,
					retryTimer: null,
					blobEnd: null,
					controller: null,
					isUploadComplete: false,
					isCameraCardFile: element.isCameraCardFile,
					storyId: element.storyId,
					storyTitle: element.storyTitle,
					sourcePath: "",
					badChunk: null,
					error: null,
					isFinalCallOk: false,
					cancelledReason: null,
					retryAttempt: 1,
				});
			}
		}

		storage.queue.setQueue(queue);

		if (isRecent) {
			storage.resetSelectedUploadLocation();
			storage.resetSelectedLocationFolder();
			storage.resetLocationsFolderTree();
		}

		await uploadQueue();
	} catch (error) {
		notifier.notify("error", getErrorMessage(error));
	}
}

export default upload;
