import { TimeFormat } from "../../domain/timeFormat";
import useTimeConversionService from "../../services/timeConversionAdapter";
import { TimeConversionService } from "../ports";

type Deps = {
	timeConverter: TimeConversionService;
};

const defaultDeps: Deps = {
	timeConverter: useTimeConversionService(),
};

function convertTime(
	time: number,
	format?: TimeFormat,
	deps: Deps = defaultDeps,
): string {
	const { timeConverter } = deps;
	return timeConverter.convert(time, format);
}

export default convertTime;
