import { MessageBar, MessageBarType } from "@fluentui/react";
import React from "react";
import ReactDOM from "react-dom";

const notifyItemClassName = "message-item";
const messagesContainerClassName = "messages-container";

function deleteNode(id: string, messagesContainer: HTMLElement): void {
	const node = document.getElementById(id);

	if (node) {
		ReactDOM.unmountComponentAtNode(node);
		messagesContainer.removeChild(node);
	}
}

/**
 * Show message bar
 *
 * @param notifyType
 * @param message
 * @param time Time is part of the ID (needed to delete or search for a message in the DOM)
 * @param autoHideTimerMs Time in ms. Default - 0. Pass 0 for disable auto hide
 * @param position
 */
export function notify(
	notifyType: "error" | "info" | "warn" | "success",
	message: string,
	time: number = new Date().getTime(),
	autoHideTimerMs = 0,
	position: "top" | "bottom" = "bottom",
): void {
	let messagesContainer: HTMLElement;

	const existingMessagesContainer = document.getElementById(
		messagesContainerClassName,
	);

	if (existingMessagesContainer) {
		messagesContainer = existingMessagesContainer;
	} else {
		messagesContainer = document.createElement("div");
		messagesContainer.setAttribute("id", messagesContainerClassName);
		messagesContainer.style.position = "absolute";
		messagesContainer.style.zIndex = "9";
		messagesContainer.style.width = "100%";

		if (position == "top") {
			messagesContainer.style.top = "0";
		} else {
			messagesContainer.style.bottom = "0";
		}
		document.body.append(messagesContainer);
	}

	const id = `${notifyItemClassName}-${time}`;
	const messageWrap = document.createElement("span");
	messageWrap.setAttribute("id", id);

	messagesContainer.append(messageWrap);

	let type: MessageBarType;

	switch (notifyType) {
		case "error":
			type = MessageBarType.error;
			break;

		case "success":
			type = MessageBarType.success;
			break;

		case "warn":
			type = MessageBarType.warning;
			break;

		default:
			type = MessageBarType.info;
			break;
	}

	if (autoHideTimerMs !== 0) {
		setTimeout(() => deleteNode(id, messagesContainer), autoHideTimerMs);
	}

	ReactDOM.render(
		React.createElement(
			MessageBar,
			{
				messageBarType: type,
				isMultiline: false,
				onDismiss: (): void => deleteNode(id, messagesContainer),
				dismissButtonAriaLabel: "Close",
			},
			message,
		),
		messageWrap,
	);
}

/**
 * Find and return message by time.
 *
 * @param time
 * @returns True or false
 */
export function findNotifyItem(time: number): boolean {
	return !!document.getElementById(`${notifyItemClassName}-${time}`);
}

export function deleteNotifyItem(time: number): void {
	const id = `${notifyItemClassName}-${time}`;

	const container = document.getElementById(messagesContainerClassName);

	if (container) {
		deleteNode(id, container);
	}
}
