import { makeAutoObservable } from "mobx";
import { QueueStorage } from "../../../application/ports";
import { AppMessage } from "../../../domain/appMessages";
import { UploadFileInfo } from "../../../domain/fileInfo";
import { UploadLocation } from "../../../domain/uploadLocation";

class Queue implements QueueStorage {
	private queue: UploadFileInfo[] | null = null;

	constructor() {
		makeAutoObservable(this);
	}

	findElementById = (id: string): UploadFileInfo => {
		if (!this.queue) {
			throw new Error(AppMessage.NoQueue);
		}

		const element = this.queue.find(i => i.leaseId == id);

		if (!element) {
			throw new Error(AppMessage.NoFileInfo);
		}

		return element;
	};

	getQueue = (): UploadFileInfo[] | null => this.queue;

	setQueue = (newQueue: UploadFileInfo[]): void => {
		this.queue = newQueue;
	};

	resetQueue = (): void => {
		this.queue = null;
	};

	setKey = (id: string, key: string): void => {
		const element = this.findElementById(id);
		element.key = key;
	};

	setFile = (id: string, file: File): void => {
		const element = this.findElementById(id);
		element.file = file;
	};

	setName = (id: string, name: string): void => {
		const element = this.findElementById(id);
		element.name = name;
	};

	// setStoryId = (id: string, storyId: string): void => {
	// 	const element = this.findElementById(id);
	// 	element.story_id = storyId;
	// };

	// setStoryTitle = (id: string, storyTitle: string): void => {
	// 	const element = this.findElementById(id);
	// 	element.story_title = storyTitle;
	// };

	setType = (id: string, type: string): void => {
		const element = this.findElementById(id);
		element.type = type;
	};

	setExt = (id: string, ext: string): void => {
		const element = this.findElementById(id);
		element.ext = ext;
	};

	setThmbnl = (id: string, thmbnl: string): void => {
		const element = this.findElementById(id);
		element.thmbnl = thmbnl;
	};

	setSize = (id: string, size: string): void => {
		const element = this.findElementById(id);
		element.size = size;
	};

	setLastModified = (id: string, lastModified: string): void => {
		const element = this.findElementById(id);
		element.lastModified = lastModified;
	};

	setRawLastModified = (
		id: string,
		rawLastModified: string | number,
	): void => {
		const element = this.findElementById(id);
		element.rawLastModified = rawLastModified;
	};

	setUmid = (id: string, umid: string): void => {
		const element = this.findElementById(id);
		element.umid = umid;
	};

	setDescription = (id: string, description: string): void => {
		const element = this.findElementById(id);
		element.description = description;
	};

	setIsFileCreated = (id: string, isFileCreated: boolean): void => {
		const element = this.findElementById(id);
		element.isFileCreated = isFileCreated;
	};

	setNumberOfChunks = (id: string, numberOfChunks: number): void => {
		const element = this.findElementById(id);
		element.numberOfChunks = numberOfChunks;
	};

	setUploadedChunks = (id: string, uploadedChunks: number): void => {
		const element = this.findElementById(id);
		element.uploadedChunks = uploadedChunks;
	};

	setCommonProgress = (id: string, commonProgress: number): void => {
		const element = this.findElementById(id);
		element.commonProgress = commonProgress;
	};

	setUploadId = (id: string, uploadId: string): void => {
		const element = this.findElementById(id);
		element.uploadId = uploadId;
	};

	setIsWaiting = (id: string, isWaiting: boolean): void => {
		const element = this.findElementById(id);
		element.isWaiting = isWaiting;
	};

	setPath = (id: string, newPath: string): void => {
		const element = this.findElementById(id);
		element.path = newPath;
	};

	setLocation = (id: string, location: UploadLocation): void => {
		const element = this.findElementById(id);
		element.location = location;
	};

	setLeaseId = (id: string, leaseId: string): void => {
		const element = this.findElementById(id);
		element.leaseId = leaseId;
	};

	// setFormatedStory = (id: string, formatedStory: string): void => {
	// 	const element = this.findElementById(id);
	// 	element.formatedStory = formatedStory;
	// };

	// setIsCameraCardFile = (id: string, isCameraCardFile: boolean): void => {
	// 	const element = this.findElementById(id);
	// 	element.isCameraCardFile = isCameraCardFile;
	// };

	setChunksMap = (id: string, chunksMap: string[] | null): void => {
		const element = this.findElementById(id);
		element.chunksMap = chunksMap;
	};

	setEta = (id: string, eta: string | null): void => {
		const element = this.findElementById(id);
		element.eta = eta;
	};

	setStartTime = (id: string, startTime: number | null): void => {
		const element = this.findElementById(id);
		element.startTime = startTime;
	};

	setUploadAttempts = (id: string, uploadAttempts: number | null): void => {
		const element = this.findElementById(id);
		element.uploadAttempts = uploadAttempts;
	};

	setChunkStart = (id: string, chunkStart: number): void => {
		const element = this.findElementById(id);
		element.chunkStart = chunkStart;
	};

	setChunkEnd = (id: string, chunkEnd: number | null): void => {
		const element = this.findElementById(id);
		element.chunkEnd = chunkEnd;
	};

	setFileChunkSize = (id: string, fileChunkSize: number): void => {
		const element = this.findElementById(id);
		element.fileChunkSize = fileChunkSize;
	};

	setRetryTimer = (id: string, retryTimer: number | null): void => {
		const element = this.findElementById(id);
		element.retryTimer = retryTimer;
	};

	setBlobEnd = (id: string, blobEnd: number | null): void => {
		const element = this.findElementById(id);
		element.blobEnd = blobEnd;
	};

	setController = (id: string, controller: AbortController | null): void => {
		const element = this.findElementById(id);
		element.controller = controller;
	};

	setIsUploadComplete = (id: string, isUploadComplete: boolean): void => {
		const element = this.findElementById(id);
		element.isUploadComplete = isUploadComplete;
	};

	setError = (id: string, error: string): void => {
		const element = this.findElementById(id);
		element.error = error;
	};

	resetError = (id: string): void => {
		const element = this.findElementById(id);
		element.error = null;
	};

	setIsFinalCallOk = (id: string, status: boolean): void => {
		const element = this.findElementById(id);
		element.isFinalCallOk = status;
	};

	setBadChunk = (id: string, value: number | null): void => {
		const element = this.findElementById(id);
		element.badChunk = value;
	};

	setCancelledReason = (id: string, reason: string | null): void => {
		const element = this.findElementById(id);
		element.cancelledReason = reason;
	};

	setRetryAttempt = (id: string, atempt: number): void => {
		const element = this.findElementById(id);
		element.retryAttempt = atempt;
	};
}

export default Queue;
