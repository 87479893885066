import { CommandButton, IContextualMenuItem } from "@fluentui/react";
import { useStore } from "effector-react";
import React, { ChangeEvent, useRef } from "react";
import importFiles from "../../application/importFiles/importFiles";
import TypeOfImport from "../../domain/typeOfImport";
import storage from "../../services/storageAdapter";

function onSelectFiles(e: ChangeEvent, typeOfImport: TypeOfImport): void {
	const files = (e.target as HTMLInputElement).files;

	if (files) {
		importFiles(files, typeOfImport);
	}

	// trick for close submenu
	document.getElementById("root")?.click();
}

function getSelectMenu(): IContextualMenuItem[] {
	const features = useStore(storage.features);

	const labelFilesRef = useRef<HTMLLabelElement>(null);
	const labelFolderRef = useRef<HTMLLabelElement>(null);
	const labelCameraCardFolderRef = useRef<HTMLLabelElement>(null);

	const baseSubMenu = [
		{
			key: "selectFiles",
			onRender: (): JSX.Element => (
				<CommandButton
					className="actions__select-button"
					text="Select files"
					onClick={(): void => labelFilesRef.current?.click()}
				>
					<label
						className="actions__select-label"
						htmlFor="select-file"
						ref={labelFilesRef}
					>
						<input
							onChange={(e: ChangeEvent): void => {
								onSelectFiles(e, TypeOfImport.SelectFile);
							}}
							className="actions__select-input"
							type="file"
							name="select-file"
							id="select-file"
							multiple
						/>
					</label>
				</CommandButton>
			),
		},
		{
			key: "selectFolder",
			onRender: (): JSX.Element => (
				<CommandButton
					className="actions__select-button"
					text="Select folder"
					onClick={(): void => labelFolderRef.current?.click()}
				>
					<label
						className="actions__select-label"
						htmlFor="select-folder"
						ref={labelFolderRef}
					>
						<input
							onChange={(e: ChangeEvent): void => {
								onSelectFiles(e, TypeOfImport.SelectFolder);
							}}
							className="actions__select-input"
							type="file"
							name="select-folder"
							id="select-folder"
							webkitdirectory=""
							mozdirectory=""
							directory=""
							nwdirectory=""
						/>
					</label>
				</CommandButton>
			),
		},
	];

	if (features.cameraCardParsingFeature) {
		baseSubMenu.push({
			key: "selectCameraCardFolder",
			onRender: (): JSX.Element => (
				<CommandButton
					className="actions__select-button"
					text="Select camera card folder"
					onClick={(): void =>
						labelCameraCardFolderRef.current?.click()
					}
				>
					<label
						className="actions__select-label"
						htmlFor="select-camera-folder"
						ref={labelCameraCardFolderRef}
					>
						<input
							onChange={(e: ChangeEvent): void => {
								onSelectFiles(e, TypeOfImport.SelectCameraCard);
							}}
							className="actions__select-input"
							type="file"
							name="select-camera-folder"
							id="select-camera-folder"
							webkitdirectory=""
							mozdirectory=""
							directory=""
							nwdirectory=""
						/>
					</label>
				</CommandButton>
			),
		});
	}

	return baseSubMenu;
}

export default getSelectMenu;
