import React, { useEffect, useState } from "react";
import { CommandBar, ICommandBarItemProps } from "@fluentui/react";
import { useStore } from "effector-react";
import storage from "../../../services/storageAdapter";
import { UploaderType } from "../../../domain/uploaderType";
import { PanelKind } from "../../../domain/panel";
import controlPanel from "../../../application/controlPanel";
import "./Actions.scss";
import { observer } from "mobx-react";
import clearData from "../../../application/clearData";
import getSelectMenu from "../../shared/getSelectMenu";
import selectionStore from "../../shared/selectionStore";
import removeFiles from "../../../application/removeFiles";
import { FileInfo } from "../../../domain/fileInfo";
import { useStatemanjs } from "@persevie/statemanjs-react";
import { userState } from "../../../services/storage/userStorageService";
import { isShowIgnoredFilesModalState } from "../../shared/state";

/**
 * To support these attributes.
 */
declare module "react" {
	interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
		webkitdirectory?: "";
		mozdirectory?: "";
		directory?: "";
		nwdirectory?: "";
	}
}
const queueInstance = storage.queue;
const { selectAll, deselectAll, getSelection, resetSelection } = selectionStore;

const Actions = observer((): JSX.Element => {
	const user = useStatemanjs(userState);
	const uploaderType = useStore(storage.uploaderType);
	const isClassic = uploaderType == UploaderType.Normal;
	const isEmbedded = uploaderType == UploaderType.Embedded;
	const dirtyQueue = useStore(storage.dirtyQueue);
	const queue = queueInstance.getQueue();
	const filesInfo = useStore(storage.filesInfo);
	const uploadLocations = useStore(storage.locations);
	const ignoredFiles = useStore(storage.ignoredFilesPaths);

	const [baseItems, setBaseItems] = useState<ICommandBarItemProps[]>([]);

	const selectedCount = getSelection().getSelectedCount();

	const showMetadataPanel = (): void => {
		controlPanel(PanelKind.Metadata);
	};

	const showUploadPanel = (): void => {
		controlPanel(PanelKind.Upload);
	};

	const showQueuePanel = (): void => {
		controlPanel(PanelKind.Queue);
	};

	const toggleIgnoredFilesModal = (): void => {
		isShowIgnoredFilesModalState.set(!isShowIgnoredFilesModalState.get());
	};

	const classicUploadBtnDisabled =
		!dirtyQueue ||
		!dirtyQueue.length ||
		!user ||
		!uploadLocations ||
		!uploadLocations.length;

	const builtInUploadBtnDisabled = !dirtyQueue || !dirtyQueue.length || !user;

	const isUploadBtnDisabled = isClassic
		? classicUploadBtnDisabled
		: builtInUploadBtnDisabled;

	const removeSelected = (): void => {
		const selectedItems = getSelection().getSelection();
		resetSelection();
		removeFiles(selectedItems as FileInfo[]);
	};

	const getAllItems = (): ICommandBarItemProps[] => {
		const items = baseItems.filter(i => i.key !== "selectAll");
		items.push(
			...[
				{
					key: "addMetadata",
					text: "Metadata",
					cacheKey: "addMetadata",
					iconProps: { iconName: "AddNotes" },
					disabled: !dirtyQueue || !dirtyQueue.length || !user,
					onClick: showMetadataPanel,
				},
				{
					key: "upload",
					iconProps: { iconName: "CloudUpload" },
					onClick: showUploadPanel,
					className: "actions__button",
					disabled: isUploadBtnDisabled,
					text: "Upload",
				},
				{
					key: "deselectAll",
					text: "Deselect all",
					cacheKey: "deselectAll",
					iconProps: { iconName: "Clear" },
					disabled: selectedCount < 0 || !user,
					onClick: deselectAll,
				},
				{
					key: "removeSelected",
					text: "Remove selected",
					cacheKey: "removeSelected",
					iconProps: { iconName: "PageRemove" },
					disabled: selectedCount < 0 || !user,
					onClick: removeSelected,
				},
			],
		);

		return items;
	};

	const selectMenu = getSelectMenu();

	const clearListOfFiles = (): void => {
		resetSelection();
		clearData();
	};

	useEffect(() => {
		const items: ICommandBarItemProps[] = [];

		if (!filesInfo || (filesInfo && !filesInfo.length)) {
			items.push({
				key: "selectData",
				text: "Add files",
				cacheKey: "selectData",
				iconProps: { iconName: "OpenFile" },
				disabled: !user,
				subMenuProps: {
					items: selectMenu,
				},
			});
		} else {
			items.push(
				{
					key: "clearData",
					text: "Clear list of files",
					cacheKey: "clearData",
					iconProps: { iconName: "Delete" },
					onClick: clearListOfFiles,
				},
				{
					key: "selectAll",
					text: "Select all",
					cacheKey: "selectAll",
					iconProps: { iconName: "MultiSelect" },
					onClick: selectAll,
				},
			);
		}

		if (isEmbedded && queue) {
			items.push({
				key: "uploadQueuePanel",
				text: "Queue",
				ariaLabel: "Upload Queue Panel",
				iconProps: { iconName: "BuildQueue" },
				onClick: showQueuePanel,
			});
		}

		if (ignoredFiles.length) {
			items.push({
				key: "invalidFilePaths",
				text: "Show ignored files",
				ariaLabel: "Toggle ignored files modal",
				iconProps: { iconName: "Hide3" },
				onClick: toggleIgnoredFilesModal,
			});
		}

		setBaseItems(items);
	}, [filesInfo, ignoredFiles]);

	return (
		<div className="actions-wrap">
			<CommandBar
				items={selectedCount > 0 ? getAllItems() : baseItems}
				ariaLabel="Actions Bar"
				className="actions"
			/>
		</div>
	);
});

export default Actions;
