import { UploadFileInfo } from "../domain/fileInfo";
import storage from "../services/storageAdapter";
import { StorageService } from "./ports";
import getQueueOrThrowError from "./utils/getQueueOrThrowError";

type Deps = {
	storage: StorageService;
	getQueueOrThrowError: typeof getQueueOrThrowError;
};

const defaultDeps: Deps = {
	storage,
	getQueueOrThrowError,
};

/**
 * Remove only items with "isUploadComplete" status.
 * In-progress and error items will remain.
 *
 * NOTE: the canceled item also has the status "isUploadComplete".
 *
 * @returns new queue
 */
function clearCompletedUploads(deps: Deps = defaultDeps): UploadFileInfo[] {
	const { storage, getQueueOrThrowError } = deps;

	const queue = getQueueOrThrowError();
	const newQueue = queue.filter(i => !i.isUploadComplete);

	storage.queue.setQueue(newQueue);
	storage.setIsCompletedUploadExist(false);

	return getQueueOrThrowError();
}

export default clearCompletedUploads;
