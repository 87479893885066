import { CachedData } from "../domain/cache";
import { CacheService } from "./ports";
import cacheService from "../services/cacheAdapter";

type Deps = {
	cacheService: CacheService;
};

const defaultDeps: Deps = {
	cacheService: cacheService,
};

function getCache(deps: Deps = defaultDeps): CachedData {
	const { cacheService } = deps;
	return cacheService.getCache();
}

export default getCache;
