import { FileInfo } from "../domain/fileInfo";
import storage from "../services/storageAdapter";
import { StorageService } from "./ports";

type Deps = {
	storage: StorageService;
};

const defaultDeps: Deps = {
	storage,
};

function updateFilesInfo(
	filesInfo: FileInfo[],
	deps: Deps = defaultDeps,
): FileInfo[] | null {
	const { storage } = deps;

	storage.setFilesInfo(filesInfo);

	return storage.getFilesInfo();
}

export default updateFilesInfo;
