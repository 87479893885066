import storage from "../services/storageAdapter";
import { StorageService } from "./ports";

type Deps = {
	storage: StorageService;
};

const defaultDeps: Deps = {
	storage,
};

function clearData(deps: Deps = defaultDeps): void {
	const { storage } = deps;

	storage.resetFilesInfo();
	storage.resetDirtyQueue();
}

export default clearData;
