import { StorageService } from "../application/ports";
import authStorageService from "./storage/authStorageService";
import cacheStorageService from "./storage/cacheStorageService";
import commonStorageService from "./storage/commonStorageService";
import dataStorageService from "./storage/dataStorageService";
import uploadStorageService from "./storage/uploadStorageService";
import userStorageService from "./storage/userStorageService";

const storage: StorageService = {
	...commonStorageService,
	...authStorageService,
	...cacheStorageService,
	...dataStorageService,
	...uploadStorageService,
	...userStorageService,
};

export default storage;
