import React, { useEffect } from "react";
import { ActionButton, DefaultButton, Modal, Text } from "@fluentui/react";
import Loader from "../Loader/Loader";
import { useStore } from "effector-react";
import storage from "../../../services/storageAdapter";
import Header from "../Header/Header";
import Actions from "../Actions/Actions";
import { UploaderType } from "../../../domain/uploaderType";
import FileList from "../FileList/FileList";
import getUploadParamsFromAir from "../../../application/getUploadParamsFromAir";
import AddMetadata from "../AddMetadata/AddMetadata";
import Upload from "../Upload/Upload";
import Queue from "../Queue/Queue";
import Dropzone from "../Dropzone/Dropzone";
import switchToExternalType from "../../../application/switchToExternalType";
import applyUploadParamsFromAir from "../../../application/applyUploadParamsFromAir";
import { AirUploadParams } from "../../../domain/airUploadParams";
import { AppInitStatus } from "../../../domain/appInitStatus";
import importFiles from "../../../application/importFiles/importFiles";
import controlPanel from "../../../application/controlPanel";
import { PanelKind } from "../../../domain/panel";
import { observer } from "mobx-react";
import homeLight from "../../assets/homeLight.svg";
import homeDark from "../../assets/homeDark.svg";
import getSelectMenu from "../../shared/getSelectMenu";
import clearData from "../../../application/clearData";
import selectionStore from "../../shared/selectionStore";
import TypeOfImport from "../../../domain/typeOfImport";
import { useStatemanjs } from "@persevie/statemanjs-react";
import { isShowIgnoredFilesModalState } from "../../shared/state";

type IgnoredFilesModalProps = {
	isOpen: boolean;
	ignoredFiles: string[];
};

function IgnoredFilesModal(props: IgnoredFilesModalProps): JSX.Element {
	return (
		<Modal
			titleAriaId="Ignored files"
			isOpen={props.isOpen}
			onDismiss={(): void => {
				isShowIgnoredFilesModalState.set(false);
			}}
			isBlocking={false}
		>
			<div className="ignored-files-container">
				<header>
					<Text as="h3">Ignored files</Text>
				</header>
				<div className="ignored-files">
					{props.ignoredFiles.map(i => (
						<Text key={i}>{i}</Text>
					))}
				</div>
			</div>
		</Modal>
	);
}

type Props = {
	isExternal?: boolean;
};

const queueInstance = storage.queue;
const { resetSelection } = selectionStore;

const ClassicApp = observer((props?: Props): JSX.Element => {
	const appInitStatus = useStore(storage.appInitStatus);
	const selectedLocation = useStore(storage.selectedUploadLocation);
	const isInfoCreating = useStore(storage.isInfoCreating);
	const uploaderType = useStore(storage.uploaderType);
	const selectedFolder = useStore(storage.selectedLocationFolder);
	const dirtyQueue = useStore(storage.dirtyQueue);
	const filesInfo = useStore(storage.filesInfo);
	const openedPanel = useStore(storage.openedPanel);
	const isDarkMode = useStore(storage.isDarkMode);
	const uploadLocations = useStore(storage.locations);
	const ignoredFiles = useStore(storage.ignoredFilesPaths);
	const isShowIgnoredFilesModal = useStatemanjs(isShowIgnoredFilesModalState);

	const queue = queueInstance.getQueue();

	const isShowFileList = filesInfo && filesInfo.length && !isInfoCreating;
	const isShowIntro =
		(!filesInfo || (filesInfo && !filesInfo.length)) && !isInfoCreating;
	const isShowAddMetadata =
		filesInfo && filesInfo.length && dirtyQueue && dirtyQueue.length;

	const items = getSelectMenu();

	function onDragNDropp(files: File[]): void {
		importFiles(files, TypeOfImport.DragNDrop);
	}

	const showUploadPanel = (): void => {
		controlPanel(PanelKind.Upload);
	};

	const clearListOfFiles = (): void => {
		resetSelection();
		clearData();
	};

	useEffect(() => {
		if (props?.isExternal) {
			switchToExternalType();
		}
	}, [props]);

	/**
	 * If upload params were passed from AIR - get them from the query string
	 * and set them in the store.
	 * Can't work if locations have't yet been retrieved from the backend.
	 */
	useEffect(() => {
		if (uploaderType == UploaderType.External && uploadLocations) {
			const params: AirUploadParams | undefined =
				getUploadParamsFromAir();
			if (params) {
				applyUploadParamsFromAir(params.locationId, params.path);
			}
		}
	}, [uploaderType, uploadLocations]);

	useEffect(() => {
		if (
			openedPanel == PanelKind.Metadata &&
			(!dirtyQueue || (dirtyQueue && !dirtyQueue.length))
		) {
			controlPanel(PanelKind.None);
		}
	}, [dirtyQueue]);

	if (appInitStatus == AppInitStatus.InProgress) {
		return <Loader text={appInitStatus} />;
	}

	if (appInitStatus == AppInitStatus.Failed) {
		return (
			<div className="main">
				<div className="app-info">
					<Text variant="large">{appInitStatus}</Text>
					<ActionButton
						iconProps={{ iconName: "FollowUser" }}
						allowDisabledFocus
					>
						Re-sign in
					</ActionButton>
				</div>
			</div>
		);
	}

	return (
		<div className="app-classic">
			<Header />
			<Actions />

			{queue && queue.length ? <Queue /> : <></>}
			{dirtyQueue && dirtyQueue.length ? <Upload /> : <></>}
			{isShowAddMetadata ? <AddMetadata /> : <></>}

			<main className="main">
				{isShowFileList ? <FileList /> : <></>}

				{isShowIntro && (
					<div className="app-info">
						<img
							className="app-info__home-img"
							src={isDarkMode ? homeDark : homeLight}
							alt="home"
						/>

						<Text variant="large">
							Uploader needs something to upload
						</Text>
						{props?.isExternal && selectedLocation && (
							<Text variant="large">
								to{" "}
								<span className="app-info__text-highlight">
									{selectedLocation.title}/
									{selectedFolder?.path}
								</span>
							</Text>
						)}

						<DefaultButton
							primary
							text="Add Files"
							iconProps={{ iconName: "OpenFile" }}
							menuProps={{ items }}
						/>
					</div>
				)}

				{isInfoCreating && <Loader text="Creating info..." />}

				{isShowIgnoredFilesModal && ignoredFiles.length ? (
					<IgnoredFilesModal
						isOpen={isShowIgnoredFilesModal}
						ignoredFiles={ignoredFiles}
					/>
				) : null}
			</main>

			{isShowFileList ? (
				<div className="app-mobile-bar">
					<DefaultButton
						primary
						text="Upload Destination"
						iconProps={{ iconName: "CloudUpload" }}
						className="app-mobile-bar__btn"
						disabled={!dirtyQueue?.length}
						onClick={showUploadPanel}
					/>

					<DefaultButton
						text="Clear"
						className="app-mobile-bar__btn"
						onClick={clearListOfFiles}
					/>
				</div>
			) : (
				<></>
			)}

			<Dropzone inputCB={onDragNDropp} />
		</div>
	);
});

export default ClassicApp;
