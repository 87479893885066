import React from "react";
import {
	DirectionalHint,
	Text,
	Label,
	ProgressIndicator,
	TooltipHost,
	IProgressIndicatorStyleProps,
	IProgressIndicatorStyles,
	IStyleFunctionOrObject,
	Stack,
	ActionButton,
} from "@fluentui/react";
import {
	CloudCheckmark24Regular,
	CloudOff24Regular,
} from "@fluentui/react-icons";
import Panel from "../Panel/Panel";
import { useStore } from "effector-react";
import storage from "../../../services/storageAdapter";
import { PanelKind } from "../../../domain/panel";
import "./Queue.scss";
import { getUploadedSize, UploadFileInfo } from "../../../domain/fileInfo";
import cancelUpload from "../../../application/upload/cancelUpload";
import clearCompletedUploads from "../../../application/clearCompletedUploads";
import { observer } from "mobx-react-lite";

const queueInstance = storage.queue;

const Queue = observer((): JSX.Element => {
	const openedPanel = useStore(storage.openedPanel);
	const isCompletedUploadExist = useStore(storage.isCompletedUploadExist);
	const queue = queueInstance.getQueue();
	const features = useStore(storage.features);

	const getTitle = (info: UploadFileInfo): string => {
		if (info.cancelledReason) {
			return "Cancelled upload";
		}

		if (info.error) {
			return "Error sending the";
		}

		if (info.commonProgress === 1) {
			return "Uploaded";
		} else {
			return "Uploading";
		}
	};

	const getDescription = (info: UploadFileInfo): React.ReactNode => {
		if (info.error) {
			return (
				<div
					style={{
						display: "grid",
						gridAutoFlow: "column",
						gridAutoColumns: "auto max-content",
						justifyContent: "space-between",
						alignItems: "center",
						columnGap: "20px",
					}}
				>
					<Text nowrap styles={{ root: { color: "red" } }}>
						{info.error}
					</Text>

					{info.retryAttempt <= features.retryCount ||
					features.retryCount === -1 ? (
						<Text nowrap styles={{ root: { color: "red" } }}>
							Atempt {info.retryAttempt}, retrying...{" "}
							{info.retryTimer}s
						</Text>
					) : (
						<></>
					)}
				</div>
			);
		}

		if (info.cancelledReason) {
			return <Text nowrap>{info.cancelledReason ?? ""}</Text>;
		}

		if (info.isWaiting) {
			return <Text nowrap>Waiting...</Text>;
		}

		if (info.commonProgress === 1) {
			return <Text nowrap>Completed</Text>;
		} else {
			return (
				<Stack
					horizontal
					horizontalAlign="space-between"
					verticalAlign="center"
				>
					<Text nowrap>{getUploadedSize(info)}</Text>
					<Text nowrap>{info.eta}</Text>
				</Stack>
			);
		}
	};

	const onCancelUploading = (element: UploadFileInfo): void => {
		cancelUpload(element);
	};

	const getProgressColor = (
		info: UploadFileInfo,
	):
		| IStyleFunctionOrObject<
				IProgressIndicatorStyleProps,
				IProgressIndicatorStyles
		  >
		| undefined => {
		if (info.error) {
			return {
				progressBar: {
					backgroundColor: "var(--error)",
				},
			};
		}

		if (info.cancelledReason) {
			return {
				progressBar: {
					backgroundColor: "var(--l-gray)",
				},
			};
		}
	};

	const getStatus = (info: UploadFileInfo): string => {
		if (info.cancelledReason) {
			return info.cancelledReason;
		} else if (info.error) {
			return info.error;
		} else if (info.isWaiting) {
			return "Waiting...";
		} else if (info.commonProgress === 1) {
			return "Completed";
		} else {
			return `Uploaded ${getUploadedSize(info)}`;
		}
	};

	const Title = (): JSX.Element => {
		if (queue) {
			return queue.length ? (
				<Text variant="xLarge">Upload status</Text>
			) : (
				<Text variant="xLarge">Uploading</Text>
			);
		}

		return <></>;
	};

	const onClearCompletedUploads = (): void => {
		clearCompletedUploads();
	};

	return (
		<Panel
			isOpen={openedPanel === PanelKind.Queue}
			title={<Title />}
			controlBar={
				<ActionButton
					iconProps={{
						iconName: "RevToggleKey",
						color: "blue",
					}}
					text="Clear completed"
					onClick={onClearCompletedUploads}
					disabled={!isCompletedUploadExist}
				/>
			}
		>
			<div className="queue">
				<div className="queue__list">
					{queue &&
						queue.map((i, idx) => (
							<div key={i.name + idx}>
								<TooltipHost
									id={i.name + idx}
									directionalHint={
										DirectionalHint.leftTopEdge
									}
									tooltipProps={{
										onRenderContent: (): JSX.Element => (
											<div className="queue__info-wrap">
												<div className="queue__info">
													<Label className="queue__info-label">
														Name
													</Label>

													<div className="queue__info-preview">
														{i.thmbnl &&
															i.isCameraCardFile && (
																<img
																	className="queue__info-thmbnl"
																	src={
																		i.thmbnl
																	}
																	alt="File thumbnail"
																/>
															)}
														<Text>{i.name}</Text>
													</div>
												</div>
												<div className="queue__info">
													<Label className="queue__info-label">
														Upload destination
													</Label>
													<Text
														styles={{
															root: {
																wordBreak:
																	"break-all",
															},
														}}
													>
														{i.path}
													</Text>
												</div>
												{i.storyTitle ? (
													<div className="queue__info">
														<Label className="queue__info-label">
															Story
														</Label>
														<Text>
															{i.storyTitle}
														</Text>
													</div>
												) : (
													<></>
												)}

												<div className="queue__info">
													<Label className="queue__info-label">
														Status
													</Label>
													<Text>{getStatus(i)}</Text>
												</div>
											</div>
										),
									}}
								>
									<div className="queue__card">
										<div className="queue__card-header">
											<div className="queue__card-title">
												<Text
													nowrap
													className="queue__card-title-text"
												>
													{getTitle(i)} file&#8194;
													<span className="queue__text-highlight queue__name">
														{i.name}
													</span>
													&#8194;to&#8194;
													<span className="queue__text-highlight">
														{i.location.title}
													</span>
												</Text>
											</div>
											<div className="queue__card-other">
												{i.commonProgress !== 1 &&
													!i.cancelledReason && (
														<ActionButton
															iconProps={{
																iconName:
																	"Clear",
															}}
															text="Cancel"
															onClick={(): void =>
																onCancelUploading(
																	i,
																)
															}
															styles={{
																root: {
																	padding: 0,
																	height: "24px",
																},
															}}
														/>
													)}

												{!i.error &&
													i.commonProgress !== 1 &&
													i.cancelledReason && (
														<CloudOff24Regular
															style={{
																fill: "gray",
															}}
														/>
													)}

												{i.commonProgress === 1 &&
													!i.error && (
														<CloudCheckmark24Regular
															style={{
																fill: "green",
															}}
														/>
													)}
											</div>
										</div>
										<ProgressIndicator
											aria-describedby={i.name + idx}
											description={getDescription(i)}
											percentComplete={i.commonProgress}
											styles={getProgressColor(i)}
										/>
									</div>
								</TooltipHost>
							</div>
						))}
				</div>
			</div>
		</Panel>
	);
});

export default Queue;
