import React, { useEffect } from "react";
import { initializeIcons, Text } from "@fluentui/react";
import "./App.scss";
import applyCache from "../application/applyCache";
import { AppMode } from ".";
import ClassicApp from "./components/ClassicApp/ClassicApp";
import getfeatures from "../application/getFeatures";
import getLocations from "../application/getLocations";
import getStories from "../application/getStories";
import Loader from "./components/Loader/Loader";
import { AppInitStatus } from "../domain/appInitStatus";
import { createState } from "@persevie/statemanjs";
import { useStatemanjs } from "@persevie/statemanjs-react";
import { AppMessage } from "../domain/appMessages";
import EmbeddedApp from "./components/EmbeddedApp/EmbeddedApp";

initializeIcons();

enum InitStatus {
	None,
	Success,
	Error,
}

const initStatusState = createState<InitStatus>(InitStatus.None);
const initErrorState = createState<string | null>(null);

async function initApp(): Promise<void> {
	try {
		initStatusState.set(InitStatus.None);
		initErrorState.set(null);

		const features = await getfeatures();
		await getLocations();

		if (features.storiesFeature) {
			await getStories();
		}

		initStatusState.set(InitStatus.Success);
	} catch (error) {
		initStatusState.set(InitStatus.Error);
		initErrorState.set((error as Error).message);
	}
}

type AppWrapperProps = {
	appMode: AppMode;
};
function AppWrapper(props: AppWrapperProps): JSX.Element {
	const initStatus = useStatemanjs(initStatusState);
	const initError = useStatemanjs(initErrorState);

	/** Checking cache and set it into store (if cache exist) */
	useEffect(() => {
		applyCache();
	}, []);

	useEffect(() => {
		initApp();
	}, []);

	switch (initStatus) {
		case InitStatus.None:
			return (
				<div id="app-info">
					<Loader text={AppInitStatus.InProgress} />
				</div>
			);

		case InitStatus.Error:
			return (
				<div id="app-info">
					<Text as="h1" className="txt_err">
						{AppMessage.CantInit}
						{initError ? `: ${initError}` : ""}
					</Text>
				</div>
			);
	}

	switch (props.appMode) {
		case AppMode.External:
			return <ClassicApp isExternal={true} />;

		case AppMode.Embedded:
			return <EmbeddedApp />;

		default:
			return <ClassicApp />;
	}
}

export default AppWrapper;
