import { FileInfo } from "../domain/fileInfo";
import storage from "../services/storageAdapter";
import { StorageService } from "./ports";

type Deps = {
	storage: StorageService;
};

const defaultDeps = {
	storage,
};

/**
 * Save queue draft to the storage
 *
 * @param elements
 * @param deps
 * @returns Dirty queue
 */
function setDirtyQueue(
	elements: FileInfo[],
	deps: Deps = defaultDeps,
): FileInfo[] | null {
	const { storage } = deps;

	storage.setDirtyQueue(elements);

	return storage.getDirtyQueue();
}

export default setDirtyQueue;
