import {
	getLocationFoldersTree,
	insertInLocationFoldersTree,
} from "../domain/locationsFoldersTree";
import storage from "../services/storageAdapter";
import useNotifier from "../services/notificationAdapter";
import { NotificationService, StorageService } from "./ports";
import selectFolder from "./selectFolder";
import { AppMessage } from "../domain/appMessages";

type Deps = {
	storage: StorageService;
	selectFolder: typeof selectFolder;
	notifier: NotificationService;
};

const defaultDeps: Deps = {
	storage,
	selectFolder,
	notifier: useNotifier(),
};

/**
 * Find folder in folder tree and select it
 *
 * @param folderName
 * @param deps
 * @returns New folder or null
 */
function findAndSelectFolder(
	folderName: string,
	deps: Deps = defaultDeps,
): void {
	const { storage, selectFolder, notifier } = deps;

	const selectedLocation = storage.getSelectedUploadLocation();

	if (!selectedLocation) {
		notifier.notify("error", AppMessage.NoSelectedLocation);
		throw new Error(AppMessage.NoSelectedLocation);
	}

	const selectedFolder = storage.getSelectedLocationFolder();
	const locationsFoldersTree = storage.getLocationsFoldersTree();
	const locationFoldersTree = getLocationFoldersTree(
		locationsFoldersTree,
		selectedLocation,
	);

	if (!locationFoldersTree) {
		notifier.notify("error", AppMessage.NoFoldersTree);
		throw new Error(AppMessage.NoFoldersTree);
	}

	const folder = selectedFolder
		? selectedFolder.childrens?.find(f => f.name === folderName)
		: locationFoldersTree.find(f => f.name === folderName);

	if (folder) {
		selectFolder(folder);
	} else {
		const newLocationsFoldersTree = insertInLocationFoldersTree(
			locationsFoldersTree,
			locationFoldersTree,
			selectedFolder
				? [`${selectedFolder.path}/${folderName}`]
				: [folderName],
		);

		storage.setLocationsFoldersTree(newLocationsFoldersTree);

		findAndSelectFolder(folderName);
	}
}

export default findAndSelectFolder;
