/**
 * Parses the file name by dots, checks for service files
 * and strips off the extension.
 *
 * @param fullName
 * @returns File name.
 */
function getFileName(fullName: string): string {
	const splitedName = fullName.split(".")[0].length
		? fullName.split(".")
		: fullName.split(".").slice(1);

	if (splitedName.length > 1) {
		return splitedName.slice(0, splitedName.length - 1).join(".");
	} else {
		return splitedName[0];
	}
}

export default getFileName;
