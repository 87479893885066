import { createEvent, createStore } from "effector";
import { UploadStorageService } from "../../application/ports";
import { FileInfo } from "../../domain/fileInfo";
import { LocationFolder } from "../../domain/locationFolder";
import { LocationsFoldersTree } from "../../domain/locationsFoldersTree";
import { LocationTreeBreadCrumb } from "../../domain/locationTreeBreadCrumbs";
import { UploadLocation } from "../../domain/uploadLocation";
import Queue from "./uploadStorageService/queue";

const currentUploads = createStore<string[] | null>(null);
const setCurrentUploads = createEvent<string[]>();
function getCurrentUploads(): string[] | null {
	return currentUploads.getState();
}
const resetCurrentUploads = createEvent();
currentUploads
	.on(setCurrentUploads, (_, uploads) => uploads)
	.reset(resetCurrentUploads);

const selectedUploadLocation = createStore<UploadLocation | null>(null);
const setSelectedUploadLocation = createEvent<UploadLocation>();
function getSelectedUploadLocation(): UploadLocation | null {
	return selectedUploadLocation.getState();
}
const resetSelectedUploadLocation = createEvent();
selectedUploadLocation
	.on(setSelectedUploadLocation, (_, location) => location)
	.reset(resetSelectedUploadLocation);

const airUploadPath = createStore<string[] | null>(null);
const setAirUploadPath = createEvent<string[]>();
function getAirUploadPath(): string[] | null {
	return airUploadPath.getState();
}
const resetAirUploadPath = createEvent();
airUploadPath.on(setAirUploadPath, (_, path) => path).reset(resetAirUploadPath);

const selectedLocationFolder = createStore<LocationFolder | null>(null);
const setSelectedLocationFolder = createEvent<LocationFolder>();
function getSelectedLocationFolder(): LocationFolder | null {
	return selectedLocationFolder.getState();
}
const resetSelectedLocationFolder = createEvent();
selectedLocationFolder
	.on(setSelectedLocationFolder, (_, folder) => folder)
	.reset(resetSelectedLocationFolder);

const locationsFoldersTree = createStore<LocationsFoldersTree>({});
const setLocationsFoldersTree = createEvent<LocationsFoldersTree>();
function getLocationsFoldersTree(): LocationsFoldersTree {
	return locationsFoldersTree.getState();
}
const resetLocationsFolderTree = createEvent();
locationsFoldersTree
	.on(setLocationsFoldersTree, (_, tree) => tree)
	.reset(resetLocationsFolderTree);

const locationTreeBreadCrumbs = createStore<LocationTreeBreadCrumb[] | null>(
	null,
);
const setLocationTreeBreadCrumbs = createEvent<LocationTreeBreadCrumb[]>();
function getLocationTreeBreadCrumbs(): LocationTreeBreadCrumb[] | null {
	return locationTreeBreadCrumbs.getState();
}
const resetLocationTreeBreadCrumbs = createEvent();
locationTreeBreadCrumbs
	.on(setLocationTreeBreadCrumbs, (_, breadCrumbs) => breadCrumbs)
	.reset(resetLocationTreeBreadCrumbs);

const dirtyQueue = createStore<FileInfo[] | null>(null);
const setDirtyQueue = createEvent<FileInfo[]>();
function getDirtyQueue(): FileInfo[] | null {
	return dirtyQueue.getState();
}
const resetDirtyQueue = createEvent();
dirtyQueue.on(setDirtyQueue, (_, elements) => elements).reset(resetDirtyQueue);

const newFolderNameDraft = createStore<string | null>(null);
const setNewFolderNameDraft = createEvent<string>();
function getNewFolderNameDraft(): string | null {
	return newFolderNameDraft.getState();
}
newFolderNameDraft.on(setNewFolderNameDraft, (_, folderName) => folderName);

const isNeedToUseNamingStandard = createStore<boolean>(true);
const setIsNeedToUseNamingStandard = createEvent<boolean>();
function getIsNeedToUseNamingStandard(): boolean {
	return isNeedToUseNamingStandard.getState();
}
isNeedToUseNamingStandard.on(
	setIsNeedToUseNamingStandard,
	(_, status) => status,
);

const uploadFolder = createStore<string | null>(null);
const setUploadFolder = createEvent<string>();
function getUploadFolder(): string | null {
	return uploadFolder.getState();
}
const resetUploadFolder = createEvent();
uploadFolder
	.on(setUploadFolder, (_, folder) => folder)
	.reset(resetUploadFolder);

const queue = new Queue();

const uploadingCount = createStore<number>(0);
const setUploadingCount = createEvent<number>();
function getUploadingCount(): number {
	return uploadingCount.getState();
}
uploadingCount.on(setUploadingCount, (_, count) => count);

const isFolderNameInvalid = createStore<boolean>(false);
const setIsFolderNameInvalid = createEvent<boolean>();
function getIsFolderNameInvalid(): boolean {
	return isFolderNameInvalid.getState();
}
isFolderNameInvalid.on(setIsFolderNameInvalid, (_, status) => status);

const isCompletedUploadExist = createStore<boolean>(false);
const setIsCompletedUploadExist = createEvent<boolean>();
function getIsCompletedUploadExist(): boolean {
	return isCompletedUploadExist.getState();
}
isCompletedUploadExist.on(setIsCompletedUploadExist, (_, status) => status);

const uploadStorageService: UploadStorageService = {
	currentUploads,
	setCurrentUploads,
	getCurrentUploads,
	resetCurrentUploads,

	selectedUploadLocation,
	setSelectedUploadLocation,
	getSelectedUploadLocation,
	resetSelectedUploadLocation,

	airUploadPath,
	setAirUploadPath,
	getAirUploadPath,
	resetAirUploadPath,

	selectedLocationFolder,
	setSelectedLocationFolder,
	getSelectedLocationFolder,
	resetSelectedLocationFolder,

	locationsFoldersTree,
	setLocationsFoldersTree,
	getLocationsFoldersTree,
	resetLocationsFolderTree,

	locationTreeBreadCrumbs,
	setLocationTreeBreadCrumbs,
	getLocationTreeBreadCrumbs,
	resetLocationTreeBreadCrumbs,

	dirtyQueue,
	setDirtyQueue,
	getDirtyQueue,
	resetDirtyQueue,

	newFolderNameDraft,
	setNewFolderNameDraft,
	getNewFolderNameDraft,

	isNeedToUseNamingStandard,
	setIsNeedToUseNamingStandard,
	getIsNeedToUseNamingStandard,

	uploadFolder,
	setUploadFolder,
	getUploadFolder,
	resetUploadFolder,

	queue,

	uploadingCount,
	setUploadingCount,
	getUploadingCount,

	isFolderNameInvalid,
	setIsFolderNameInvalid,
	getIsFolderNameInvalid,

	isCompletedUploadExist,
	setIsCompletedUploadExist,
	getIsCompletedUploadExist,
};

export default uploadStorageService;
