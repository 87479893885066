import useFile from "../../../services/fileAdapter";
import storage from "../../../services/storageAdapter";
import createFile from "./createFile";
import { FileService, StorageService } from "../../ports";
import decreaseUploadingCount from "./decreaseUploadingCount";
import increaseUploadingCount from "./increaseUploadingCount";
import {
	updateIsWaiting,
	updateCommonProgress,
	updateIsFileCreated,
	updateError,
	updateIsFinalCallOk,
	updateBadChunk,
	updateRetryTimer,
	updateErrorToDefault,
	updateIsUploadComplete,
	updateRetryAttempt,
} from "./updateUploadingInfo";
import uploadByChunks from "./uploadByChunks";
import {
	getUploadingInfoProgress,
	UploadFileInfo,
} from "../../../domain/fileInfo";
import { uploadingRetryTimeout } from "../../../domain/defaultValues";
import { getErrorMessage } from "../../utils/errorFormater";
import getQueueOrThrowError from "../../utils/getQueueOrThrowError";
import abort from "../shared/abort";

type Deps = {
	fileService: FileService;
	storage: StorageService;
	increaseUploadingCount: typeof increaseUploadingCount;
	updateIsWaiting: typeof updateIsWaiting;
	createFile: typeof createFile;
	uploadByChunks: typeof uploadByChunks;
	updateCommonProgress: typeof updateCommonProgress;
	getUploadingInfoProgress: typeof getUploadingInfoProgress;
	updateIsFileCreated: typeof updateIsFileCreated;
	updateError: typeof updateError;
	updateErrorToDefault: typeof updateErrorToDefault;
	updateIsFinalCallOk: typeof updateIsFinalCallOk;
	updateBadChunk: typeof updateBadChunk;
	updateRetryTimer: typeof updateRetryTimer;
	updateIsUploadComplete: typeof updateIsUploadComplete;
	decreaseUploadingCount: typeof decreaseUploadingCount;
	getQueueOrThrowError: typeof getQueueOrThrowError;
	uploadingRetryTimeout: number;
};

const defaultDeps: Deps = {
	fileService: useFile(),
	storage,
	increaseUploadingCount,
	updateIsWaiting,
	createFile,
	uploadByChunks,
	updateCommonProgress,
	getUploadingInfoProgress,
	updateIsFileCreated,
	updateError,
	updateErrorToDefault,
	updateIsFinalCallOk,
	updateBadChunk,
	updateRetryTimer,
	updateIsUploadComplete,
	decreaseUploadingCount,
	getQueueOrThrowError,
	uploadingRetryTimeout,
};

async function uploadQueue(deps: Deps = defaultDeps): Promise<void> {
	const {
		fileService,
		storage,
		increaseUploadingCount,
		updateIsWaiting,
		createFile,
		uploadByChunks,
		updateCommonProgress,
		getUploadingInfoProgress,
		updateError,
		updateErrorToDefault,
		updateIsFinalCallOk,
		updateRetryTimer,
		updateIsUploadComplete,
		decreaseUploadingCount,
		getQueueOrThrowError,
		uploadingRetryTimeout,
	} = deps;

	const queue = getQueueOrThrowError();

	const tryToUpload = async (item: UploadFileInfo): Promise<void> => {
		try {
			if (!item.isFileCreated) {
				await createFile(item);
			}

			if (
				item.isFileCreated &&
				(item.uploadedChunks === 0 || item.badChunk !== null)
			) {
				await uploadByChunks(item);
			}

			if (
				!item.isFinalCallOk &&
				item.isFileCreated &&
				item.numberOfChunks === item.uploadedChunks
			) {
				await fileService.complete(item);
			}

			updateIsFinalCallOk(item.leaseId, true);
			updateCommonProgress(item.leaseId, getUploadingInfoProgress(item));

			decreaseUploadingCount();

			const currentUploads = storage.getCurrentUploads() ?? [];
			const newCurrentUploads = currentUploads.filter(
				u => u !== item.path,
			);
			storage.setCurrentUploads([...newCurrentUploads]);
			updateIsUploadComplete(item.leaseId, true);
			storage.setIsCompletedUploadExist(true);
		} catch (error) {
			if (!item.cancelledReason) {
				let errorDescription = "";

				if (!item.isFileCreated) {
					errorDescription = "during file creation";
				} else if (
					item.uploadedChunks > 0 &&
					item.uploadedChunks !== item.numberOfChunks
				) {
					errorDescription = `during file upload; chunk №${item.badChunk}`;
				} else if (
					!item.isFinalCallOk &&
					item.uploadedChunks === item.numberOfChunks
				) {
					errorDescription = "during completion of uploading";
				}

				updateError(
					item.leaseId,
					`${getErrorMessage(error)} (${errorDescription})`,
				);

				const maxRetryCount = storage.getFeatures().retryCount;

				if (
					item.retryAttempt <= maxRetryCount ||
					maxRetryCount === -1
				) {
					// Local timer
					let timer = 0;
					// Init retry timer
					updateRetryTimer(item.leaseId, uploadingRetryTimeout);

					const retry = setInterval(() => {
						if (item.cancelledReason) {
							clearInterval(retry);
						} else if (timer == uploadingRetryTimeout) {
							clearInterval(retry);

							if (
								item.retryAttempt <= maxRetryCount ||
								maxRetryCount === -1
							) {
								updateErrorToDefault(item.leaseId);
								tryToUpload(item);
								updateRetryAttempt(
									item.leaseId,
									item.retryAttempt + 1,
								);
							}
						} else {
							updateRetryTimer(
								item.leaseId,
								uploadingRetryTimeout - timer,
							);

							timer++;
						}
					}, 1000);
				} else {
					if (item.cancelledReason === null) {
						abort(item);
					}
				}
			}
		}
	};

	const uploadWithLimit = async (
		currentUploads: string[],
		item: UploadFileInfo,
	): Promise<void> => {
		if (
			item.uploadedChunks === 0 &&
			!item.isFileCreated &&
			!item.cancelledReason &&
			!item.error &&
			!item.isUploadComplete
		) {
			if (currentUploads.length < 10) {
				increaseUploadingCount();
				updateIsWaiting(item.leaseId, false);

				const currentUploads = storage.getCurrentUploads() ?? [];
				currentUploads.push(item.path);
				storage.setCurrentUploads([...currentUploads]);

				await tryToUpload(item);
			} else {
				updateIsWaiting(item.leaseId, true);
				setTimeout(() => {
					uploadWithLimit(storage.getCurrentUploads() ?? [], item);
				}, 1000);
			}
		}
	};

	queue.forEach(async item => {
		uploadWithLimit(storage.getCurrentUploads() ?? [], item);
	});
}

export default uploadQueue;
