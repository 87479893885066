/**
 * Makes the error message clear and beautiful
 *
 * @param description Description of where the error occurred
 * (for example - 'an error occurred during authorization')
 * @param error Error object
 * @returns
 */
export function formatError(description: string, error: unknown): string {
	return `${description}: ${getErrorMessage(error)}`;
}

/**
 * Makes the invalid response message clear and beautiful
 *
 * @param res Invalid response (Not ok)
 * @returns
 */
export function formatInvalidResponse(res: Response): string {
	return `${res.statusText} (status ${res.status})`;
}

export function getErrorMessage(error: unknown): string {
	return (error as Error).message;
}
