import cacheService from "../../services/cacheAdapter";
import { CacheService, StorageService } from "../ports";
import storage from "../../services/storageAdapter";

type Deps = {
	cache: CacheService;
	storage: StorageService;
};

const defaultDeps: Deps = {
	cache: cacheService,
	storage,
};

function signOut(deps: Deps = defaultDeps): void {
	const { cache, storage } = deps;

	cache.clearAuth();
	cache.clearAuthState();

	storage.resetUser();
	window.location.reload();
}

export default signOut;
