import { StoriesService } from "../application/ports";
import { getErrorMessage } from "../application/utils/errorFormater";
import { Story } from "../domain/story";
import api from "./utils/api";

type Deps = {
	api: typeof api;
	getErrorMessage: typeof getErrorMessage;
};

const defaultDeps = { api, getErrorMessage };

function useStories(): StoriesService {
	async function getStories(
		url: string,
		deps: Deps = defaultDeps,
	): Promise<Story[]> {
		const { api, getErrorMessage } = deps;

		try {
			const res = await api().get(url);
			return res.data;
		} catch (error) {
			throw new Error(getErrorMessage(error));
		}
	}

	return { getStories };
}

export default useStories;
