import React, { FormEvent, useEffect, useState } from "react";
import {
	Text,
	PrimaryButton,
	CommandBarButton,
	Breadcrumb,
	Separator,
	IButtonStyles,
	Toggle,
	FocusTrapCallout,
	FocusZone,
	DefaultButton,
	DirectionalHint,
	TextField,
	TooltipHost,
	Icon,
} from "@fluentui/react";
import Panel from "../Panel/Panel";
import { useBoolean } from "@fluentui/react-hooks";
import Loader from "../Loader/Loader";
import "./Upload.scss";
import validateNewFolderName from "../../../application/validateNewFolderName";
import setNewFolderNameDraft from "../../../application/setNewFolderNameDraft";
import { useStore } from "effector-react";
import storage from "../../../services/storageAdapter";
import { UploadLocation } from "../../../domain/uploadLocation";
import createNewFolder from "../../../application/createNewFolder";
import setIsNeedToUseNamingStandard from "../../../application/setIsNeedToUseNamingStandard";
import { PanelKind } from "../../../domain/panel";
import controlPanel from "../../../application/controlPanel";
import { getLocationFoldersTree } from "../../../domain/locationsFoldersTree";
import { LocationFolder } from "../../../domain/locationFolder";
import selectUploadLocation from "../../../application/selectUploadLocation";
import selectFolder from "../../../application/selectFolder";
import upload from "../../../application/upload/upload";
import getLocationFolders from "../../../application/getLocationFolders";

function Upload(): JSX.Element {
	const selectedLocation = useStore(storage.selectedUploadLocation);
	const selectedFolder = useStore(storage.selectedLocationFolder);
	const dirtyQueue = useStore(storage.dirtyQueue);
	const openedPanel = useStore(storage.openedPanel);
	const locationTreeBreadCrumbs = useStore(storage.locationTreeBreadCrumbs);
	const isLocationFoldersLoading = useStore(storage.isLocationFoldersLoading);
	const locationsFoldersTree = storage.getLocationsFoldersTree();
	const locationFoldersTree = selectedLocation
		? getLocationFoldersTree(locationsFoldersTree, selectedLocation)
		: null;
	const recentUploadPath = useStore(storage.recentUploadPath);
	const isNeedToUseNamingStandard = useStore(
		storage.isNeedToUseNamingStandard,
	);
	const uploadLocations = useStore(storage.locations);
	const isFolderNameInvalid = useStore(storage.isFolderNameInvalid);
	const newFolderNameDraft = useStore(storage.newFolderNameDraft);
	const features = useStore(storage.features);

	const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] =
		useBoolean(false);
	const [isNameEmpty, setIsNameEmpty] = useState(true);

	const flexContainerStyles: IButtonStyles = {
		flexContainer: {
			display: "grid",
			gridAutoFlow: "column",
			height: "100%",
			alignContent: "center",
		},
	};

	const getTooltipContent = (): string => {
		if (selectedLocation) {
			const path = selectedLocation.title;

			if (selectedFolder) {
				return `${path}/${selectedFolder.path}`;
			}

			return path;
		}

		return "";
	};

	const getCustomDivider = (): JSX.Element => (
		<span aria-hidden="true" style={{ width: "6px" }}></span>
	);

	const Title = (): JSX.Element => (
		<TooltipHost content={getTooltipContent()}>
			<div className="upload__path">
				<Text variant="xLarge">Path to upload</Text>
				{selectedLocation && (
					<Icon
						iconName="Info"
						aria-label="Info tooltip"
						className="upload__path-icon"
					/>
				)}
			</div>
		</TooltipHost>
	);

	const LoadHelper = (): JSX.Element => (
		<Text>
			No folders loaded, press &quot;Update folders&quot; button to get
			location&apos;s folders
		</Text>
	);

	const onSelectLocation = (location: UploadLocation): void => {
		selectUploadLocation(location);
		getLocationFolders();
	};

	const onCreateFolder = (): void => {
		toggleIsCalloutVisible();
	};

	const onNameChange = (e: FormEvent): void => {
		const value = (e.target as HTMLInputElement).value;

		if (!value.length) {
			setIsNameEmpty(true);
		} else {
			setIsNameEmpty(false);
		}

		setNewFolderNameDraft(value);
	};

	const onSaveNewFolderName = (): void => {
		createNewFolder();
		toggleIsCalloutVisible();
	};

	const onChangeGenerateFolder = (
		ev: React.MouseEvent<HTMLElement>,
		checked?: boolean,
	): void => {
		setIsNeedToUseNamingStandard(!!checked);
	};

	function onSelectFolder(folder: LocationFolder): void {
		selectFolder(folder);
	}

	const onRecentUpload = (): void => {
		upload(true);
	};

	const onUpload = (): void => {
		upload();
	};

	const onUpdateFolders = async (): Promise<void> => {
		await getLocationFolders();
	};

	const onCancel = (): void => {
		controlPanel(PanelKind.None);
	};

	/** Close panel if no one file selected */
	useEffect(() => {
		if (!dirtyQueue || (dirtyQueue && !dirtyQueue.length)) {
			controlPanel(PanelKind.None);
		}
	}, [dirtyQueue]);

	return (
		<Panel isOpen={openedPanel === PanelKind.Upload} title={<Title />}>
			<div className="upload">
				{selectedLocation ? (
					<div className="upload-folders">
						<header className="upload-folders__header">
							<Breadcrumb
								items={locationTreeBreadCrumbs ?? []}
								dividerAs={getCustomDivider}
								overflowAriaLabel="More"
								className="upload__breadcrumb"
								maxDisplayedItems={4}
								overflowIndex={1}
							/>

							<div className="upload-folders__header-actions">
								<CommandBarButton
									id="create-btn"
									className="upload-btn"
									iconProps={{
										iconName: "FabricNewFolder",
									}}
									onRenderText={(): JSX.Element => (
										<span
											className="upload-txt"
											key="upload-txt"
										>
											New folder
										</span>
									)}
									styles={flexContainerStyles}
									onClick={onCreateFolder}
									disabled={isLocationFoldersLoading}
								/>

								<CommandBarButton
									className="upload-btn"
									iconProps={{
										iconName: "FabricSyncFolder",
									}}
									onRenderText={(): JSX.Element => (
										<span
											className="upload-txt"
											key="update-folders"
										>
											Update folders
										</span>
									)}
									styles={flexContainerStyles}
									onClick={onUpdateFolders}
									disabled={isLocationFoldersLoading}
								/>

								{isCalloutVisible ? (
									<FocusTrapCallout
										role="alertdialog"
										gapSpace={0}
										target={"#create-btn"}
										setInitialFocus
										directionalHint={
											DirectionalHint.topCenter
										}
										className="upload-create-folder-callout"
									>
										<FocusZone className="upload-create-folder-callout__body">
											<TextField
												label="Enter folder name"
												onChange={onNameChange}
												onGetErrorMessage={
													validateNewFolderName
												}
												validateOnLoad={false}
												placeholder="Folder name"
											/>

											<div className="upload-create-folder-callout__btns">
												<PrimaryButton
													onClick={
														onSaveNewFolderName
													}
													disabled={
														isFolderNameInvalid ||
														!newFolderNameDraft ||
														isNameEmpty
													}
												>
													Create
												</PrimaryButton>
												<DefaultButton
													onClick={
														toggleIsCalloutVisible
													}
												>
													Cancel
												</DefaultButton>
											</div>
										</FocusZone>
									</FocusTrapCallout>
								) : null}
							</div>
						</header>

						{isLocationFoldersLoading ? (
							<Loader text="Loading folders..." />
						) : (
							<>
								{locationFoldersTree &&
								locationFoldersTree.length ? (
									<div className="upload-folders__content">
										{selectedFolder ? (
											selectedFolder.childrens ? (
												selectedFolder.childrens.map(
													(i, idx) => (
														<CommandBarButton
															className="upload-btn"
															iconProps={{
																iconName:
																	"FabricFolder",
															}}
															key={i.name + idx}
															onRenderText={(): JSX.Element => (
																<span
																	className="upload-txt"
																	key={
																		i.name +
																		i.path
																	}
																>
																	{i.name}
																</span>
															)}
															styles={
																flexContainerStyles
															}
															wrap="false"
															onClick={(): void =>
																onSelectFolder(
																	i,
																)
															}
														/>
													),
												)
											) : selectedFolder.childrens ===
											  null ? (
												<Text>
													There are no subfolders at
													this level
												</Text>
											) : (
												<LoadHelper />
											)
										) : (
											locationFoldersTree.map(
												(
													i: LocationFolder,
													idx: number,
												) => {
													if (!i.parent) {
														return (
															<CommandBarButton
																className="upload-btn"
																iconProps={{
																	iconName:
																		"FabricFolder",
																}}
																key={
																	i.name + idx
																}
																onRenderText={(): JSX.Element => (
																	<span
																		className="upload-txt"
																		key={
																			i.name +
																			i.path
																		}
																	>
																		{i.name}
																	</span>
																)}
																styles={
																	flexContainerStyles
																}
																wrap="false"
																onClick={(): void =>
																	onSelectFolder(
																		i,
																	)
																}
															/>
														);
													}
												},
											)
										)}
									</div>
								) : (
									<div className="upload-folders__content">
										<LoadHelper />
									</div>
								)}
							</>
						)}
					</div>
				) : (
					<div className="upload-locations">
						<div className="upload-locations__locations">
							{uploadLocations &&
								uploadLocations.map(l => (
									<CommandBarButton
										className="upload-btn"
										iconProps={{ iconName: "Cloud" }}
										key={l.id}
										onRenderText={(): JSX.Element => (
											<span
												className="upload-txt"
												key={l.title + l.id}
											>
												{l.title}
											</span>
										)}
										styles={flexContainerStyles}
										wrap="false"
										onClick={(): void =>
											onSelectLocation(l)
										}
									/>
								))}
						</div>

						{recentUploadPath && (
							<div className="upload-locations__recent">
								<Separator />

								<Text variant="small">
									Upload to recent path
								</Text>

								<CommandBarButton
									className="upload-btn"
									iconProps={{
										iconName: "FabricNetworkFolder",
									}}
									onRenderText={(): JSX.Element => (
										<TooltipHost
											hostClassName="upload-txt upload-txt_overflow"
											content={recentUploadPath}
											key={recentUploadPath}
										>
											{recentUploadPath}
										</TooltipHost>
									)}
									styles={flexContainerStyles}
									wrap="false"
									onClick={onRecentUpload}
								/>
							</div>
						)}
					</div>
				)}

				{selectedLocation && (
					<footer className="upload-folders__footer">
						<Separator />

						<div className="upload-actions">
							<Toggle
								label={
									<>
										Use Subfolder Naming Standard{" "}
										<TooltipHost
											content={
												features.storiesFeature
													? `Create subfolder named "MM-DD-YYYY [Story title]"`
													: `Create subfolder named "MM-DD-YYYY" with today's date`
											}
										>
											<Icon
												iconName="Info"
												aria-label="Info tooltip"
												className="upload-actions__upload-type-info-icon"
											/>
										</TooltipHost>
									</>
								}
								checked={isNeedToUseNamingStandard}
								onText="On"
								offText="Off"
								onChange={onChangeGenerateFolder}
								className="upload-actions__upload-type"
								role="checkbox"
								aria-describedby="toggleId"
								disabled={isLocationFoldersLoading}
							/>

							<div className="upload-actions__btns">
								<PrimaryButton
									className="upload-actions__btns-btn"
									text="Upload here"
									iconProps={{ iconName: "CloudUpload" }}
									disabled={
										!selectedLocation ||
										!dirtyQueue ||
										(dirtyQueue && !dirtyQueue.length) ||
										isLocationFoldersLoading
									}
									onClick={onUpload}
								/>
								<DefaultButton
									className="upload-actions__btns-btn"
									text="Cancel"
									onClick={onCancel}
								/>
							</div>
						</div>
					</footer>
				)}
			</div>
		</Panel>
	);
}

export default Upload;
