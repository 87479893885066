export type LocationFolder = {
	name: string;
	childrens?: LocationFolder[] | null;
	parent?: LocationFolder;
	path: string;
	key: string;
};

export function getLocationFolderNameIsNotUniqueMessage(
	folderName: string,
): string {
	return `The name of folder must be unique (${folderName} already exists)`;
}
