export type UploadLocation = {
	id: string;
	title: string;
};

/**
 * Find location in an array of locations by ID, throw an error if not found
 */
export function findUploadLocation(
	uploadLocations: UploadLocation[],
	uploadLocationId: string,
): UploadLocation | undefined {
	const uploadLocation = uploadLocations.find(l => l.id === uploadLocationId);

	if (!uploadLocation) {
		throw new Error(
			`Something went wrong: this locations is not found in storage (locationId - ${uploadLocationId}`,
		);
	}

	return uploadLocation;
}
