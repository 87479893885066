import useAuth from "../../services/authAdapter";
import cacheService from "../../services/cacheAdapter";
import storageAdapter from "../../services/storageAdapter";
import { getAppError } from "../appError/appError";
import { AppErrorKind } from "../appError/appErrorKind";
import { AuthService, CacheService, StorageService } from "../ports";
import auth from "./auth";

type Deps = {
	authService: AuthService;
	cacheService: CacheService;
	storageService: StorageService;
	getAppError: typeof getAppError;
	appErrorKind: typeof AppErrorKind;
};

const defaultDeps: Deps = {
	authService: useAuth,
	cacheService: cacheService,
	storageService: storageAdapter,
	getAppError,
	appErrorKind: AppErrorKind,
};

async function signIn(deps: Deps = defaultDeps): Promise<void> {
	const {
		authService,
		cacheService,
		storageService,
		getAppError,
		appErrorKind,
	} = deps;

	storageService.resetAuthError();

	try {
		const state = cacheService.getAuthState();
		const returnUri = cacheService.getReturnUri();

		if (state === null || returnUri === null) {
			auth();
		} else {
			const userCredentials = await authService.getUserCredentials(state);

			const expirationDate = new Date();
			expirationDate.setSeconds(
				expirationDate.getSeconds() + userCredentials.expiresIn,
			);

			cacheService.setAuth({
				...userCredentials.user,
				expirationDate: expirationDate,
				token: userCredentials.accessToken,
			});

			await authService.signIn(userCredentials.accessToken, state);

			if (returnUri === null) {
				throw new Error("No return uri");
			}

			window.location = returnUri as Location & string;
		}
	} catch (error) {
		storageService.setAuthError(
			getAppError(
				error as Error,
				appErrorKind.Auth,
				"error during authorization",
			),
		);
		throw error;
	}
}

export default signIn;
