import { PanelKind } from "../domain/panel";
import storage from "../services/storageAdapter";
import { StorageService } from "./ports";

type Deps = {
	storage: StorageService;
};

const defaultDeps: Deps = {
	storage,
};

function controlPanel(panelName: PanelKind, deps: Deps = defaultDeps): void {
	const { storage } = deps;

	const openedPanel = storage.getOpenedPanel();

	if (panelName !== openedPanel) {
		if (openedPanel !== PanelKind.None) {
			storage.setOpenedPanel(PanelKind.None);

			setTimeout(() => {
				storage.setOpenedPanel(panelName);
			}, 140);
		} else {
			storage.setOpenedPanel(panelName);
		}
	}
}

export default controlPanel;
