import { FileService } from "../application/ports";
import { AppMessage } from "../domain/appMessages";
import {
	getCompleteUploadUrl,
	getCreateFileUrl,
	getFailUploadUrl,
	getUploadUrl,
} from "./utils/endpoints";
import { getErrorMessage } from "../application/utils/errorFormater";
import { UploadFileInfo } from "../domain/fileInfo";
import { FileMetadata } from "../domain/fileMetadata";
import api from "./utils/api";

type Deps = {
	getErrorMessage: typeof getErrorMessage;
	api: typeof api;
};

type CreateFileDeps = Deps & {
	getCreateFileUrl: typeof getCreateFileUrl;
};

type FailDeps = Deps & {
	getFailUploadUrl: typeof getFailUploadUrl;
};

type CompleteDeps = Deps & {
	getCompleteUploadUrl: typeof getCompleteUploadUrl;
};

type UploadChunkDeps = Deps & {
	getUploadUrl: typeof getUploadUrl;
};

const commonDeps = {
	getErrorMessage: getErrorMessage,
	api,
};

const defaultCreateFileDeps: CreateFileDeps = {
	...commonDeps,
	getCreateFileUrl,
};

const defaultFailDeps: FailDeps = { ...commonDeps, getFailUploadUrl };

const defaultCompleteDeps: CompleteDeps = {
	...commonDeps,
	getCompleteUploadUrl,
};

const defaultUploadChunkDeps: UploadChunkDeps = {
	...commonDeps,
	getUploadUrl,
};

function useFile(): FileService {
	async function createFile(
		element: UploadFileInfo,
		deps: CreateFileDeps = defaultCreateFileDeps,
	): Promise<Response> {
		const { getErrorMessage, api, getCreateFileUrl } = deps;

		try {
			const payload = {
				leaseId: element.leaseId,
				filePath: encodeURIComponent(element.path),
				fileSize: element.file.size,
			};

			return await api().put(
				getCreateFileUrl(element.location.id),
				payload,
				{
					headers: {
						"Content-Type": "application/json; charset=UTF-8",
					},
				},
			);
		} catch (error) {
			throw new Error(getErrorMessage(error));
		}
	}

	async function uploadChunk(
		element: UploadFileInfo,
		chunkId: string,
		leaseId: string,
		chunkStart: number,
		blobEnd: number,
		chunkForm: FormData,
		controller: AbortController,
		deps: UploadChunkDeps = defaultUploadChunkDeps,
	): Promise<Response> {
		const { getErrorMessage, api, getUploadUrl } = deps;

		try {
			return await api().post(
				getUploadUrl(element.location.id),
				chunkForm,
				{
					headers: {
						filePath: encodeURIComponent(element.path),
						chunkId: chunkId,
						"x-leaseId": leaseId,
						Range: `bytes=${chunkStart}-${blobEnd}`,
						"Content-Type": "multipart/form-data; charset=UTF-8",
					},
					signal: controller.signal,
				},
			);
		} catch (error) {
			throw new Error(getErrorMessage(error));
		}
	}

	async function fail(
		element: UploadFileInfo,
		deps: FailDeps = defaultFailDeps,
	): Promise<Response> {
		const { getErrorMessage, api } = deps;

		try {
			const payload = {
				leaseId: element.leaseId,
				fileSize: element.file.size,
				filePath: encodeURIComponent(element.path),
			};

			return await api().post(
				getFailUploadUrl(element.location.id),
				payload,
				{
					headers: {
						"Content-Type": "application/json; charset=UTF-8",
					},
				},
			);
		} catch (error) {
			throw new Error(getErrorMessage(error));
		}
	}

	async function complete(
		element: UploadFileInfo,
		deps: CompleteDeps = defaultCompleteDeps,
	): Promise<Response> {
		const { getErrorMessage, api, getCompleteUploadUrl } = deps;

		try {
			const chunks = element.chunksMap;

			if (!chunks) {
				throw new Error(AppMessage.NoChunksMap);
			}

			const metadata: FileMetadata = {
				name: encodeURIComponent(`${element.name}.${element.ext}`),
				ext: element.ext,
			};

			if (element.storyId) {
				metadata.story_id = element.storyId;
			}
			if (element.storyTitle) {
				metadata.story_title = element.storyTitle;
			}
			if (element.umid) {
				metadata.umid = element.umid;
			}
			if (element.description) {
				metadata.description = encodeURIComponent(element.description);
			}
			if (element.cameraModelName) {
				metadata.camera_model_name = element.cameraModelName;
			}
			if (element.cameraSerialNumber) {
				metadata.camera_serial_number = element.cameraSerialNumber;
			}

			const payload = {
				leaseId: element.leaseId,
				filePath: encodeURIComponent(element.path),
				fileSize: element.file.size,
				chunks,
				metadata,
			};

			return await api().post(
				getCompleteUploadUrl(element.location.id),
				payload,
				{
					headers: {
						"Content-Type": "application/json; charset=UTF-8",
					},
				},
			);
		} catch (error) {
			throw new Error(getErrorMessage(error));
		}
	}

	return { createFile, uploadChunk, fail, complete };
}

export default useFile;
