import axios, { AxiosInstance } from "axios";
import { CacheService, StorageService } from "../../application/ports";
import storage from "../storageAdapter";
import cacheService from "../cacheAdapter";
import { AppMessage } from "../../domain/appMessages";

type Deps = {
	storage: StorageService;
	cache: CacheService;
};

const defaultDeps: Deps = {
	storage,
	cache: cacheService,
};

function api(deps: Deps = defaultDeps): AxiosInstance {
	const { storage, cache } = deps;

	const user = storage.getUser();
	const authState = cache.getAuthState();

	if (user === null || authState === null) {
		throw new Error(AppMessage.NoToken);
	}

	const instance = axios.create({
		headers: {
			Authorization: `Bearer ${user.token}`,
			"X-Session-Id": authState,
		},
	});

	return instance;
}

export default api;
