export enum AppMessage {
	NoUser = "The user is not found, try to reload the page or reauthorize",
	NoFilesInfo = "Information about the imported files was not found, try to reload the page and re-import the files",
	NoUploadingInfo = "Information about uploading files was not found, try to reload the page",
	NoDirtyQueue = "No files selected, try to reload the page",
	NoQueue = "No files to upload, try to reload the page",
	NoQueueRef = "File is not found in queue",
	NoFiles = "No files found, try to reload the page",
	NoSelectedLocation = "No selected upload location, try to reload the page or re-select upload location",
	NoSelectedLocationId = "Upload location ID not found, try to reload the page or re-select upload location",
	NoSelectedLocationTitle = "Upload location tile not found, try to reload the page or re-select upload location",
	NoToken = "Authorization token not found, try to reauthorize",
	NoBreadcrumbs = "Breadcrumbs not found, try to reload the page or re-select upload location",
	NoStories = "No stories found, try to reload the page",
	NoStory = "It looks like this is the wrong story",
	NoUploadLocations = "No upload locations found for the current user",
	NoNameMultipleFiles = "You can't name multiple files",
	EmptyFileName = "The name cannot be empty",
	NotUniqueFileName = "The name must be unique",
	DifferentDescriptions = "The descriptions of the selected files are different",
	DifferentStories = "The selected files have different stories",
	CantGetStories = "Can't get stories from Wolftech (Wolftech error)",
	NoChunksMap = "No chunks map found",
	NoChunkStart = "No 'chunkStart' found in file info",
	NoFileChunkSize = "No 'fileChunkSize' found in file info",
	NoBlobEnd = "No 'blobEnd' found in file info",
	NoStartTime = "No 'startTime' found in file info",
	ReAuth = "Auto reauthorization failed, try clearing your browser cache and reloading the page.",
	NoController = "No contrroller found for this file",
	FileUploading = "This file is being uploaded using the same path right now, change the path/file name or wait for the upload to finish. ",
	CantParseMetadata = "Metadata parsing failed",
	NoMetadataFile = "Metadata file not found",
	NoFileInfo = "Can't find this file in storage",
	NoFoldersTree = "Can't find the folders tree",
	NoParrentFolder = "Parent folder not found",
	NoSelectedLocationFolder = "No selected upload location folder, try to reload the page or re-select upload location folder",
	CantCalculated = "Can't calculated",
	CanceledByUser = "Canceled by user",
	CantGettingLocationFolders = "Can't get location folders",
	CantGettingLocations = "Can't get locations",
	StoriesVendorReturnNull = "Stories vendor return null",
	CantGettingStories = "Can't get stories from stories provider",
	NotExternalApp = "Is not external app, try to reload page",
	AuthError = "An error occurred during authorization",
	LogoutError = "An error occurred during logout",
	ValidationEmptyName = "The name cannot be empty",
	ValidationSeparatorSymbol = "The name cannot contain a slash character - '/'",
	ValidationNoStory = "It looks like this is the wrong story",
	ErrorWithFailApiCall = "Error during 'fail' api call",
	CantInit = "Error during application initialization",
}
