import React from "react";
import controlPanel from "../../../application/controlPanel";
import { PanelKind } from "../../../domain/panel";
import { useStore } from "effector-react";
import storage from "../../../services/storageAdapter";
import { UploaderType } from "../../../domain/uploaderType";
import "./Panel.scss";
import { ActionButton } from "@fluentui/react";

type PanelProps = {
	isOpen: boolean;
	title: JSX.Element;
	controlBar?: JSX.Element;
	children: JSX.Element;
};

function Panel(props: PanelProps): JSX.Element {
	const uploaderType = useStore(storage.uploaderType);
	const isEmbedded = uploaderType == UploaderType.Embedded;

	const closePanel = (): void => {
		controlPanel(PanelKind.None);
	};

	return (
		<div
			className={`panel ${props.isOpen ? "panel_open" : "panel_closed"} ${
				isEmbedded ? "panel_embedded" : "panel_classic"
			}`}
		>
			<header className="panel__header">
				<div className="panel__title">{props.title}</div>
				{props.controlBar && <div>{props.controlBar}</div>}
				<ActionButton
					iconProps={{ iconName: "Clear" }}
					onClick={closePanel}
					styles={{ root: { padding: 0 }, icon: { margin: 0 } }}
				/>
			</header>
			<div className="panel__body">{props.children}</div>
		</div>
	);
}

export default Panel;
