import qs from "qs";
import { AuthService } from "../application/ports";
import AuthConfig from "../domain/authConfig";
import { UserCredentials } from "../domain/user";

class AuthAdapter implements AuthService {
	private redirectUri = `${window.location.protocol}//${window.location.host}/sign-in-callback`;

	constructor() {
		this.getAuthConfig = this.getAuthConfig.bind(this);
		this.getUserCredentials = this.getUserCredentials.bind(this);
		this.signOut = this.signOut.bind(this);
	}

	async getAuthConfig(): Promise<AuthConfig> {
		const res = await fetch(
			`/api/auth/request?redirectUri=${this.redirectUri}`,
		);
		const data = await res.json();

		return data;
	}

	async getUserCredentials(state: string): Promise<UserCredentials> {
		const query = qs.parse(window.location.search, {
			ignoreQueryPrefix: true,
		});

		const payload = {
			state,
			code: query.code,
			redirectUri: this.redirectUri,
		};

		const res = await fetch("/api/auth", {
			method: "POST",
			body: JSON.stringify(payload),
			headers: {
				"Content-Type": "application/json;charset=UTF-8",
			},
		});

		const userCredentials: UserCredentials = await res.json();
		return userCredentials;
	}

	async signIn(token: string, state: string): Promise<void> {
		await fetch("/api/authentication/track", {
			method: "POST",
			headers: {
				Authorization: `Bearer ${token}`,
				"X-Session-Id": state,
				"Content-Type": "application/json;charset=UTF-8",
			},
			body: JSON.stringify({ actionType: "SignIn" }),
		});
	}

	async signOut(token: string, state: string): Promise<void> {
		await fetch("/api/authentication/track", {
			method: "POST",
			headers: {
				Authorization: `Bearer ${token}`,
				"X-Session-Id": state,
				"Content-Type": "application/json;charset=UTF-8",
			},
			body: JSON.stringify({ actionType: "SignOut" }),
		});
	}
}

const authAdapter = new AuthAdapter();

export default authAdapter;
