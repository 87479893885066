import { LocationTreeBreadCrumb } from "../domain/locationTreeBreadCrumbs";
import storage from "../services/storageAdapter";
import useNotifier from "../services/notificationAdapter";
import { NotificationService, StorageService } from "./ports";
import { AppMessage } from "../domain/appMessages";

type Deps = {
	storage: StorageService;
	notifier: NotificationService;
};

const defaultDeps: Deps = {
	storage,
	notifier: useNotifier(),
};

/**
 * Reset breadcrumbs to default state (root > {LOCATION})
 *
 * @param deps
 * @returns Breadcrumbs
 */
function resetBreadcrumbs(deps: Deps = defaultDeps): LocationTreeBreadCrumb[] {
	const { storage, notifier } = deps;

	const selectedUploadLocation = storage.getSelectedUploadLocation();

	if (!selectedUploadLocation) {
		notifier.notify("error", AppMessage.NoSelectedLocation);
		throw new Error(AppMessage.NoSelectedLocation);
	}

	const onRootClick = (): void => {
		storage.resetSelectedUploadLocation();
		storage.resetSelectedLocationFolder();
	};

	const onLocationClick = (): void => {
		const breadcrumbs = storage.getLocationTreeBreadCrumbs();
		const newBreadcrumbs = breadcrumbs ? breadcrumbs.slice(0, 2) : [];

		storage.setLocationTreeBreadCrumbs(newBreadcrumbs);
		storage.resetSelectedLocationFolder();
	};

	const newBreadcrumbs = [
		{
			text: "root",
			key: "main",
			onClick: onRootClick,
		},
		{
			text: selectedUploadLocation.title,
			key: selectedUploadLocation.id,
			onClick: onLocationClick,
		},
	];

	storage.setLocationTreeBreadCrumbs(newBreadcrumbs);

	return newBreadcrumbs;
}

export default resetBreadcrumbs;
