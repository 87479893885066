import { LocationFolder } from "../domain/locationFolder";
import storage from "../services/storageAdapter";
import findAndSelectFolder from "./findAndSelectFolder";
import { StorageService } from "./ports";

type Deps = {
	storage: StorageService;
	findAndSelectFolder: typeof findAndSelectFolder;
};

const defaultDeps: Deps = {
	storage,
	findAndSelectFolder,
};

/**
 * Create new folder with name from draft
 *
 * @param deps
 * @returns New folder or null
 */
function createNewFolder(deps: Deps = defaultDeps): LocationFolder | null {
	const { storage, findAndSelectFolder } = deps;

	const newFolderNameDraft = storage.getNewFolderNameDraft();

	if (newFolderNameDraft) {
		findAndSelectFolder(newFolderNameDraft);
	}

	return storage.getSelectedLocationFolder();
}

export default createNewFolder;
