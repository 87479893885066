import { FileInfo, FileWithPath } from "../../../domain/fileInfo";
import storage from "../../../services/storageAdapter";
import convertTime from "../../utils/convertTime";
import { StorageService } from "../../ports";
import path from "path";
import { TimeFormat } from "../../../domain/timeFormat";
import formatStory from "../../utils/formatStory";

type Deps = {
	storage: StorageService;
	convertTime: typeof convertTime;
	formatStory: typeof formatStory;
};

const defaultDeps: Deps = {
	storage,
	convertTime,
	formatStory,
};

export function getUploadsPath(
	element: FileInfo,
	folder: string,
	isNeedToCreateSubfolderByConvention: boolean,
	deps: Deps = defaultDeps,
): string {
	const { storage, convertTime, formatStory } = deps;

	const fileWithPath = element.file as FileWithPath;

	let baseName = "";

	if (folder.length) {
		baseName += folder;
	}

	if (isNeedToCreateSubfolderByConvention) {
		const time = new Date().getTime();

		if (
			storage.getFeatures().storiesFeature &&
			element.storyId &&
			element.storyTitle
		) {
			baseName += `/${convertTime(time, TimeFormat.M2D2Y4)} ${formatStory(
				element.storyId,
				element.storyTitle,
			)}`;
		} else {
			baseName += `/${convertTime(time, TimeFormat.M2D2Y4)}`;
		}
	}

	if (storage.getIsNeedToPreservePath()) {
		return path.normalize(
			`${baseName}/${
				fileWithPath.webkitRelativePath.split(element.file.name)[0]
			}/${element.name}.${element.ext}`,
		);
	}

	if (
		!storage.getIsCameraCardImported() &&
		storage.getIsDataDragNDropped() &&
		fileWithPath.path.includes("/")
	) {
		return path.normalize(
			`${baseName}/${fileWithPath.path.split(element.file.name)[0]}/${
				element.name
			}.${element.ext}`,
		);
	}

	return path.normalize(`${baseName}/${element.name}.${element.ext}`);
}
