import { UploadFileInfo } from "../../../domain/fileInfo";
import useFile from "../../../services/fileAdapter";
import { FileService } from "../../ports";
import { getErrorMessage } from "../../utils/errorFormater";
import { updateIsFileCreated } from "./updateUploadingInfo";

type Deps = {
	updateIsFileCreated: typeof updateIsFileCreated;
	getErrorMessage: typeof getErrorMessage;
	fileService: FileService;
};

const defaultDeps: Deps = {
	updateIsFileCreated,
	getErrorMessage,
	fileService: useFile(),
};

async function createFile(
	element: UploadFileInfo,
	deps: Deps = defaultDeps,
): Promise<void> {
	const { fileService, updateIsFileCreated, getErrorMessage } = deps;

	try {
		await fileService.createFile(element);

		updateIsFileCreated(element.leaseId, true);
	} catch (error) {
		throw new Error(getErrorMessage(error));
	}
}

export default createFile;
