import {
	updateErrorToDefault,
	updateIsFileCreated,
	updateIsUploadComplete,
} from "./upload/updateUploadingInfo";
import { AppMessage } from "../../domain/appMessages";
import { FileService } from "../ports";
import { UploadFileInfo } from "../../domain/fileInfo";
import useFile from "../../services/fileAdapter";
import { getErrorMessage } from "../utils/errorFormater";
import abort from "./shared/abort";

type Deps = {
	updateErrorToDefault: typeof updateErrorToDefault;
	updateIsUploadComplete: typeof updateIsUploadComplete;
	fileService: FileService;
};

const defaultDeps: Deps = {
	updateErrorToDefault,
	updateIsUploadComplete,
	fileService: useFile(),
};

/** Cancels the uploading, closes the connection */
async function cancelUpload(
	element: UploadFileInfo,
	deps: Deps = defaultDeps,
): Promise<void> {
	const { updateErrorToDefault, fileService } = deps;

	abort(element);

	updateErrorToDefault(element.leaseId);

	try {
		const res = await fileService.fail(element);

		if (res.status === 200) {
			updateIsFileCreated(element.leaseId, false);
		}
	} catch (error) {
		console.error(
			`${getErrorMessage(error)} (${AppMessage.ErrorWithFailApiCall})`,
		);
	}
}

export default cancelUpload;
