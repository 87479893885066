import { AppMessage } from "../../../domain/appMessages";
import { formatError } from "../../utils/errorFormater";
import { FileWithPath } from "../../../domain/fileInfo";

/**
 * Parses metadata and return data (xml-like document).
 *
 * @param metadataFile
 * @returns XML-file (metadata).
 */
async function getParsedMetadata(
	metadataFile: FileWithPath,
): Promise<Document> {
	try {
		const parser = new DOMParser();
		const text = await new Response(metadataFile).text();

		return parser.parseFromString(text, "text/xml");
	} catch (error) {
		throw new Error(formatError(AppMessage.CantParseMetadata, error));
	}
}

export default getParsedMetadata;
