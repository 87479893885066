import { CacheService } from "../application/ports";
import { User } from "../domain/user";
import { routes } from "../ui/router";
import { CachedData, CachedItemName } from "../domain/cache";

class CacheAdapter implements CacheService {
	constructor() {
		this.setAuthState = this.setAuthState.bind(this);
		this.getAuthState = this.getAuthState.bind(this);
		this.setAuth = this.setAuth.bind(this);
		this.getAuth = this.getAuth.bind(this);
		this.setReturnUri = this.setReturnUri.bind(this);
		this.getReturnUri = this.getReturnUri.bind(this);
		this.getCache = this.getCache.bind(this);
	}

	private _authStateCacheKey = "air.auth.state";
	private _authCacheKey = "air.auth";
	private _authReturnUriCacheKey = "air.auth.returnUri";
	private _cacheServise = window.localStorage;

	setAuthState(authState: string): void {
		this._cacheServise.setItem(this._authStateCacheKey, authState);
	}
	getAuthState(): string | null {
		return this._cacheServise.getItem(this._authStateCacheKey);
	}
	clearAuthState(): void {
		this._cacheServise.removeItem(this._authStateCacheKey);
	}

	setAuth(auth: User): void {
		this._cacheServise.setItem(this._authCacheKey, JSON.stringify(auth));
	}
	getAuth(): User | null {
		const cached = this._cacheServise.getItem(this._authCacheKey);

		if (cached === null) {
			return null;
		}

		return JSON.parse(cached);
	}
	clearAuth(): void {
		this._cacheServise.removeItem(this._authCacheKey);
	}

	setReturnUri(returnUri: string): void {
		if (returnUri.includes(routes.signInHandler)) {
			this._cacheServise.setItem(
				this._authReturnUriCacheKey,
				routes.classic,
			);
		} else {
			this._cacheServise.setItem(this._authReturnUriCacheKey, returnUri);
		}
	}
	getReturnUri(): string | null {
		const returnUri = this._cacheServise.getItem(
			this._authReturnUriCacheKey,
		);

		if (returnUri === null) {
			return returnUri;
		}

		return returnUri.includes(routes.signInHandler)
			? routes.classic
			: returnUri;
	}

	getCache(): CachedData {
		const recentLocationTitle = localStorage.getItem(
			CachedItemName.RecentLocationTitle,
		);
		const recentLocationId = localStorage.getItem(
			CachedItemName.RecentLocationId,
		);
		const subfolderNamingStandardRule = localStorage.getItem(
			CachedItemName.RecentSubfolderNamingStandardRule,
		);
		const darkModeRule = localStorage.getItem(CachedItemName.IsDarkMode);

		const cahcedData: CachedData = {
			recentFolder: localStorage.getItem(CachedItemName.RecentFolder),
			recentUploadPath: localStorage.getItem(
				CachedItemName.RecentUploadPath,
			),
			recentSubfolderNamingStandardRule:
				subfolderNamingStandardRule === "true" ? true : false,
			recentLocation: null,
			isDarkMode: darkModeRule === "true" ? true : false,
		};

		if (recentLocationId && recentLocationTitle) {
			cahcedData.recentLocation = {
				id: recentLocationId,
				title: recentLocationTitle,
			};
		}
		return cahcedData;
	}
}

const cacheService = new CacheAdapter();

export default cacheService;
