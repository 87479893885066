import { UuidService } from "../application/ports";
import { v4 } from "uuid";

function useUuid(): UuidService {
	function getUuid(): string {
		return v4();
	}

	return { getUuid };
}

export default useUuid;
