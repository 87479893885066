import { FileWithPath } from "../../../domain/fileInfo";
import storage from "../../../services/storageAdapter";
import { validatorAdapter } from "../../../services/validatorAdapter";
import { StorageService, ValidatorService } from "../../ports";

type Deps = {
	storage: StorageService;
	validator: ValidatorService;
};

const defaultDeps: Deps = {
	storage,
	validator: validatorAdapter,
};

function validataFiles(
	files: FileList | File[],
	deps: Deps = defaultDeps,
): File[] {
	const { storage, validator } = deps;

	const sourcePaths: string[] = [];
	const validFiles: File[] = [];
	const invalidFilePaths: string[] = [];

	for (let index = 0; index < files.length; index++) {
		const sourcePath = storage.getIsDataDragNDropped()
			? (files[index] as FileWithPath).path
			: (files[index] as FileWithPath).webkitRelativePath;
		const isDublicate = sourcePaths.includes(sourcePath);

		if (
			!files[index].name.startsWith(".") &&
			!isDublicate &&
			files[index].size > 0 &&
			validator.validate(sourcePath, storage.getIgnoreList())
		) {
			validFiles.push(files[index]);

			// path is empty (relative) when import file(s) via input tag
			if (sourcePath.length === 0) {
				sourcePaths.push((files[index] as FileWithPath).name);
			} else {
				sourcePaths.push(sourcePath);
			}
		} else {
			invalidFilePaths.push(sourcePath);
		}
	}

	storage.setIgnoredFilePaths(invalidFilePaths);

	return validFiles;
}

export default validataFiles;
