import { createState } from "@persevie/statemanjs";
import { AuthStorageService } from "../../application/ports";

export const authErrorState = createState<string | null>(null);

function setAuthError(error: string): void {
	authErrorState.set(error);
}

function getAuthError(): string | null {
	return authErrorState.get();
}

function resetAuthError(): void {
	authErrorState.set(null);
}

const authStorageService: AuthStorageService = {
	setAuthError,
	getAuthError,
	resetAuthError,
};

export default authStorageService;
