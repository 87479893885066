import { FeaturesService, NotificationService } from "../application/ports";
import Features, { defaultFeatures } from "../domain/features";
import useNotifier from "./notificationAdapter";
import { featuresUrl } from "./utils/endpoints";
import api from "./utils/api";

type Deps = {
	api: typeof api;
	notifier: NotificationService;
};

const defaultDeps = {
	api,
	notifier: useNotifier(),
};

function useFeauturesService(): FeaturesService {
	async function getFeatures(deps: Deps = defaultDeps): Promise<Features> {
		const { api, notifier } = deps;

		try {
			const res = await api().get(featuresUrl);
			return res.data;
		} catch (error) {
			notifier.notify("warn", (error as Error).message);
			return defaultFeatures;
		}
	}

	return { getFeatures };
}

export default useFeauturesService;
