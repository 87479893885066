import { IconService } from "../application/ports";

function useIcon(): IconService {
	function getIconPath(iconType: string): string {
		return `https://static2.sharepointonline.com/files/fabric/assets/item-types/16/${iconType}.svg`;
	}

	return { getIconPath };
}

export default useIcon;
