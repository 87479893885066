import React from "react";
import { IconButton } from "@fluentui/react";
import storage from "../../../services/storageAdapter";
import User from "../User/User";
import controlPanel from "../../../application/controlPanel";
import { PanelKind } from "../../../domain/panel";
import "./Header.scss";
import { observer } from "mobx-react";
import { Text } from "@fluentui/react";
import { logoUrlState } from "../../shared/state";

const queueInstance = storage.queue;

const Header = observer((): JSX.Element => {
	const queue = queueInstance.getQueue();

	const showQueuePanel = (): void => {
		controlPanel(PanelKind.Queue);
	};

	return (
		<header className="header">
			<div className="header__content-wrap">
				<div className="header__about">
					<img
						height={32}
						width={32}
						src={logoUrlState.get()}
						alt="uploader logo"
					/>
					<Text
						variant="xLarge"
						styles={{ root: { color: "#ffffff" } }}
					>
						AIR Uploader
					</Text>
				</div>
				<div className="header__actions">
					<IconButton
						iconProps={{ iconName: "Ringer" }}
						title="Uploads"
						ariaLabel="Uploads"
						className="header__bell"
						onClick={showQueuePanel}
						disabled={!queue || (queue && !queue.length)}
					/>
					<User />
				</div>
			</div>
		</header>
	);
});

export default Header;
