import React, { useEffect, useState } from "react";
import {
	DetailsList,
	DetailsListLayoutMode,
	IColumn,
	SelectionMode,
	TooltipHost,
	Text,
} from "@fluentui/react";
import defaultThmbnl from "../../assets/default-file.svg";
import storage from "../../../services/storageAdapter";
import { useStore } from "effector-react";
import { FileInfo, getFullStory, sortFiles } from "../../../domain/fileInfo";
import { PanelKind } from "../../../domain/panel";
import controlPanel from "../../../application/controlPanel";
import setSortedColumn from "../../../application/setSortedColumn";
import updateFilesInfo from "../../../application/updateFilesInfo";
import planSorting from "../../../application/planSorting";
import "./FileList.scss";
import { observer } from "mobx-react";
import selectionStore from "../../shared/selectionStore";
import { noStory } from "../../../domain/defaultValues";

const selection = selectionStore.getSelection();
const { resetSelection } = selectionStore;

/** Renders a table of imported elements. */
const FileList = observer((): JSX.Element => {
	const filesInfo = useStore(storage.filesInfo);
	const sortedColumn = useStore(storage.sortedColumn);
	const isNeedToSort = useStore(storage.isNeedToSort);
	const features = useStore(storage.features);
	const isCameraCardImported = useStore(storage.isCameraCardImported);

	const getColumns = (): IColumn[] => {
		const columns: IColumn[] = [];

		// Preview and name columns
		columns.push(
			{
				key: "thmbnl",
				name: "Preview",
				fieldName: "thmbnl",
				minWidth: 56,
				maxWidth: 56,
				isRowHeader: true,
				isResizable: false,
				isPadded: true,
				onRender: (i: FileInfo): JSX.Element => (
					<>
						{features.cameraCardParsingFeature &&
						isCameraCardImported ? (
							<TooltipHost
								id={i.name + i.id}
								tooltipProps={{
									onRenderContent: (): JSX.Element => (
										<div className="file-list__tooltip">
											{i.thmbnl && (
												<img
													className="file-list__thmbnl_big"
													src={i.thmbnl}
													loading="lazy"
													alt="File thumbnail"
												/>
											)}
											<Text variant="small" nowrap>
												{i.name}.{i.ext}
											</Text>
										</div>
									),
								}}
							>
								<div
									style={{
										display: "grid",
										width: "100%",
										height: "100%",
										justifyContent: "center",
										alignContent: "center",
									}}
								>
									<img
										key={`thmbnl-${i.name}`}
										className="file-list__thmbnl-meta"
										src={
											i.thmbnl.length
												? i.thmbnl
												: defaultThmbnl
										}
										loading="lazy"
										alt="File thumbnail"
									/>
								</div>
							</TooltipHost>
						) : (
							<div
								style={{
									display: "grid",
									width: "100%",
									height: "100%",
									justifyContent: "center",
									alignContent: "center",
								}}
							>
								<img
									key={`thmbnl-${i.name}`}
									className="file-list__thmbnl"
									src={
										i.thmbnl.length
											? i.thmbnl
											: defaultThmbnl
									}
									loading="lazy"
									alt="File thumbnail"
								/>
							</div>
						)}
					</>
				),
			},
			{
				key: "name",
				name: "Name",
				fieldName: "name",
				minWidth: 100,
				maxWidth: 200,
				isRowHeader: true,
				isResizable: true,
				data: "string",
				isPadded: true,
				isSortedDescending: false,
				sortAscendingAriaLabel: "Sorted A to Z",
				sortDescendingAriaLabel: "Sorted Z to A",
				onColumnClick: onSort,
				onRender: (i: FileInfo): JSX.Element => (
					<div
						key={`name-${i.name}`}
						className="file-list__meta-wrap file-list__meta-wrap_clickable"
						onClick={showMetadataPanel}
					>
						<span className="file-list__meta-data">{i.name}</span>
					</div>
				),
			},
		);

		if (features.storiesFeature) {
			columns.push({
				key: "storyTitle",
				name: "Story",
				fieldName: "storyTitle",
				minWidth: 100,
				maxWidth: 200,
				isRowHeader: true,
				isResizable: true,
				data: "string",
				isPadded: true,
				isSortedDescending: false,
				sortAscendingAriaLabel: "Sorted A to Z",
				sortDescendingAriaLabel: "Sorted Z to A",
				onColumnClick: onSort,
				onRender: (i: FileInfo): JSX.Element => (
					<div
						key={`story-${i.storyId}`}
						className="file-list__meta-wrap file-list__meta-wrap_clickable"
						onClick={showMetadataPanel}
					>
						<span className="file-list__meta-data">
							{getFullStory(i) ?? noStory}
						</span>
					</div>
				),
			});
		}

		// Type, size and lastModified columns
		columns.push(
			{
				key: "type",
				name: "Type",
				fieldName: "type",
				minWidth: 50,
				maxWidth: 100,
				isRowHeader: true,
				isResizable: true,
				data: "string",
				isPadded: true,
				isSortedDescending: false,
				sortAscendingAriaLabel: "Sorted A to Z",
				sortDescendingAriaLabel: "Sorted Z to A",
				onColumnClick: onSort,
				onRender: (i: FileInfo): JSX.Element => (
					<div
						key={`type-${i.type}`}
						className="file-list__meta-wrap"
					>
						<span className="file-list__meta-data">{i.type}</span>
					</div>
				),
			},
			{
				key: "size",
				name: "Size",
				fieldName: "size",
				minWidth: 50,
				maxWidth: 100,
				isRowHeader: true,
				isResizable: true,
				isPadded: true,
				onColumnClick: onSort,
				onRender: (i: FileInfo): JSX.Element => (
					<div
						key={`size-${i.size}`}
						className="file-list__meta-wrap"
					>
						<span className="file-list__meta-data">{i.size}</span>
					</div>
				),
			},
			{
				key: "lastModified",
				name: "Last modified",
				fieldName: "lastModified",
				minWidth: 150,
				maxWidth: 200,
				isRowHeader: true,
				isResizable: true,
				isPadded: true,
				onColumnClick: onSort,
				onRender: (i: FileInfo): JSX.Element => (
					<div
						key={`lm-${i.lastModified}`}
						className="file-list__meta-wrap"
					>
						<span className="file-list__meta-data">
							{i.lastModified}
						</span>
					</div>
				),
			},
			{
				key: "ext",
				name: "Extension",
				fieldName: "ext",
				minWidth: 50,
				maxWidth: 100,
				isRowHeader: true,
				isResizable: true,
				isPadded: true,
				onRender: (i: FileInfo): JSX.Element => (
					<div key={`lm-${i.ext}`} className="file-list__meta-wrap">
						<span className="file-list__meta-data">{i.ext}</span>
					</div>
				),
			},
			{
				key: "sourcePath",
				name: "Source Path",
				fieldName: "sourcePath",
				minWidth: 100,
				maxWidth: 500,
				isRowHeader: true,
				isResizable: true,
				isPadded: true,
				onRender: (i: FileInfo): JSX.Element => (
					<TooltipHost
						key={`lm-${i.sourcePath}`}
						content={i.sourcePath}
						id={i.sourcePath}
					>
						<div
							className="file-list__meta-wrap"
							aria-describedby={i.sourcePath}
						>
							<span className="file-list__meta-data">
								{i.sourcePath}
							</span>
						</div>
					</TooltipHost>
				),
			},
		);

		return columns;
	};

	const [columns, setColumns] = useState<IColumn[]>(getColumns());

	function showMetadataPanel(): void {
		controlPanel(PanelKind.Metadata);
	}

	function onSort(ev: React.MouseEvent<HTMLElement>, column: IColumn): void {
		const newColumns = columns.slice();
		const currColumn = newColumns.filter(
			currCol => column.key === currCol.key,
		)[0];

		setSortedColumn(
			currColumn.fieldName ?? "",
			currColumn.isSortedDescending ?? false,
		);

		newColumns.forEach((newCol: IColumn) => {
			if (newCol === currColumn) {
				currColumn.isSortedDescending = !currColumn.isSortedDescending;
				currColumn.isSorted = true;
			} else {
				newCol.isSorted = false;
				newCol.isSortedDescending = true;
			}
		});

		sortColumn(newColumns, currColumn);
	}

	function sortColumn(newColumns: IColumn[], currColumn: IColumn): void {
		if (!currColumn.fieldName) {
			throw Error();
		}

		// To avoid a closure
		const filesInfo = storage.getFilesInfo();
		if (filesInfo) {
			const newItems = sortFiles(
				filesInfo,
				currColumn.fieldName,
				currColumn.isSortedDescending,
			);

			updateFilesInfo(newItems ?? []);
			setColumns(newColumns);
		}
	}

	function getKey(item: FileInfo): string {
		return item.id.toString();
	}

	/**
	 * To prevent the sorting from being reset after adding new data (name, story)
	 */
	useEffect(() => {
		if (isNeedToSort && sortedColumn && sortedColumn.columnName.length) {
			const newColumns = columns.slice();
			const currColumn = newColumns.filter(
				currCol => sortedColumn.columnName === currCol.fieldName,
			)[0];
			sortColumn(newColumns, currColumn);
			planSorting(false);
		}
	}, [filesInfo]);

	useEffect(() => {
		resetSelection();
	}, []);

	return (
		<div className="file-list-wrap">
			<DetailsList
				className="file-list"
				items={filesInfo ?? []}
				compact={false}
				columns={columns}
				selectionMode={SelectionMode.multiple}
				selection={selection}
				layoutMode={DetailsListLayoutMode.justified}
				isHeaderVisible={true}
				setKey="multiple"
				ariaLabelForSelectionColumn="Toggle selection"
				ariaLabelForSelectAllCheckbox="Toggle selection for all items"
				checkButtonAriaLabel="select row"
				getKey={getKey}
				selectionPreservedOnEmptyClick={true}
			/>
		</div>
	);
});

export default FileList;
