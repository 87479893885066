import React from "react";
import { Spinner, SpinnerSize } from "@fluentui/react";
import "./Loader.scss";

type LoaderProps = {
	text?: string;
};

function Loader(props: LoaderProps): JSX.Element {
	return (
		<div className="app-loader">
			<Spinner size={SpinnerSize.large} label={props.text} />
		</div>
	);
}

export default Loader;
