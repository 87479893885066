import { FileInfo } from "../domain/fileInfo";
import storage from "../services/storageAdapter";
import useNotifier from "../services/notificationAdapter";
import { NotificationService, StorageService } from "./ports";
import { AppMessage } from "../domain/appMessages";
import planSorting from "./planSorting";
import setDirtyQueue from "./setDirtyQueue";
import formatStory from "./utils/formatStory";

type Deps = {
	storage: StorageService;
	notifier: NotificationService;
	planSorting: typeof planSorting;
	setDirtyQueue: typeof setDirtyQueue;
};

const defaultDeps: Deps = {
	storage,
	notifier: useNotifier(),
	planSorting,
	setDirtyQueue,
};

/**
 * Change story (for BBC) from list
 *
 * @param story
 * @param deps
 * @returns Files info
 */
function changeStory(
	story: string,
	deps: Deps = defaultDeps,
): FileInfo[] | null {
	const { storage, notifier, planSorting, setDirtyQueue } = deps;

	const user = storage.getUser();
	if (!user) {
		notifier.notify("error", AppMessage.NoUser);
		throw new Error(AppMessage.NoUser);
	}

	const stories = storage.getStories();
	if (!stories) {
		notifier.notify("error", AppMessage.NoStories);
		throw new Error(AppMessage.NoStories);
	}

	const filesInfo = storage.getFilesInfo();
	if (!filesInfo) {
		notifier.notify("error", AppMessage.NoFilesInfo);
		throw new Error(AppMessage.NoFilesInfo);
	}

	const dirtyQueue = storage.getDirtyQueue();
	if (!dirtyQueue) {
		notifier.notify("error", AppMessage.NoDirtyQueue);
		throw new Error(AppMessage.NoDirtyQueue);
	}

	const selectedStory = stories.find(
		i => formatStory(i.id, i.title) === story,
	);

	if (selectedStory || !story.length) {
		const newDirtyQueue: FileInfo[] = [];

		dirtyQueue.forEach(element => {
			const filesInfoFileIndex = filesInfo.findIndex(
				i => i.id === element.id,
			);
			if (filesInfoFileIndex === -1) {
				notifier.notify("error", AppMessage.NoFileInfo);
				throw new Error(AppMessage.NoFileInfo);
			}

			filesInfo[filesInfoFileIndex].storyId = selectedStory
				? selectedStory.id.toString()
				: undefined;
			filesInfo[filesInfoFileIndex].storyTitle = selectedStory
				? selectedStory.title
				: undefined;

			newDirtyQueue.push(filesInfo[filesInfoFileIndex]);
		});

		const sortedColumn = storage.getSortedColumn();

		if (sortedColumn && sortedColumn.columnName === "storyTitle") {
			planSorting(true);
		}

		storage.setFilesInfo([...filesInfo]);
		setDirtyQueue(newDirtyQueue);

		return storage.getFilesInfo();
	} else {
		return storage.getFilesInfo();
	}
}
export default changeStory;
