import React, { useEffect, useState } from "react";
import { DefaultButton, Text } from "@fluentui/react";
import Dropzone from "../../components/Dropzone/Dropzone";
import Actions from "../../components/Actions/Actions";
import AddMetadata from "../../components/AddMetadata/AddMetadata";
import Queue from "../../components/Queue/Queue";
import FileList from "../../components/FileList/FileList";
import Upload from "../../components/Upload/Upload";
import Loader from "../../components/Loader/Loader";
import { AirUploadParams } from "../../../domain/airUploadParams";
import applyUploadParamsFromAir from "../../../application/applyUploadParamsFromAir";
import switchToEmbeddedType from "../../../application/switchToEmbeddedType";
import { useStore } from "effector-react";
import storage from "../../../services/storageAdapter";
import { UploaderType } from "../../../domain/uploaderType";
import { AppInitStatus } from "../../../domain/appInitStatus";
import importFiles from "../../../application/importFiles/importFiles";
import controlPanel from "../../../application/controlPanel";
import { PanelKind } from "../../../domain/panel";
import { observer } from "mobx-react-lite";
import homeLight from "../../assets/homeLight.svg";
import homeDark from "../../assets/homeDark.svg";
import getSelectMenu from "../../shared/getSelectMenu";
import TypeOfImport from "../../../domain/typeOfImport";

const queueInstance = storage.queue;

const EmbeddedApp = observer((): JSX.Element => {
	const appInitStatus = useStore(storage.appInitStatus);
	const isInfoCreating = useStore(storage.isInfoCreating);
	const selectedFolder = useStore(storage.selectedLocationFolder);
	const location = useStore(storage.selectedUploadLocation);
	const uploaderType = useStore(storage.uploaderType);
	const selectedLocation = useStore(storage.selectedUploadLocation);
	const filesInfo = useStore(storage.filesInfo);
	const dirtyQueue = useStore(storage.dirtyQueue);
	const openedPanel = useStore(storage.openedPanel);
	const isDarkMode = useStore(storage.isDarkMode);

	const queue = queueInstance.getQueue();

	const isAppInit = appInitStatus !== AppInitStatus.InProgress;
	const isEmbedded = uploaderType == UploaderType.Embedded;
	const [isInfoApplying, setIsInfoApplying] = useState(false);

	const isShowFileList = filesInfo && filesInfo.length && !isInfoCreating;
	const isShowIntro =
		(!filesInfo || (filesInfo && !filesInfo.length)) && !isInfoCreating;
	const isShowAddMetadata =
		filesInfo && filesInfo.length && dirtyQueue && dirtyQueue.length;
	const items = getSelectMenu();

	function onDragNDropp(files: File[]): void {
		importFiles(files, TypeOfImport.DragNDrop);
	}

	useEffect(() => {
		switchToEmbeddedType();
	}, []);

	/**
	 * Waiting for a message from AIR.
	 * Extracts parameters from a message and apply.
	 */
	useEffect(() => {
		window.addEventListener(
			"message",
			async (event: MessageEvent<AirUploadParams>) => {
				if (event && event.data.locationId) {
					setIsInfoApplying(true);
					await applyUploadParamsFromAir(
						event.data.locationId,
						event.data.path,
					);
					setIsInfoApplying(false);
				}
			},
		);
	}, []);

	useEffect(() => {
		if (
			(openedPanel == PanelKind.Metadata && !dirtyQueue) ||
			(dirtyQueue && !dirtyQueue.length)
		) {
			controlPanel(PanelKind.None);
		}
	}, [dirtyQueue]);

	if ((isEmbedded && !selectedLocation) || isInfoApplying) {
		return (
			<div className="app-loading">
				<Loader text="Waiting for data from AIR..." />
			</div>
		);
	}

	return (
		<>
			{!isAppInit ? (
				<Loader text={appInitStatus} />
			) : (
				<div className="app-embedded">
					<Actions />
					{dirtyQueue && dirtyQueue.length ? <Upload /> : <></>}
					{queue && queue.length ? <Queue /> : <></>}
					{isShowAddMetadata ? <AddMetadata /> : <></>}

					<main className="main">
						{isShowFileList ? (
							<>
								<FileList />
							</>
						) : (
							<></>
						)}

						{isShowIntro ? (
							<div className="app-info">
								<img
									className="app-info__home-img"
									src={isDarkMode ? homeDark : homeLight}
									alt="home"
								/>

								<Text variant="large">
									Select or Drag and Drop a file/folder
								</Text>
								{location && (
									<Text variant="large">
										to upload to{" "}
										<span className="app-info__text-highlight">
											{location.title}/
											{selectedFolder?.path}
										</span>
									</Text>
								)}

								<DefaultButton
									primary
									text="Add Files"
									iconProps={{ iconName: "OpenFile" }}
									menuProps={{ items }}
								/>
							</div>
						) : (
							<></>
						)}

						{isInfoCreating && <Loader text="Creating info..." />}
					</main>

					<Dropzone inputCB={onDragNDropp} />
				</div>
			)}
		</>
	);
});

export default EmbeddedApp;
