import { AppMessage } from "../../../domain/appMessages";
import { UploadFileInfo } from "../../../domain/fileInfo";
import storage from "../../../services/storageAdapter";
import { StorageService } from "../../ports";
import decreaseUploadingCount from "../upload/decreaseUploadingCount";
import {
	updateCancelledReason,
	updateIsUploadComplete,
} from "../upload/updateUploadingInfo";

type Deps = {
	decreaseUploadingCount: typeof decreaseUploadingCount;
	updateIsUploadComplete: typeof updateIsUploadComplete;
	storage: StorageService;
};

const defaultDeps: Deps = {
	decreaseUploadingCount,
	updateIsUploadComplete,
	storage,
};

async function abort(
	element: UploadFileInfo,
	deps: Deps = defaultDeps,
): Promise<void> {
	const { decreaseUploadingCount, storage } = deps;

	const controller = element.controller;
	if (controller) {
		controller.abort();
	}

	decreaseUploadingCount();
	updateCancelledReason(
		element.leaseId,
		element.error ?? AppMessage.CanceledByUser,
	);

	const currentUploads = storage.getCurrentUploads() ?? [];
	const newCurrentUploads = currentUploads.filter(u => u !== element.path);
	storage.setCurrentUploads([...newCurrentUploads]);
	updateIsUploadComplete(element.leaseId, true);
	storage.setIsCompletedUploadExist(true);
}

export default abort;
