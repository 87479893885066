import storage from "../../../services/storageAdapter";
import { StorageService } from "../../ports";

type Deps = {
	storage: StorageService;
};

const defaultDeps: Deps = {
	storage,
};

function decreaseUploadingCount(deps: Deps = defaultDeps): number {
	const { storage } = deps;

	storage.setUploadingCount(storage.getUploadingCount() - 1);

	return storage.getUploadingCount();
}

export default decreaseUploadingCount;
