import storage from "../services/storageAdapter";
import { StorageService } from "./ports";

type Deps = {
	storage: StorageService;
};

const defaultDeps: Deps = {
	storage,
};

function setIsNeedToUseNamingStandard(
	status: boolean,
	deps: Deps = defaultDeps,
): boolean {
	const { storage } = deps;

	storage.setIsNeedToUseNamingStandard(status);

	return storage.getIsNeedToUseNamingStandard();
}

export default setIsNeedToUseNamingStandard;
