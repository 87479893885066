import moment from "moment";
import { TimeConversionService } from "../application/ports";
import { TimeFormat } from "../domain/timeFormat";

function useTimeConversionService(): TimeConversionService {
	function convert(time: number, format?: TimeFormat): string {
		return moment(time).format(format ?? TimeFormat.M4D2Y4);
	}

	return { convert };
}

export default useTimeConversionService;
