import { storiesUrl } from "../services/utils/endpoints";
import storage from "../services/storageAdapter";
import useNotifier from "../services/notificationAdapter";
import useStories from "../services/storiesAdapter";
import { NotificationService, StorageService, StoriesService } from "./ports";
import { Story } from "../domain/story";
import { AppMessage } from "../domain/appMessages";

type Deps = {
	storage: StorageService;
	url: string;
	storiesService: StoriesService;
	notifier: NotificationService;
};

const defaultDeps = {
	storage,
	url: storiesUrl,
	storiesService: useStories(),
	notifier: useNotifier(),
};

/**
 * Get stories for current user from third-party service
 *
 * @param deps
 * @returns Array of stories or null.
 */
async function getStories(deps: Deps = defaultDeps): Promise<Story[] | null> {
	const { storage, url, storiesService, notifier } = deps;

	storage.resetStories();

	try {
		const stories = await storiesService.getStories(url);

		if (stories) {
			storage.setStories(stories);
		} else {
			notifier.notify("warn", AppMessage.StoriesVendorReturnNull);
		}

		return stories;
	} catch (error) {
		notifier.notify("error", AppMessage.CantGettingStories);
	}

	return null;
}

export default getStories;
