import { AppInitStatus } from "../domain/appInitStatus";
import { UploaderType } from "../domain/uploaderType";
import { findUploadLocation, UploadLocation } from "../domain/uploadLocation";
import storage from "../services/storageAdapter";
import useNotifier from "../services/notificationAdapter";
import findAndSelectFolder from "./findAndSelectFolder";
import getLocationFolders from "./getLocationFolders";
import { NotificationService, StorageService } from "./ports";
import { LocationFolder } from "../domain/locationFolder";
import { AppMessage } from "../domain/appMessages";

type ApplyUploadParamsFromAir = {
	selectedUploadLocation: UploadLocation | null;
	selectedLocationFolder: LocationFolder | null;
};

type Deps = {
	storage: StorageService;
	notifier: NotificationService;
};

const defaultDeps = {
	storage,
	notifier: useNotifier(),
};

/**
 * Find location by ID and set location and upload folder by params passed from AIR
 *
 * @param uploadLocationId
 * @param uploadPath
 * @param deps
 * @returns Applied parameters
 */
async function applyUploadParamsFromAir(
	uploadLocationId: string,
	uploadPath: string,
	deps: Deps = defaultDeps,
): Promise<ApplyUploadParamsFromAir> {
	const { storage, notifier } = deps;

	const user = storage.getUser();

	if (!user) {
		notifier.notify("error", AppMessage.NoUser);
		storage.setAppInitStatus(AppInitStatus.Failed);
		throw new Error(AppMessage.NoUser);
	}

	const locations = storage.getLocations();

	if (!locations) {
		notifier.notify("error", AppMessage.NoUploadLocations);
		storage.setAppInitStatus(AppInitStatus.Failed);
		throw new Error(AppMessage.NoUploadLocations);
	}

	const uploadLocation = findUploadLocation(locations, uploadLocationId);

	if (!uploadLocation) {
		notifier.notify("error", AppMessage.NoSelectedLocation);
		storage.setAppInitStatus(AppInitStatus.Failed);
		throw new Error(AppMessage.NoSelectedLocation);
	}

	const selectedUploadLocation = storage.getSelectedUploadLocation();

	if (
		selectedUploadLocation &&
		selectedUploadLocation.id === uploadLocationId
	) {
		if (storage.getUploaderType() == UploaderType.Embedded) {
			storage.resetSelectedLocationFolder();
			storage.resetLocationTreeBreadCrumbs();
		}
	} else {
		storage.setSelectedUploadLocation(uploadLocation);
	}

	await getLocationFolders();

	if (uploadPath.length) {
		storage.setAirUploadPath(uploadPath.split("/"));
		const airUploadPath = storage.getAirUploadPath();

		if (airUploadPath) {
			airUploadPath.forEach(i => {
				findAndSelectFolder(i);
			});

			storage.setAppInitStatus(AppInitStatus.Done);
		}
	}

	return {
		selectedUploadLocation: storage.getSelectedUploadLocation(),
		selectedLocationFolder: storage.getSelectedLocationFolder(),
	};
}

export default applyUploadParamsFromAir;
