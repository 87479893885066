import { Spinner, ThemeProvider, Text } from "@fluentui/react";
import { useStatemanjs } from "@persevie/statemanjs-react";
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import auth from "../application/auth/auth";
import signIn from "../application/auth/signIn";
import { User } from "../domain/user";
import { pathnameState, redirect, routes, routesList } from "./router";
import "./styles/index.scss";
import getCache from "../application/getCache";
import applyTheme from "../application/applyTheme";
import { useStore } from "effector-react";
import storage from "../services/storageAdapter";
import { dark, light } from "./shared/themes";
import AppWrapper from "./App";
import { userState } from "../services/storage/userStorageService";
import { authErrorState } from "../services/storage/authStorageService";
import { logoUrlState } from "./shared/state";

function routeHandler(pathname: string, user: User | null): void {
	if (!routesList.includes(pathname)) {
		redirect(routes.classic);
	} else if (pathname === routes.signInHandler) {
		if (user !== null) {
			auth();
		} else {
			signIn();
		}
	} else if (user === null) {
		auth();
	}
}

export enum AppMode {
	Classic,
	Embedded,
	External,
}

function getAppMode(pathname: string): AppMode {
	switch (pathname) {
		case routes.embedded:
			return AppMode.Embedded;

		case routes.external:
			return AppMode.External;

		default:
			return AppMode.Classic;
	}
}

function AppRouterWrapper(): JSX.Element {
	const pathname = useStatemanjs(pathnameState);
	const user = useStatemanjs(userState);
	const authError = useStatemanjs(authErrorState);

	const appMode = getAppMode(pathname);
	const isEntryPathname =
		pathname !== routes.signInHandler && routesList.includes(pathname);

	useEffect(() => {
		routeHandler(pathname, user);
	}, [pathname, user]);

	return (
		<Index
			user={user}
			authError={authError}
			isEntryPathname={isEntryPathname}
			appMode={appMode}
		/>
	);
}

type IndexProps = {
	user: User | null;
	authError: string | null;
	isEntryPathname: boolean;
	appMode: AppMode;
};

function Index(props: IndexProps): JSX.Element {
	const isDarkMode = useStore(storage.isDarkMode);

	useEffect(() => {
		const cache = getCache();
		applyTheme(cache.isDarkMode ?? false);
	}, []);

	if (props.authError !== null) {
		return (
			<ThemeProvider
				applyTo="body"
				theme={isDarkMode ? dark : light}
				style={{ height: "100%" }}
			>
				<div id="app-info">
					<Text as="h1" className="txt_err">
						{props.authError}
					</Text>
				</div>
			</ThemeProvider>
		);
	}

	if (props.user !== null && props.isEntryPathname) {
		return (
			<ThemeProvider
				applyTo="body"
				theme={isDarkMode ? dark : light}
				style={{ height: "100%" }}
			>
				<AppWrapper appMode={props.appMode} />
			</ThemeProvider>
		);
	}

	return (
		<ThemeProvider
			applyTo="body"
			theme={isDarkMode ? dark : light}
			style={{ height: "100%" }}
		>
			<div id="app-info">
				<Spinner label="Auth in progress..." />
			</div>
		</ThemeProvider>
	);
}

async function applyConfig(): Promise<void> {
	const res = await fetch("/api/config/ui");

	if (res.ok) {
		const config = await res.json();

		if (config.iconUrl && config.iconUrl.length) {
			const favicon = document.querySelector(
				'link[rel="icon"]',
			) as HTMLAnchorElement;

			favicon.href = config.iconUrl;
		}

		if (config.title && config.title.length) {
			document.title = config.title;
		}

		if (config.logoUrl && config.logoUrl.length) {
			logoUrlState.set(config.logoUrl);
		}

		if (config.filterExtensions && config.filterExtensions.length) {
			const excludeList = config.filterExtensions
				.toLowerCase()
				.split(",")
				.map((i: string) => i.trim());

			storage.setIgnoreList(excludeList);
		}
	}
}

applyConfig().then((): void => {
	ReactDOM.render(<AppRouterWrapper />, document.getElementById("root"));
});
