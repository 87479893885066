import { FileInfo, FileWithPath } from "../../../domain/fileInfo";
import { mimeTypeKeys } from "../../../domain/mimes";
import storage from "../../../services/storageAdapter";
import convertTime from "../../utils/convertTime";
import getCategory from "./getCategory";
import getFileName from "./getFileName";
import getIconPath from "./getIconPath";
import getSize from "../../../utils/getSize";
import { StorageService } from "../../ports";
import getExt from "./getExt";
import { notFound } from "../../../domain/defaultValues";

type Deps = {
	storage: StorageService;
	getFileName: typeof getFileName;
	getCategory: typeof getCategory;
	getIconPath: typeof getIconPath;
	getSize: typeof getSize;
	convertTime: typeof convertTime;
	getExt: typeof getExt;
};

const defaultDeps: Deps = {
	storage,
	getFileName,
	getCategory,
	getIconPath,
	getSize,
	convertTime,
	getExt,
};

/**
 * Set info for imported files
 *
 * @param validFiles
 * @param deps
 * @returns Files info
 */
async function setInfo(
	validFiles: File[],
	deps: Deps = defaultDeps,
): Promise<FileInfo[] | null> {
	const { storage, getCategory, getIconPath, getSize, convertTime, getExt } =
		deps;

	const filesInfo: FileInfo[] = [];

	validFiles.forEach((i, idx) => {
		const ext = getExt(i.type, i.name);

		filesInfo.push({
			id: idx,
			file: i,
			key: idx.toString(),
			umid: notFound,
			name: getFileName(i.name),
			type: getCategory(ext, mimeTypeKeys, i.name),
			ext,
			thmbnl: getIconPath(ext, mimeTypeKeys),
			size: getSize(i.size),
			lastModified: convertTime(i.lastModified),
			rawLastModified: i.lastModified,
			description: notFound,
			sourcePath: storage.getIsDataDragNDropped()
				? (i as FileWithPath).path
				: (i as FileWithPath).webkitRelativePath,
		});
	});

	storage.setIsCameraCardImported(false);
	storage.setIsInfoCreating(false);

	storage.setFilesInfo(filesInfo);

	return storage.getFilesInfo();
}

export default setInfo;
