/** Seconds */
export const uploadingRetryTimeout = 5;

/** Bytes (4MB max chunk size to upload in azure files) */
export const chunkSize = 4194304;

export const uploadingLimit = 1;

export const uploadAttemptsLimit = 3;

/** Ms */
export const limitCheckTimeout = 1000;

export const notFound = "";

export const unknown = "Unknown";

export const noStory = "No story selected";

/** RegExp for defining the main metadata file. */
export const metadataRegExp = /(XDROOT\/MEDIAPRO.XML)/gm;
