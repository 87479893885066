import { AppMessage } from "../../domain/appMessages";
import TypeOfImport from "../../domain/typeOfImport";
import useNotifier from "../../services/notificationAdapter";
import storage from "../../services/storageAdapter";
import { NotificationService, StorageService } from "../ports";
import { getErrorMessage } from "../utils/errorFormater";
import checkForCameraCard from "./importFiles/checkForCameraCard";
import parseMetadata from "./importFiles/parseMetadata";
import setCameraCardInfo from "./importFiles/setCameraCardInfo";
import setInfo from "./importFiles/setInfo";
import validateFiles from "./importFiles/validateFiles";

type Deps = {
	storage: StorageService;
	checkForCameraCard: typeof checkForCameraCard;
	notifier: NotificationService;
	validateFiles: typeof validateFiles;
	parseMetadata: typeof parseMetadata;
	setCameraCardInfo: typeof setCameraCardInfo;
	setInfo: typeof setInfo;
};

const defaultDeps: Deps = {
	storage,
	checkForCameraCard,
	notifier: useNotifier(),
	validateFiles,
	parseMetadata,
	setCameraCardInfo,
	setInfo,
};

async function importFiles(
	files: FileList | File[],
	typeOfImport: TypeOfImport,
	deps: Deps = defaultDeps,
): Promise<void> {
	const { storage, notifier, validateFiles, setInfo } = deps;

	const { getFeatures } = storage;

	storage.resetDirtyQueue();
	storage.setFilesInfo([]);

	if (!files.length) {
		notifier.notify("error", AppMessage.NoFiles);
		throw new Error(AppMessage.NoFiles);
	}

	storage.setIsDataDragNDropped(typeOfImport == TypeOfImport.DragNDrop);
	storage.setIsNeedToPreservePath(typeOfImport == TypeOfImport.SelectFolder);

	const validFiles: FileList | File[] = validateFiles(files);

	/** Schedule a micro-task to unload the flow */
	setTimeout(async () => {
		const isCameraCard = checkForCameraCard(files);

		if (
			typeOfImport == TypeOfImport.SelectCameraCard &&
			getFeatures().cameraCardParsingFeature &&
			isCameraCard
		) {
			const metadataFile = storage.getBaseFileMetadata();

			if (!metadataFile) {
				throw new Error(AppMessage.NoMetadataFile);
			}

			let parsedMetadata: Document;
			try {
				parsedMetadata = await parseMetadata(metadataFile);
			} catch (error) {
				throw new Error(getErrorMessage(error));
			}

			await setCameraCardInfo(validFiles, parsedMetadata);
		} else {
			await setInfo(validFiles);
		}
	}, 0);
}

export default importFiles;
