import { UploaderType } from "../domain/uploaderType";
import storage from "../services/storageAdapter";
import { StorageService } from "./ports";

type Deps = {
	storage: StorageService;
};

const defaultDeps = {
	storage,
};

function switchToEmbeddedType(deps: Deps = defaultDeps): UploaderType {
	const { storage } = deps;

	storage.setUploaderType(UploaderType.Embedded);

	return storage.getUploaderType();
}

export default switchToEmbeddedType;
