import { AppMessage } from "../../../domain/appMessages";
import { chunkSize } from "../../../domain/defaultValues";
import {
	getUploadingInfoEta,
	getUploadingInfoProgress,
	UploadFileInfo,
} from "../../../domain/fileInfo";
import useFile from "../../../services/fileAdapter";
import useUuid from "../../../services/uuidAdapter";
import { FileService, UuidService } from "../../ports";
import {
	formatInvalidResponse,
	getErrorMessage,
} from "../../utils/errorFormater";
import {
	updateBadChunk,
	updateBlobEnd,
	updateChunkEnd,
	updateChunksMap,
	updateChunkStart,
	updateCommonProgress,
	updateController,
	updateEta,
	updateFileChunkSize,
	updateStartTime,
	updateUploadedChunks,
} from "./updateUploadingInfo";

type UploadChunkDeps = {
	updateController: typeof updateController;
	formatInvalidResponse: typeof formatInvalidResponse;
	updateChunksMap: typeof updateChunksMap;
	updateUploadedChunks: typeof updateUploadedChunks;
	updateCommonProgress: typeof updateCommonProgress;
	getUploadingInfoProgress: typeof getUploadingInfoProgress;
	updateEta: typeof updateEta;
	getUploadingInfoEta: typeof getUploadingInfoEta;
	updateChunkStart: typeof updateChunkStart;
	chunkSize: number;
	fileService: FileService;
	getErrorMessage: typeof getErrorMessage;
};

type CreateChunkDeps = {
	chunkSize: number;
	updateChunkStart: typeof updateChunkStart;
	updateChunkEnd: typeof updateChunkEnd;
	updateUploadedChunks: typeof updateUploadedChunks;
	updateFileChunkSize: typeof updateFileChunkSize;
	updateBlobEnd: typeof updateBlobEnd;
	uuid: UuidService;
};

type Deps = {
	getErrorMessage: typeof getErrorMessage;
};

const defaultUploadChunkDeps: UploadChunkDeps = {
	updateController,
	formatInvalidResponse,
	updateChunksMap,
	updateUploadedChunks,
	updateCommonProgress,
	getUploadingInfoProgress,
	updateEta,
	getUploadingInfoEta,
	updateChunkStart,
	chunkSize,
	fileService: useFile(),
	getErrorMessage,
};

const defaultCreateChunkDeps: CreateChunkDeps = {
	chunkSize,
	updateChunkStart,
	updateChunkEnd,
	updateUploadedChunks,
	updateFileChunkSize,
	updateBlobEnd,
	uuid: useUuid(),
};

const defaultDeps: Deps = {
	getErrorMessage,
};

async function uploadChunk(
	chunkId: string,
	chunkForm: FormData,
	element: UploadFileInfo,
	deps: UploadChunkDeps = defaultUploadChunkDeps,
): Promise<void> {
	const {
		updateController,
		formatInvalidResponse,
		updateChunksMap,
		updateUploadedChunks,
		updateCommonProgress,
		getUploadingInfoProgress,
		updateEta,
		getUploadingInfoEta,
		updateChunkStart,
		chunkSize,
		fileService,
		getErrorMessage,
	} = deps;
	try {
		const controller = new AbortController();

		const leaseId = element.leaseId;
		const file = element.file;
		const uploadedChunks = element.uploadedChunks;
		const numberOfChunks = element.numberOfChunks;

		const chunkStart = element.chunkStart;
		if (chunkStart === null) {
			throw new Error(AppMessage.NoChunkStart);
		}
		const fileChunkSize = element.fileChunkSize;
		if (fileChunkSize === null) {
			throw new Error(AppMessage.NoFileChunkSize);
		}
		const blobEnd = element.blobEnd;
		if (blobEnd === null) {
			throw new Error(AppMessage.NoBlobEnd);
		}
		const startTime = element.startTime;
		if (startTime === null) {
			throw new Error(AppMessage.NoStartTime);
		}

		updateController(element.leaseId, controller);

		const res = await fileService.uploadChunk(
			element,
			chunkId,
			leaseId,
			chunkStart,
			blobEnd,
			chunkForm,
			controller,
		);

		if (res.status !== 200) {
			throw new Error(formatInvalidResponse(res));
		} else {
			const chunksMap = element.chunksMap ?? [];
			chunksMap.push(chunkId);
			updateChunksMap(element.leaseId, chunksMap);
			updateUploadedChunks(element.leaseId, element.uploadedChunks + 1);
			updateCommonProgress(
				element.leaseId,
				getUploadingInfoProgress(element),
			);

			if (uploadedChunks !== numberOfChunks) {
				const elapsedTime =
					startTime > 0 ? new Date().getTime() - startTime : 0;
				const totalUploaded = uploadedChunks * fileChunkSize;
				const speed = totalUploaded / elapsedTime;

				const rawEta = (file.size - totalUploaded) / speed;
				updateEta(element.leaseId, getUploadingInfoEta(rawEta));
			} else {
				updateEta(element.leaseId, getUploadingInfoEta(0));
			}

			updateChunkStart(element.leaseId, chunkStart + chunkSize);

			if (
				element.chunkStart < file.size &&
				element.uploadedChunks < element.numberOfChunks
			) {
				await createChunk(element);
			}
		}
	} catch (error) {
		element.controller?.abort();
		throw new Error(getErrorMessage(error));
	}
}

/**
 * Updates chunkEnd.
 * Creates a chunk, adds it to FormData, and starts sending it.
 */
async function createChunk(
	element: UploadFileInfo,
	deps: CreateChunkDeps = defaultCreateChunkDeps,
): Promise<void> {
	const {
		chunkSize,
		updateChunkStart,
		updateChunkEnd,
		updateUploadedChunks,
		updateFileChunkSize,
		updateBlobEnd,
		uuid,
	} = deps;

	const badChunk = element.badChunk;
	const file = element.file;

	const chunkStart = badChunk
		? chunkSize * (badChunk - 1)
		: element.chunkStart ?? 0;
	const chunkEnd = Math.min(chunkStart + chunkSize, file.size);
	const uploadedChunks = badChunk ? badChunk - 1 : element.uploadedChunks;
	updateBadChunk(element.leaseId, null);
	const fileChunkSize =
		chunkStart + chunkSize > file.size
			? chunkStart + chunkSize - file.size
			: chunkSize;
	const blobEnd = chunkEnd - 1;

	updateChunkStart(element.leaseId, chunkStart);
	updateChunkEnd(element.leaseId, chunkEnd);
	updateUploadedChunks(element.leaseId, uploadedChunks);
	updateFileChunkSize(element.leaseId, fileChunkSize);
	updateBlobEnd(element.leaseId, blobEnd);

	const chunk = file.slice(chunkStart, chunkEnd);
	const chunkForm = new FormData();
	const chunkId = uuid.getUuid();
	chunkForm.append("file", chunk);

	await uploadChunk(chunkId, chunkForm, element);
}

async function uploadByChunks(
	element: UploadFileInfo,
	deps: Deps = defaultDeps,
): Promise<void> {
	const { getErrorMessage } = deps;

	try {
		updateStartTime(element.leaseId, new Date().getTime());
		updateEta(element.leaseId, getUploadingInfoEta(Infinity));
		await createChunk(element);
	} catch (error) {
		updateBadChunk(element.leaseId, element.uploadedChunks + 1);
		throw new Error(getErrorMessage(error));
	}
}

export default uploadByChunks;
