import { metadataRegExp } from "../../../domain/defaultValues";
import { FileWithPath } from "../../../domain/fileInfo";
import storage from "../../../services/storageAdapter";
import { StorageService } from "../../ports";

type Deps = {
	storage: StorageService;
};

const defaultDeps: Deps = {
	storage,
};

/**
 * Checks whether the folder matches the camera structure
 *
 * @param files Imported file list
 * @param deps
 * @returns true or false
 */
function checkForCameraCard(
	files: FileList | File[],
	deps: Deps = defaultDeps,
): boolean {
	const { storage } = deps;

	/** reset file info */
	storage.setBaseFileMetadata(null);

	for (let index = 0; index < files.length; index++) {
		if (storage.getIsDataDragNDropped()) {
			if ((files[index] as FileWithPath).path.match(metadataRegExp)) {
				storage.setBaseFileMetadata(files[index] as FileWithPath);
			}
		} else {
			if (
				(files[index] as FileWithPath).webkitRelativePath.match(
					metadataRegExp,
				)
			) {
				storage.setBaseFileMetadata(files[index] as FileWithPath);
			}
		}
	}

	return !!storage.getBaseFileMetadata();
}

export default checkForCameraCard;
