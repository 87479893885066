import useUuid from "../../services/uuidAdapter";
import { UuidService } from "../ports";

type Deps = {
	uuid: UuidService;
};

const defaultDeps: Deps = {
	uuid: useUuid(),
};

export function getUuid(deps: Deps = defaultDeps): string {
	const { uuid } = deps;
	return uuid.getUuid();
}
