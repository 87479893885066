import React, { useEffect, useRef } from "react";
import { useDropzone } from "react-dropzone";
import { Text } from "@fluentui/react";
import "./Dropzone.scss";

interface Props {
	inputCB: (file: File[]) => void;
}

function Dropzone(props: Props): JSX.Element {
	const {
		acceptedFiles,
		getRootProps,
		getInputProps,
		isDragActive,
		isDragAccept,
		isDragReject,
	} = useDropzone();

	const { inputCB } = props;

	let className = "dropzone";

	if (isDragActive) {
		className += " dropzone_active";
	}
	if (isDragAccept) {
		className += " dropzone_acept";
	}
	if (isDragReject) {
		className += " dropzone_reject";
	}

	const dropZoneRef = useRef<HTMLDivElement>(null);

	const toggleVisibility = (): void => {
		if (dropZoneRef && dropZoneRef.current) {
			dropZoneRef.current.classList.toggle("dropzone-wrap_hidden");
		}
	};

	useEffect(() => {
		document.body.addEventListener("dragenter", toggleVisibility);
		document.body.addEventListener("dragleave", toggleVisibility);
		document.body.addEventListener("drop", toggleVisibility);

		return (): void => {
			document.body.removeEventListener("dragenter", toggleVisibility);
			document.body.removeEventListener("dragleave", toggleVisibility);
			document.body.removeEventListener("drop", toggleVisibility);

			if (dropZoneRef && dropZoneRef.current) {
				dropZoneRef.current.classList.add("dropzone-wrap_hidden");
			}
		};
	}, []);

	useEffect(() => {
		if (acceptedFiles.length) {
			inputCB(acceptedFiles);
		}
	}, [acceptedFiles]);

	return (
		<article
			ref={dropZoneRef}
			className="dropzone-wrap dropzone-wrap_hidden"
		>
			<div {...getRootProps({ className })}>
				<input {...getInputProps()} />
				<Text variant="large">Drop your files here</Text>
			</div>
		</article>
	);
}

export default Dropzone;
